import React, { Component } from "react";
import {
  // Alert,
  View,
  Text,
  TextInput,
  // Button,
  Picker,
  Dimensions,
  FlatList,
  StyleSheet,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  // Modal,
  TouchableWithoutFeedback,
  ActivityIndicator,
  CheckBox

  // ColorPropType,
} from "react-native";
// import Text from './../CommonText';

// import {
//   MenuProvider,
//   Menu,
//   MenuTrigger,
//   MenuOptions,
//   MenuOption,
// } from 'react-native-popup-menu';
import { RadioButton } from "react-native-paper"

// import Modal from "react-native-modal";
// import SideMenu from "../../SideMenu";
import Style from "../../../styles/Style";
import { color } from "../../../styles/Color";
import Icon from "react-native-vector-icons/FontAwesome";
import RoundIcon from "react-native-vector-icons/Entypo";
import * as Speech from 'expo-speech';

import { SearchBar } from 'react-native-elements';
// import SearchBar from "react-native-search-bar";
import Service from "../../../network/Service";
import { Constants } from "../../../utils/Constants";
import moment from "moment";

import { 
  // CommonPicker, 
  CommonButton 
} from './../BaseComponent'

// import { Dropdown } from "react-native-material-dropdown";
// import DeviceInfo from 'react-native-device-info';
// import DatePicker from "react-native-datepicker";
// import Buttons from "../../../components/button/Buttons";
import AsyncStorage from "../../../AsyncStorage";
import BaseClass from "../../BaseClass";
import { Tooltip, DatePicker, AutoComplete, TimePicker } from 'antd';
import dayjs from 'dayjs';
// import { TextInput } from "react-native-gesture-handler";
import OpthamologyService from "../../../network/OpthamologyService";
// import NextAppointment from "../../doctor/common/NextAppointment";
// import zIndex from "@material-ui/core/styles/zIndex";
import SwitchToggle from "react-native-switch-toggle";
import { format } from "crypto-js";
import ImagePath from "../../../utils/ImagePaths";
import "../../Home.css";
import { CommonHistorySectionHeader } from './../BaseComponent';
var Messages = require('../../../utils/InfoMessages')

// import CreateAppointment from "../../doctor/common/CreateAppointment";
// import TimerLog from "../../TimerLog";


const screenHeight = Dimensions.get("window").height;
const screenWidth = Dimensions.get("window").width;

const dateInstance = new Date();
const today = moment(dateInstance).format("YYYY-MM-DD");

const platform = Platform.OS;

// import Pagination from './Pagination'
export default class HomeScreen extends BaseClass {
  constructor(props) {
    super(props);

    let selectedClinicOrDoctor = {}
    try {

      // patientAppointment = this.props.navigation.
      // alert(userType)
      selectedClinicOrDoctor = this.props.selectedClinicOrDoctor;

    } catch (error) { }

    this.state = {
      showPickerDropDownFlag:false,
      today: today,
      selectedDate: moment(new Date()).format("YYYY-MM-DD"),
      appointmentData: [],
      appointmentList: [],
      ShowCreateAppointmentPatientId: "",
      appointmentComment: {},
      appointmentStat: {},
      selectedIndex: null,
      selectedDateAndTime: "",
      selectedItem: {},
      clinicData: [],
      unLockDoctor: {},
      appointmentRequest: {},
      searchPatientData: [],
      deviceType: "",
      allergyTypes: [
        { id: 1, value: "" },
        { id: 1, value: "Medication Allergy" },
        { id: 2, value: "Non Medication Allergy" }
      ],
      userType: this.props.userType,
      roleType: "",
      modalVisible: false,
      currentTime: "",
      dropDownViewFlag: false,
      getMonth: "",
      getYear: "",
      getDate: 0,
      dropDownView: "",
      day1: 10,
      day2: 11,
      day3: 12,
      day4: 13,
      day5: 14,
      totalMonths: [
        { "month": "January", "totalDays": 31 },
        { "month": "February", "totalDays": 28 },
        { "month": "March", "totalDays": 31 },
        { "month": "April", "totalDays": 30 },
        { "month": "May", "totalDays": 31 },
        { "month": "June", "totalDays": 30 },
        { "month": "July", "totalDays": 31 },
        { "month": "August", "totalDays": 31 },
        { "month": "September", "totalDays": 30 },
        { "month": "October", "totalDays": 31 },
        { "month": "November", "totalDays": 30 },
        { "month": "December", "totalDays": 31 },
      ],
      changedMonthDays: 0,
      chengedMonthInNumber: 0,
      changedDay: 0,
      showNextAppoinmentTimer: false,
      cancelAppointment: false,
      hour12Formet: 12,
      minutes: '00',
      findAmPm: 'am',

      patientId: '',
      nextAppoinmentatPientDetails: {},
      purposeOfVisitsInNextAppoinment: "",
      ShowCreateappointment: false,
      // next appointment (NAC)
      // nacDropDownViewFlag:false,
      // nacGetMonth:"",
      // nacGetYear:"",
      // nacGetDate:0,
      // nacDropDownView:"",
      // nacDay1:10,
      // nacDay2:11,
      // nacDay3:12,
      // nacDay4:13,
      // nacDay5:14,
      // nacChangedMonthDays:0,
      // nacChengedMonthInNumber:0,
      // nacChangedDay:0,

      showOptimetryDropDown: false,
      optimetrySelectedValue: {},


      checkIn: true,
      WaitingComments:false,
      optimetry: false,
      withDoctor: false,
      scanAndSurgery: false,
      checkedOut: false,
      selectedStatusIndex: 0,

      printFlag: false,
      createBillFlag: false,
      messageFlag: false,
      cancelFlag: false,
      nextAppointmentFlag: false,
      dilation: "Tropicamide Plus",

      // for Frontoffice
      clinicData: [],
      modalOpenSrc: "",
      // selectedClinic: this.props.selectedClinicOrDoctor,

      // selectedDoctor:this.props.selectedClinicOrDoctor,
      doctorData:[],
      selectedClinicOrDoctor: selectedClinicOrDoctor ? selectedClinicOrDoctor : {},
      selectedClinicID : '',
      currentAppointmentsDateValue:"",

      pageLoading:true,

      isAvailableIndicatuionFlag:false,
      getActiveTokensListFOrQMS:[],
      clientID:"",
      selected_patient_id: null,
      selected_appointment_id: null,
      isCounsellorPatientSelected: false,
      patientTransmittedCounsellingData: {},
      counsellingTransmitId: null,
      transmitDoctorId: null,
      selectedEyeType: {},
      selectedSurgeryId: null,
      selectedPackageId: null,
      surgeonName: "",
      anesthetist: "",
      counsellor: "",
      notes: "",
      surgeryName: "",
      packageName: "",
      lensPower: "",
      showTimer: false,
      packageAmount: null,
      packageDiscountAmt: null,
      packageDiscountPercentage: null,
      packageGSTamount: null,
      packageBillAmount: null,
      approvalStatus: "",
      selectedTab: "surgery",
      selectedPackageAmount: 0,
      totalPackageData: [],
      suggestionPackageData: [],
      searchPackage: "",
      searchPackageOpen: false,
      isPackageEdit: false,
      selectedGST: 0,
      selectedGSTtype: "",
      counsellorDoctorData:[],
      doctorSuggestionData: [],
      isSurgieonDoctorSuggestion: false,
      isAnaesthetistDoctorSuggestion: false,
      surgeryDate: "",

      isFoAdmin:false,
      isQMSAccessAvailableUser:false,
      dummyData:"",
      selectedAppointmentStatus:"",
      onPressTodayPatient:false,
      appointmentCount:{},
      clinicType: "",
      WaitingCommentsVisible : false,
      sortValue: "",
      loginCounsellorName: "",

      CancelAppointmentVisible : false,

      // is Admin Role
      isRoleAdmin: false,
      // QMS on queue
      isOnQueueClick: false,
      appointmentData: [],
      isCallClick: false,
      selectedDoctorList: [],
      speclizationList: {},
      isQMSEnable: "",
      tokenGenerationType: "",
      isConformation: false,
      isPatientTransfer: false,
      transferPatient: {},
      selectLaboratory: "",
      roleType: "",
      laboratoryList: [],
      departmentsList: [],
      doctorsList: [],
      originalDoctorList: [],
      selectedDoctor: "",
      selectDepartment: "",
      selectedClinic: {},
      typedIndex: null,

      FilterPopUpOpen : false,
      AddressSearchKey:"", 
      FatherHusSearchKey:"",
      FilterPopupErrorText:"",

      is_OPT : "",
      withNurse:false,

      isTransfer: false,
      TranferData:{},
      purposeOfVisitList: [],
      popListData:[],
      selectedAppointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedAppointmentTime: moment(new Date()).format("hh:mm"),
      doctor_id: "",
      DoctorLists: [],
      clinic_id: null,

    };

    this.setModalVisible = this.setModalVisible.bind(this);

    var today = new Date();
    var date = today.getDate();
    var month = today.getMonth();
    var year = today.getFullYear();
    for (let i = 0; i < this.state.totalMonths.length; i++) {
      if (month === i) {
        this.state.chengedMonthInNumber = i + 1;
        this.state.changedMonthDays = this.state.totalMonths[i].totalDays;
        this.state.getMonth = this.state.totalMonths[i].month;
        this.state.getYear = year;
        this.state.getDate = date;
        this.state.day1 = date - 2;
        this.state.day2 = date - 1;
        this.state.day3 = date;
        this.state.day4 = date + 1;
        this.state.day5 = date + 2;
        this.state.changedDay = date;
      };
    }

    this.getStatusChangeUpdate = this.getStatusChangeUpdate.bind(this)

  }

  getcurrenDateMonthYear = () => {
    var appointmentDate = this.state.getYear.toString() + "-" + this.state.chengedMonthInNumber.toString() + "-" + this.state.changedDay.toString();
    // console.log("<+++++++++++++++++")
    // console.log(appointmentDate)
    this.setState({
      selectedDateAndTime: appointmentDate
    })
  }


  async componentDidMount() {
    const isFoAdmin = await  AsyncStorage.getItem("isFoAdmin");
    const LoginName = await  AsyncStorage.getItem("UserName");

    const clientID = await  AsyncStorage.getItem("UserID");
    var loggedInData = await (AsyncStorage.getItem("loggedInData"))
      var loggedInDataObj = JSON.parse(loggedInData)
      var isOPT = false
      if(loggedInDataObj.clinic_type){
        isOPT = loggedInDataObj.clinic_type.is_opt_clinic
      }else{
        isOPT = false;
      }
    this.setState({
      clientID:clientID,
      isFoAdmin:isFoAdmin,
      clinicType: isOPT,
      loginCounsellorName: LoginName,
      isQMSEnable: loggedInDataObj.is_qms_enabled,
      tokenGenerationType: loggedInDataObj.token_gen_type,
      roleType: loggedInDataObj.role_type,
      is_OPT: loggedInDataObj.is_OPT,
      clinic_id: loggedInDataObj.clinic_id
    },()=>{
      this.getStatusChangeUpdate()
    })
    if(this.state.selectedClinicOrDoctor.id) {
      this.getLaboratoryList()
      this.getDoctors()
    }
    this.checkUser();
    // this.appointmentRequest();
    // this.unLockDoctor();
    // var fields = this.state;
    // fields["deviceType"] = DeviceInfo.getBrand();
    // this.setState({ fields });
  
    // setTimeout(() => {
      if(this.props.currentPage=="home"){
      // setInterval(() => {
        // var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
        this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"));
      // }, 60000);// every one min 
    }
    // }, 60000)
   

    //  this line for wait time and dialation time not updating in every second 
    // starts
    setInterval(() => {
      this.setState({
        dummyData:""
      })
    });// every one min 
    // ends

    // this.getClinic();

    let popData = await AsyncStorage.getItem("PurposeOfVisit")
    let popListData = popData == null ? [] : JSON.parse(popData)
    this.setState({
      popListData:popListData
    })
  }
  getLaboratoryList = () => {
    var clinic_id = this.state.clinic_id ? this.state.clinic_id : this.state.selectedClinic.id
    var service_url =
      Constants.LABORATORY_LIST + "?clinic_id=" + clinic_id + "&test_category=Laboratory Test"
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getLaboratoryListSuccess,
      this.getLaboratoryListFailure
    );
  };

  getLaboratoryListSuccess = (response) => {
    if (response.status === "success") {
      this.setState({
        laboratoryList:response.data
      })

    }
  };

  getLaboratoryListFailure = (error) => {
    // console.log("opthamology complaints GET error response");
    console.log(error);
  };
  removeDuplicate(data) {
    var nameListJsonObject = data.map(JSON.stringify);
    var nameListUniqueSet = new Set(nameListJsonObject);
    var nameListUniqueArray = Array.from(nameListUniqueSet).map(JSON.parse);
    return nameListUniqueArray;
}
  getDoctors = () => {
    var clinic_id = this.state.clinic_id ? this.state.clinic_id : this.state.selectedClinic.id
    var serviceUrl =
      Constants.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + clinic_id ;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getDoctorsSuccess,
      this.getDoctorsFailure
    );
  };

  getDoctorsSuccess = (response) => {
    if(response && response.status === "success") {
      var tempList = []
      var department_list = []
      var speciality_list = []
      response.data && response.data.length > 0 && response.data.map((item) => {
        if(item.first_name=="Nursing"){
        }else{
          tempList.push({ id: item.doctor_id, value: item.first_name + " " + item.initial, speciality_id: item.speciality_id })
          department_list.push({ id: item.speciality_id, value: item.speciality_name, doctor_id: item.doctor_id })
          speciality_list = this.removeDuplicate(department_list)
        }
      });
      var fields = this.state;
      fields["doctorsList"] = tempList;
      fields["departmentsList"] = speciality_list 
      fields["originalDoctorList"] = tempList
      this.setState({
        fields,
        DoctorLists : response.data
      })
    }
  }
  getDoctorsFailure = (error) => {
    this.props.showResposeValue("error", error.message)
  }
  getDoctorList = () => {
    var serviceUrl =
      Constants.HOME_SPECLIZATION_LIST;

    Service.getInstance().getClinicList(
      serviceUrl,
      this,
      this.getDoctorListSuccess,
      this.getDoctorListFailure
    );
  };
  getDoctorListSuccess =  (response) => {
    if(response.status === "success") {
        this.setState({
          speclizationList: response.data
        }, () => {
          var { appointmentData, speclizationList } = this.state
         for( let i =0 ; i < appointmentData.length ; i++ ) {  
          if( appointmentData[i].specialization__name != null && appointmentData[i].specialization__name === "GENERAL PHYSICIAN") {
            appointmentData[i].doctorSelectionList = speclizationList.DOCTOR
          } else if(  appointmentData[i].specialization__name != null && appointmentData[i].specialization__name === "DIABETOLOGY") {
           appointmentData[i].doctorSelectionList = speclizationList.DB
          }else if(  appointmentData[i].specialization__name != null && appointmentData[i].specialization__name === "OBGYN") {
           appointmentData[i].doctorSelectionList = speclizationList.OBGYN
          }else if(  appointmentData[i].specialization__name != null && appointmentData[i].specialization__name === "PSYCHOLOGY") {
           appointmentData[i].doctorSelectionList = speclizationList.PSY
          }else if(  appointmentData[i].specialization__name != null && appointmentData[i].specialization__name === "PEDIATRICIAN") {
           appointmentData[i].doctorSelectionList = speclizationList.PD
          }
          this.setState({
            appointmentData
          })
         }

        })
    }
  }  
  getDoctorListFailure = (error) => {
    this.props.showResposeValue("error", error.message)
  }

  // callAgainAppointmentRequest(){
  //   this.ap
  // }

  appointmentRequest = () => {
    let service_url = ""
    
    if(this.state.userType === Constants.ROLE_FO) {
      service_url =Constants.FO_GET_APPOINMENT_REQUEST;
    } else {
      service_url =Constants.GET_APPOINMENT_REQUEST;
    }
     

  OpthamologyService.getInstance().getComplaints(
    service_url,
    this,
    this.appointmentRequestSuccess,
    this.appointmentRequestFailure
  );
  }

  appointmentRequestSuccess = (response) => {
    if(response.status== "success"){
    //  console.log("++++++++++++++++++++++++++++"+ JSON.stringify(response))
      this.setState({
        appointmentRequest : response.data,
        pageLoading : false
      },()=>{
        this.props.appointmentRequestModalFlag(true, this.state.appointmentRequest)
      })
    }
  }

  rerenderAppointmentStatus(date=""){
    
    this.todayAppointment(date);
    this.getAppointmentStat();
    // if(this.state.isFoAdmin == "true"){  // need to check 
      this.checkCounterActiveForQMS();
    // }
  }
 

  async componentWillReceiveProps(nextProps) {
    if(nextProps.refreshHomeComponentFlag){
      this.rerenderAppointmentStatus(this.state.currentAppointmentsDateValue);
      this.props.refreshHomeComponent(false)
    }
    
    if(this.state.selectedClinic != this.props.selectedClinicOrDoctor ) {
      this.setState({
        selectedClinic: this.props.selectedClinicOrDoctor
      }, () => {
        this.getLaboratoryList()
        this.getDoctors()
      })
    }
    if(nextProps && nextProps.searcBoxTextValue){
      this.setState({
        searchBar: nextProps.searcBoxTextValue
      })
    }else if(nextProps && nextProps.searcBoxTextValue === ""){
      this.setState({
        searchBar: nextProps.searcBoxTextValue
      })
    }
    if(nextProps && nextProps.isSearchClicked){
      this.searchPatient();
    }
    if(nextProps && nextProps.isFilterSearchClicked && nextProps.isFilterSearchClicked){
      this.setState({
        searchPatientData: [], 
        FilterPopUpOpen: true
      })
    }else {
      this.setState({
        FilterPopUpOpen: false
      })
    }

    this.props.userType
    if(nextProps && nextProps.selectedClinicOrDoctor){

      if(this.props.userType == Constants.ROLE_FO){
        if(this.state.selectedClinicOrDoctor.id !== nextProps.selectedClinicOrDoctor.id)
        this.setState({
          selectedClinicOrDoctor:nextProps.selectedClinicOrDoctor
        },()=>{
          this.rerenderAppointmentStatus()
          AsyncStorage.setItem("Selected_ClinicIDFor_Doctor",this.state.selectedClinicOrDoctor.id);

        })
      }else if(this.props.userType == Constants.ROLE_OPTOMETRY){
        if(this.state.selectedClinicOrDoctor.id !== nextProps.selectedClinicOrDoctor.id)
        this.setState({
          selectedClinicOrDoctor:nextProps.selectedClinicOrDoctor
        },()=>{
          this.getAppointments(nextProps.receivedAppoinmentDate)
        })
      }
      else{
        if(this.state.selectedClinicOrDoctor.id !== nextProps.selectedClinicOrDoctor.id)
        this.setState({
          selectedClinicOrDoctor:nextProps.selectedClinicOrDoctor
        },()=>{
          this.rerenderAppointmentStatus()
          AsyncStorage.setItem("Selected_ClinicIDFor_Doctor",this.state.selectedClinicOrDoctor.id);
        })
      }

    }


    // if(nextProps && nextProps.modelCloseStatus && nextProps.modelCloseStatus =="success"){
    //   alert("modelCloseStatus ------------> ")
    //   this.rerenderAppointmentStatus();
    //   this.props.modelClose(false, "")
    // }
   
    if(nextProps && nextProps.receivedAppoinmentDate && nextProps.receivedAppoinmentDate !== this.props?.receivedAppoinmentDate){
      // alert("receivedAppoinmentDate")
      // console.log("Date"+ JSON.stringify(nextProps))
      this.getAppointments(nextProps.receivedAppoinmentDate)
    }
    if (nextProps && nextProps.navigation && nextProps.navigation.state && nextProps.navigation.state.params && (nextProps.navigation.state.params.callNextAppointment || nextProps.navigation.state.params.appointmentReload) ) {
      this.todayAppointment()
    }
    if(nextProps && nextProps.changeAppointmentStatusbyId){
      if(this.props.changeAppointmentStatusbyId !== nextProps.changeAppointmentStatusbyId) {
        this.setState({
          changeAppointmentStatusbyId: nextProps.changeAppointmentStatusbyId
        },()=>{
          this.changeAppointmentStatus('Arrived', nextProps.changeAppointmentStatusbyId);
        })
      }
    }
    let popData = await AsyncStorage.getItem("PurposeOfVisit")
    if(popData){
    let popListData = popData == null ? [] : JSON.parse(popData)
    this.setState({
      popListData:popListData
    })}
  }

  checkUser = async () => {
    // const setClinicID = await AsyncStorage.setItem("Clinic_Id", 10);
    // const userType = await AsyncStorage.getItem("service_type").then(()=>{ this.getDropDownData(); });

    const userType = await AsyncStorage.getItem("service_type");
    const roleType = await AsyncStorage.getItem("role_type");

    var fields = this.state;
    fields["userType"] = userType;
    fields["selectedAppointmentStatus"] = roleType === "Doctor"  ? "Arrived" : ""

    this.setState({ fields },()=>{
      // alert(userType);
      if( Constants.ROLE_FO == userType || Constants.ROLE_OPTOMETRY == userType ) {
        this.getDoctor();
      } else {
        this.getClinic();
      }
    });
  };

  getAppointments = date => {
    // console.log("in side function Date"+ JSON.stringify(date))
    var { userType, selectedClinicOrDoctor } = this.state;
    // alert("test  --- >" +this.props.userType)
    var serviceUrl = "";
    var selectedId = selectedClinicOrDoctor && Object.keys(selectedClinicOrDoctor).length > 0?selectedClinicOrDoctor.id:"";
    var isNursing = selectedClinicOrDoctor.value=="Nursing  "? true :false;
    
    // console.log(" selectedClinicOrDoctor ")
    // console.log(" selectedId --- " + selectedId \
   
    if( Constants.ROLE_FO == userType ) {
      if(isNursing){
        serviceUrl = Constants.HOME_FO_APPOINTMENTS + 
      "?appointment_date=" + date +
      "&is_nurse=" + true + "&appointment_status=" +this.state.selectedAppointmentStatus + "&sort=" + this.state.sortValue ;
      }else{
        var doctor_id = this.state.selectedAppointmentStatus === "On Queue" ? "" : selectedId
        serviceUrl = Constants.HOME_FO_APPOINTMENTS + 
      "?appointment_date=" + date +
      "&doctor_id=" + doctor_id + "&appointment_status=" +this.state.selectedAppointmentStatus + "&sort=" + this.state.sortValue ;
      }

    } else if ( Constants.ROLE_OPTOMETRY == userType  ) {

      serviceUrl = Constants.HOME_OPT_APPOINTMENTS + 
      "?appointment_date=" + date + 
      "&doctor_id=" + selectedId + "&appointment_status=" +this.state.selectedAppointmentStatus + "&sort=" + this.state.sortValue;

    }else if ( Constants.ROLE_COUNSELLOR == userType  ) {

      serviceUrl = Constants.HOME_COUNSELLOR_APPOINTMENTS + 
      "?date=" + date + "&clinic_id=" + selectedId + "&sort=" + this.state.sortValue ;

    } else {
      
      serviceUrl = Constants.HOME_DOCTOR_APPOINTMENTS + 
      "?appointment_date=" + date + 
      "&clinic_id=" + (this.state.clinic_id ? this.state.clinic_id : selectedClinicOrDoctor.id ? selectedClinicOrDoctor.id : "") + "&appointment_status=" +this.state.selectedAppointmentStatus + "&sort=" + this.state.sortValue;
    }



    Service.getInstance().homeAppointments(
      serviceUrl,
      this,
      this.getAppointmentSuccess,
      this.getAppointmentFailure
    );
  };

  getAppointmentSuccess = response => {

    this.props.resentComment(response.data.map(data=>data.appointment_id),
    response.data.map(data=>data.waiting_comment)
    )

    if (response.detail) {

     // console.log("token expired")
      this._logoutFunction();

    } else {
      var fields = this.state;
      var { appointmentData } = this.state
      fields["appointmentData"] = response.data;
      fields["appointmentCount"] = response.patient_arrived_count

      if (response.data.length > 0) {
        fields["selectedIndex"] = 0;
        fields["selectedItem"] = response.data[0];
        // 
        this.setState({ patientId: response.data[0].patient_id },()=>{
          // if(this.state.userType == Constants.ROLE_FO){  need to check 
            this.getListfoActiveTokensForQMS()
          // }
        })
        // this.getAppointmentComments(response.data[0].appointment_id);

      }

      this.setState({
        fields,
        pageLoading:false,
      }, () => {
        appointmentData.forEach(data => { data.doctorSelectionList = []; data.doctor_id = null; data.isCallOption = false });
       if(this.state.userType === "FO") {
        this.getDoctorList()
       } 
      });
    }
  };

  getAppointmentFailure = error => {
  //  console.log("Get Appointment error response");
    console.log(error);
  };

  getAppointmentComments = (appointmentId) => {
    /*
    var serviceUrl =
      Constants.HOME_APPOINTMENT_COMMENT +
      "?appointment_id=" +
      appointmentId;

    Service.getInstance().appointmentComments(
      serviceUrl,
      this,
      this.getAppCommentSuccess,
      this.getAppCommentFailure
    );
    */
  };

  getAppCommentSuccess = response => {
    //console.log("Get Appointment comment success response");
   // console.log(response);

    var fields = this.state;
    fields["appointmentComment"] = response.data;
    this.setState({
      fields
    }, () => {
    });
  };

  getAppCommentFailure = error => {
  //  console.log("Get Appointment comment error response");
    console.log(error);
  };

  getAppointmentStat = () => {

    var { userType } = this.state;

    var serviceUrl = "";
    
    if( Constants.ROLE_FO == userType ) {

      serviceUrl = Constants.FO_APPOINTMENT_STAT_OF_STATUS + "?doctor_id=" + (this.state.selectedClinicOrDoctor?this.state.selectedClinicOrDoctor.id:"") + "&appointment_date=" + this.state.selectedDate;
    
    } else if( Constants.ROLE_OPTOMETRY == userType ) {

      serviceUrl = Constants.FO_APPOINTMENT_STAT_OF_STATUS + "?doctor_id=" + (this.state.selectedClinicOrDoctor?this.state.selectedClinicOrDoctor.id:"") + "&appointment_date=" + this.state.selectedDate+ "&clinic_id=" + (this.state.clinic_id?this.state.clinic_id:"");

      // var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
      // this.getAppointments(selectedDateAndTime);

    } else {

      serviceUrl = Constants.DOCTOR_APPOINTMENT_STAT_OF_STATUS + "?clinic_id=" + (this.state.clinic_id ? this.state.clinic_id : this.state.selectedClinicOrDoctor.id ? this.state.selectedClinicOrDoctor.id:"") + "&appointment_date=" + this.state.selectedDate;
    }

    if( serviceUrl ) 
    {
      Service.getInstance().appointmentComments(
        serviceUrl,
        this,
        this.getAppStatSuccess,
        this.getAppStatFailure
      );
    }
  };

  getAppStatSuccess = response => {
    // console.log("Get Appointment stat success response");
    // console.log(response);
    // alert("chec ---->_APPOINTMENT_STAT_---------->  "+JSON.stringify(response));
    var fields = this.state;
    fields["appointmentStat"] = response.data;

    this.setState({
      fields
    },()=>{

      // var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 

      this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"));
      // this.getAppointmentStat();
    });
  };

  getAppStatFailure = error => {
   // console.log("Get Appointment stat error response");
    console.log(error);
  };

  searchPatient = () => {

    if (this.state.searchBar !== "" || this.state.AddressSearchKey !== "" || this.state.FatherHusSearchKey !== "") {
      var serviceUrl =
        Constants.HOME_SEARCH_PATIENT +
        "?search_key=" +this.state.searchBar+
        "&address_key=" + this.state.AddressSearchKey +
        "&father_husband_key=" + this.state.FatherHusSearchKey + 
        "&clinic_id=" + this.state.selectedClinicOrDoctor?.id ;

      Service.getInstance().appointmentComments(
        serviceUrl,
        this,
        this.searchPatientSuccess,
        this.searchPatientFailure
      );
    } else {
      var fields = this.state;
      // fields["searchPatientData"] = [];

      this.setState({
        fields
      });
    }
  };

  searchPatientSuccess = response => {
   // console.log("search patient success response");
    //console.log(response);

    var fields = this.state;
      fields["searchPatientData"] = response.data.patient;
      fields["AddressSearchKey"] = "";
      fields["FatherHusSearchKey"] = "";
      this.props.FilterSearchClose()
      this.props.clearSearchTextBoxValue()
    if (response.data.patient && response.data.patient.length === 0) {
      this.props.showResposeValue("error", "No Records Found !");
    }
    this.setState({
      fields
    });

  };

  searchPatientFailure = error => {
    //console.log("search patient error response");
    console.log(error);
  };


  PrintAppointmentListToken = () => {
    var appointmentDate = this.state.getYear.toString() + "-" + this.state.chengedMonthInNumber.toString() + "-" + this.state.changedDay.toString();
    let states = this.state;
    var service =""
    if( Constants.ROLE_FO == this.state.userType){
      service =Constants.HOME_FO_APPOINTMENT_PDF + "?appointment_date=" + appointmentDate + "&doctor_id=" + (this.state.selectedClinicOrDoctor?this.state.selectedClinicOrDoctor.id:"") + "&export=pdf";
    }else{
      service =Constants.HOME_APPOINTMENT_PDF + "?appointment_date=" + appointmentDate + "&clinic_id=" + this.state.selectedClinicOrDoctor.id + "&export=pdf";
    }
      

    OpthamologyService.getInstance().documentUploadGet(
      service,
      // this,
      this.PrintAppointmentListSuccess,
      this.PrintAppointmentListFailure,
      "pdf"
    );
  };

  PrintAppointmentListSuccess = response => {

    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // alert("111success")
    //  console.log(file)
      window.open(fileURL);
    }
  };

  PrintAppointmentListFailure = error => {
   // console.log("Get clinic list error response");
    console.log(error);
  };

  PrintPatienToken = () => {

    let states = this.state;
    var serviceUrl =
      Constants.HOME_DOCTOR_SINGEL_PATIENT_PRINT_TOKEN + "?appointment_id=" + this.state.selectedItem.appointment_id;

    Service.getInstance().getPrint(
      serviceUrl,
      this,
      this.getPrintSuccess,
      this.getPrintFailure
    );
  };

  getPrintSuccess = response => {

    if (response) {

      // console.log(JSON.stringify(response))

      //Create a Blob from the PDF Stream
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      //Build a URL from the file
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      // console.log(file)
      window.open(fileURL);
    }
  };

  getPrintFailure = error => {
   // console.log("Get clinic list error response");
    console.log(error);
  };



  getClinic = () => {

    let { userType } = this.state;

    if( userType != Constants.ROLE_OPTOMETRY ) 
    {
      var serviceUrl =
        Constants.HOME_GET_DOCTOR_CLINICS;

      Service.getInstance().getClinicList(
        serviceUrl,
        this,
        this.getClinicSuccess,
        this.getClinicFailure
      );
    }
  };

  getClinicSuccess = async (response) => {
    //console.log("Get clinic list success response");
   // console.log(response);

    if (response.detail) {
      this._logoutFunction();
    } else {

      var tempList = [];

      response.data && response.data.length > 0 && response.data.map((item) => {
        tempList.push({ id: item.clinic_id, value: item.clinic_name })
      });

      var fields = this.state;
      fields["clinicData"] = tempList;

      if (response && response.data && response.data.length > 0) {

       var selectedValue =  await AsyncStorage.getItem("Selected_Clinic_Doctor");
        if(selectedValue){
          // this.props.changeHomeScreenPickerValues(selectedValue,)

          this.props.changeHomeScreenPickerValues(
            selectedValue,
            this.state.showPickerDropDownFlag,
            tempList,
            this.state.userType == Constants.ROLE_FO ? "FO" : "Doctor"
          )


              // this.props.changeHomeScreenPickerValues(
              //     selectedValue,
              //     this.state.showPickerDropDownFlag,
              //     this.state.selectedDoctor,
              //     this.state.doctorData,
              //     pickerKey = this.state.userType == Constants.ROLE_FO ? "FO" :"Doctor"
              //     )


          fields["selectedClinicOrDoctor"] = JSON.parse(selectedValue);
        }else{
        await AsyncStorage.setItem("Selected_Clinic_Doctor",JSON.stringify(tempList[0]));
        this.props.changeHomeScreenPickerValues(
          tempList[0],
          this.state.showPickerDropDownFlag,
          tempList,
          this.state.userType == Constants.ROLE_FO ? "FO" : "Doctor"
        )
        fields["selectedClinicOrDoctor"] = tempList[0];
        }
        // this.props.changeStateValues({}, {}, "", tempList[0],{},tempList)
        // this.props.changeScreen("home", {}, "", this.state.selectedClinic)
      }

      this.setState({
        fields
      },()=>{
        // this.unLockDoctor()
      });

      this.getAppointments(moment().format("YYYY-MM-DD"));
      this.getAppointmentStat();
    }
  };

  getClinicFailure = error => {
  //  console.log("Get clinic list error response");
    console.log(error);
  };


  getDoctor = () => {

    var serviceUrl = "";

    if(this.state.userType === Constants.ROLE_FO)
    {
      serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS;
    } 
    else if( this.state.userType === Constants.ROLE_OPTOMETRY )
    {
      serviceUrl = Constants.HOME_GET_OPTOMETRY_DOCTORS;
    }
    else if( this.state.userType === Constants.ROLE_COUNSELLOR )
    {
      serviceUrl = Constants.HOME_GET_CLINIC_DOCTORS + "?clinic_id=" + this.state.clinic_id ? this.state.clinic_id : this.state.selectedClinicOrDoctor.id;
    }

    if( serviceUrl )
    {
      Service.getInstance().getClinicList(
        serviceUrl,
        this,
        this.getDoctorSuccess,
        this.getDoctorFailure
      );
    }
  };

  getDoctorSuccess = async response => {
    
    // console.log("Get doctor list success response");
    // console.log(JSON.stringify(response));

    if (response.detail) {
      this._logoutFunction();
    } else {

      var tempList = [];

      response.data && response.data.length > 0 && response.data.map((item) => {
        tempList.push(
          { 
            id: item.doctor_id, 
            value: item.first_name + " " + item.initial,
            serviceType:item.speciality_name
          })
      });

      var fields = this.state;
      fields["doctorData"] = tempList;

      if (response.data.length > 0) {
        var selectedValue =  await AsyncStorage.getItem("Selected_Clinic_Doctor");
        if(selectedValue){
          fields["selectedClinicOrDoctor"] = JSON.parse(selectedValue);
        }else{
        await AsyncStorage.setItem("Selected_Clinic_Doctor",JSON.stringify(tempList[0]));
        fields["selectedClinicOrDoctor"] = tempList[0];
        }
        // this.props.changeStateValues({}, {}, "", tempList[0],{},tempList)


        // fields["selectedClinicOrDoctor"] = tempList[0];
      }

      this.setState({
        fields,
        doctorSuggestionData : response.data,
        counsellorDoctorData: response.data
      },()=>{
        // if(this.state.isFoAdmin == "true"){    // need to check
          this.checkCounterActiveForQMS(); 
        // }
      });

      this.getAppointments(moment().format("YYYY-MM-DD"));
      this.getAppointmentStat();
    }
  };

  getDoctorFailure = error => {
    console.log("Get doctor list error response");
    console.log(error);
  };

  async getManualToken(appointmentId){
    var clintID="";

    var getClintID = await  AsyncStorage.getItem("UserID");
    if(getClintID){
      if(this.state.isFoAdmin == "true"){
        clintID=getClintID
      }else{
        clintID=this.state.selectedClinicOrDoctor.id
      }
    
    }
    
    var data = {
      "token_type" : "normal",   // 1) normal or 2) priority
      "customer_id" : appointmentId
  }

  // alert("qms") QMS alert
  // var counterID = (this.state.clientID.toString())+this.state.selectedClinicOrDoctor.id.toString()


  var counterID ="";
  if(Object.keys(this.state.selectedClinicOrDoctor).length && this.state.selectedClinicOrDoctor.id){
  if(this.state.isFoAdmin == "true"){ 
    counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
  }else{
    counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
  }
  // alert("getManualToken -------->clintID "+clintID)
  // alert("getManualToken -------->counterID "+counterID)


    var service_url = Constants.QMS_GENERATE_TOKEN+clintID+"/"+counterID+"/";
    OpthamologyService.getInstance().postComplaints(
      service_url,
      JSON.stringify(data),
      this,
      this.getManualTokenSuccess,
      this.getManualTokenFailure,
      "",
      "qms"
    );
  }
  }

  getManualTokenSuccess=(success)=>{
    // alert("*********success************"+JSON.stringify(success))
  }

  getManualTokenFailure=(error)=>{
  //  alert("*********error************"+JSON.stringify(error))

  }

  changeAppointmentStatus = (status, appointmentId, item, istransfer) => {
    this.setState({
      WaitingCommentsVisible: false,
      CancelAppointmentVisible : false,
      isTransfer : false,
      doctor_id: "",
      selectedAppointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedAppointmentTime: moment(new Date()).format("hh:mm"),
      purposeOfVisitsInNextAppoinment:""
    })

    if((status == "Arrived" && this.state.isFoAdmin == "true" && this.state.userType == Constants.ROLE_FO) || (this.state.userType !== Constants.ROLE_FO && this.state.isQMSAccessAvailableUser && status == "Arrived")){
      // alert("getManualToken --------> ");
      if(item.qms_token_digit){
        // do nothing 
      }else{
        // this.getManualToken(appointmentId)

      }

    }else if((status == "Completed" && this.state.isFoAdmin == "true" && this.state.userType == Constants.ROLE_FO) || (this.state.userType !== Constants.ROLE_FO && this.state.isQMSAccessAvailableUser && status == "Completed")){

      this.changeTokenStatusInQMS(item,"complete")
    }

    if (appointmentId || (istransfer == true && this.state.doctor_id != "")) {
      let date = new Date()
      let options = { timeZone: 'Asia/Kolkata', hour12: true, hour: 'numeric', minute: 'numeric' }
      let timeString = date.toLocaleTimeString('en-IN', options)
      var params = {};
      let waitingComments = []
      let currentDateTime = new Date()
      let currentDateTimeFormatted = moment(currentDateTime); //.tz('UTC'); //.format("DD-MM-YYYY HH:mm:ss")
  
      let arrivedDateTimeFormatted = "";
      if (item && item.patient_arrived_time) {
  
        let t = moment(item.patient_arrived_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
        arrivedDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
      }
      let arrivedTimeFormate=""
      if(item && item.patient_arrived_time){
        let time=moment(item.patient_arrived_time);
        arrivedTimeFormate=moment(time).format("LT")  //change 24 hr time format to 12 hr format
      }
  
  
      let withDateTimeFormatted = "";
      if (item && item.patient_with_doctor_time) {
  
        let t = moment(item.patient_with_doctor_time);  //.tz('UTC') //tz("Asia/Kolkata")
        withDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
      }
  
      let timer = item && item.patient_arrived_time ?
        item.patient_with_doctor_time ?
          this.getHHMMSS(
            moment(moment(withDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
          )
          :
          this.getHHMMSS(
            moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
          )
        : "-"
        const waitTime = timer.split(":");
        let time = waitTime[0]+" Hr " + waitTime[1] + " Mins."
      status === "Waiting" ? waitingComments.push({
        "comments":item,
        "time":timeString,
        "date":moment(date).format("YYYY-MM-DD")
      }) : []
      params["appointment_id"] = appointmentId,
        params["appointment_status"] = status === "Waiting" ? '' : status,
        params["is_transfered"] = istransfer ? istransfer : false
        params["transfer_doctor_id"] = istransfer ? this.state.doctor_id : null
        params["waiting_timer"] = time
        //waiting_timer:"0 Hr 0 Mins."
        //send waiting time params
        status == "Waiting" ? params["waiting_comment"] = waitingComments : null
        status == "Cancelled" ? params["cancel_reason"] = item : null

      // console.log("change appointment status params");
      // console.log(params);
      // alert(JSON.stringify(params));
      // console.log("check req--->" + JSON.stringify(params))
      // console.log("///////" + this.state.userType)


      if(Constants.ROLE_OPTOMETRY == this.state.userType){
        params["doctor_id"] = this.state.selectedClinicOrDoctor.id
      }
      var service =""

      if( Constants.ROLE_FO == this.state.userType || Constants.ROLE_OPTOMETRY == this.state.userType ){
        service =Constants.HOME_APPOINTMENT_CHANGE_STATUSFO
      }else{
        service =Constants.HOME_APPOINTMENT_CHANGE_STATUSDR
      }

      Service.getInstance().changeAppointmentStatus(
       
        service,
        params,
        this,
        this.changeAppointmentStatusSuccess,
        this.changeAppointmentStatusFailure,
        this.state.userType
      );
    } else {
      console.log("Please enter valid appointment id or doctor id");
    }
  };

  changeAppointmentStatusSuccess = response => {
    this.setState({
      WaitingCommentsVisible : false
    })
    // console.log("change Appointment status success response");
    // console.log(response);
    // alert(JSON.stringify(response))
    if (response.status === "success"){
      if(this.state.isPatientTransfer) {
        this.onPressTransferData()
      }
      var arr = this.state.selectedDate.split("-");
      this.getAppointmentStat();
      this.getAppointments()
    }else{
      this.props.showResposeValue("error",response.message);
    }
  };

  changeAppointmentStatusFailure = error => {  
    console.log(error);
  };

  todayAppointment = (date) => {
    // var getCurrentYear = moment().format("YYYY");
    // var getCurrentMonth = moment().format("MM");
    // var getCurrentDate = moment().format("DD");

    // var day = this.state;

    // day["day1"] = parseInt(getCurrentDate) - 2;
    // day["day2"] = parseInt(getCurrentDate) - 1;
    // day["day3"] = parseInt(getCurrentDate);
    // day["day4"] = parseInt(getCurrentDate) + 1;
    // day["day5"] = parseInt(getCurrentDate) + 2;

    // day["changedDay"] = parseInt(getCurrentDate);


    // if (this.state.day3 === 1) {
    //   this.setState({
    //     day1: this.state.day1 + 2,
    //     day2: this.state.day2 + 2,
    //     day3: this.state.day3 + 2,
    //     day4: this.state.day4 + 3,
    //     day5: this.state.day5 + 4,
    //     changedDay: this.state.day1 + 2,
    //   });
    // }
    // if (this.state.day3 === 2) {
    //   this.setState({
    //     day1: this.state.day1 + 1,
    //     day2: this.state.day2 + 1,
    //     day3: this.state.day3 + 1,
    //     day4: this.state.day4 + 1,
    //     day5: this.state.day5 + 1,
    //     changedDay: this.state.day2 + 2,
    //   });
    // }


    // // if(dateChange){
    // this.setState({
    //   day,
    //   getMonth: this.state.totalMonths[getCurrentMonth - 1].month,
    //   getYear: getCurrentYear,
    //   getDate: getCurrentDate,
    // })

    // var fields = this.state;

    // fields["selectedDate"] = moment(today).format("DD-MM-YYYY");
    this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
    // this.setState({
    //   fields
    // });
  // }else{
  //   var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 

  //   this.getAppointments(selectedDateAndTime);
  // }

  }

  renderHeaderBtn = (imagePath, btnName, isBottom, isThemecolor = false) => {
    return (
      <View
        style={{
          width: 80,
          // width: platform === "web" ? screenWidth / 23 : 90, // 70 : 90,
          // height: platform === "web" ? screenWidth / 15 : 60, // 80 : 60,
          borderColor: color.listAlternate,
          // borderWidth: 0.1,
          borderRadius: 10,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          //backgroundColor: color.white
        }}
      >
        <Image
          source={imagePath}
          resizeMode="cover"
          style={{
            height: isBottom ? screenWidth / 23 : 25, // 65 : 25,
            width: isBottom ? screenWidth / 23 : 25,  // 65 : 25,
            alignSelf: "center",
            tintColor: isThemecolor ? color.themeDark : null //color.lightGray  
          }}
        />

        <Text
          numberOfLines={2}
          //numberOfLines={btnName === "Unlocked Doctor Notes" ? 2 : 1}
          style={{
            color: isThemecolor ? color.black : isBottom ? color.lightGray : color.black,
            marginTop: platform === "web" ? 0 : 10,
            bottom: 4,
            textAlign: "center",
            fontSize: 10
          }}
        >
          {btnName}
        </Text>
      </View>
      //   </TouchableOpacity>
      // </View>
    );
  };

  renderDateBtn = (dateValue, btnName, isBottom, selectedDate = false) => {
    return (

      <View
        style={{
          width: platform === "web" ? 22 : 90, // screenWidth / 30  //  70 : 90,
          height: platform === "web" ? 22 : 60, // screenWidth / 15    // 80 : 60,
          borderColor: color.listAlternate,
          backgroundColor: selectedDate ? color.themePink : color.white,
          // borderWidth: 0.1,column
          borderRadius: 20,
          flexDirection: "row",
          justifyContent: "center",
          alignContent: "center"
        }}
      >
        <Text
          style={{
            // height: isBottom ? screenWidth / 23 : 25, // 65 : 25,
            // width: isBottom ? screenWidth / 23 : 25,  // 65 : 25,
            alignSelf: "center",
            color: selectedDate ? color.white : color.lightGray,
            fontSize: 11
          }}
        >{dateValue}</Text>
      </View>
      // </View>
    );
  };
 
  renderStatValue = (image, title, value,colorValue,colorText) => {
    return (
      <View style={{ flexDirection: "column", marginRight: 7 }}>
       
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <View
            style={{
              justifyContent: "flex-end",
              alignItems: "flex-end",
              paddingLeft: "1.62vw"
            }}
          >
            {
              image ? 
              <Image source={image} style={{ width: 10, height: 10 }} /> : null
            }
            {/* <Image source={image} style={{ width: 10, height: 10 }} /> */}
          </View>
          <View style={{ justifyContent: "flex-end", alignItems: "flex-end" }}>
            <Text style={{ fontSize: "1.8vw", fontWeight: "500",color:colorValue }}>{value}</Text>
          </View>
        </View>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <Text style={{ textAlign: "center",fontWeight: "500", fontSize: ".75vw", color:colorText }}>{title}</Text>
        </View>
      </View>
    );
  };

  async changePickerDropDownValue(flag,selectedValue,pickerKey){
    
    var states = this.state;
    var valueChanged=false;
    var selectedDR_Clinic={};
    var selectedClinicOrDoctor=states["selectedClinicOrDoctor"];
    
    if(selectedValue){
      if(!selectedClinicOrDoctor || (selectedClinicOrDoctor && selectedClinicOrDoctor.id!==selectedValue.id)){
        valueChanged=true;
      }
    }

    if(pickerKey == "Doctor"){
      
      states["showPickerDropDownFlag"] = flag
      if(selectedValue){
        await AsyncStorage.setItem("Selected_Clinic_Doctor",JSON.stringify(selectedValue));
        states["selectedClinicOrDoctor"]=selectedValue;
        selectedDR_Clinic=selectedValue;
      }
    }else if(pickerKey == "FO" || props.userType == Constants.ROLE_OPTOMETRY){
      
      states["showPickerDropDownFlag"] = flag
      if(selectedValue){
        await AsyncStorage.setItem("Selected_Clinic_Doctor",JSON.stringify(selectedValue));
        states['selectedClinicOrDoctor']=selectedValue;
        selectedDR_Clinic=selectedValue;
      }
    }



    this.setState({
      states
    },()=>{
      // this.props.changeStateValues({}, {}, "", this.state.selectedClinic,selectedDR_Clinic,this.state.clinicData)
      // this.props.changeScreen("home", {}, "", this.state.selectedClinic)

      var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
      
      
      // on change stats data retrieve
      if(valueChanged){
        this.getAppointments(selectedDateAndTime);
        this.getAppointmentStat();
      }
    })
  }

  renderStatView = () => {
    return (
      <View
        style={{
          height: 70,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          paddingLeft: 20,
          // paddingTop: 5
        }}
      >
        <View style={{ width: "25%"}}>
         
         {/* {
           this.state.userType == Constants.ROLE_FO  ?
           <CommonPicker 
           showPickerDropDownFlag = {this.state.showPickerDropDownFlag} 
           changePickerDropDownValue = {this.changePickerDropDownValue.bind(this)}
           selectedValue={this.state.selectedDoctor}
           arrayOfData={this.state.doctorData}
           pickerKey="FO"
           /> :
         <CommonPicker 
          showPickerDropDownFlag = {this.state.showPickerDropDownFlag} 
          changePickerDropDownValue = {this.changePickerDropDownValue.bind(this)}
          selectedValue={this.state.selectedClinic}
          arrayOfData={this.state.clinicData}
          pickerKey="Doctor"
          />

         } */}
          
          
          {/* {this.state.userType == Constants.ROLE_FO ?
            <Picker
              selectedValue={this.state.selectedDoctor ? this.state.selectedDoctor['id'] : null}
              style={Style.pickerRoundCornerView}
              itemStyle={Style.pickerFont}
            >
              <Picker.Item key={-1} label={"Select Doctor"} value={0} />
              {this.state.doctorData && this.state.doctorData.map((i, index) => (
                <Picker.Item key={index} label={i.value} value={i.id} />
              ))}
            </Picker>
            :
            <Picker
              selectedValue={this.state.selectedClinic.value}
              style={Style.pickerRoundCornerView}
              itemStyle={Style.pickerFont}
              onValueChange={(itemvalue) => {
                var {selectedClinic} = this.state;
                this.setState({
                  selectedClinic
                }, () => {
                  alert(JSON.stringify(this.state.selectedClinic))
                  alert(JSON.parse(JSON.stringify(itemvalue)))
                  this.getAppointments(moment().format("YYYY-MM-DD"));
                })
              }}
            >
              <Picker.Item key={-1} label={"Select Clinic"} value={0} />
              {this.state.clinicData.map((item, index) => {
                return(
                <Picker.Item key={index} label={item.value} value={item} />
                )
              })}
            </Picker>
          } */}
        </View>

        <View style={{ width: "30%" }}>
          <SearchBar
            round={true}
            containerStyle={Style.SearchBarContainer}
            inputContainerStyle={Style.SearchBarInnerContainer}
            inputStyle={Style.SearchBarInput}
            placeholder="Search Patient..."
            value={this.state.searchBar}
            ref={text => (this.searchBar = text)}
            onChangeText={text => this.searchPatient(text)}

          // searchBarStyle="minimal"
          // onSearchButtonPress={...}
          // onCancelButtonPress={...}
          />
        </View>

        <View style={{ flexDirection: "row" }}>
          <View
            style={{
              flexDirection: "row",
              paddingLeft: 10,
              paddingRight: 10,
              alignItems: "center",
              justifyContent: "center"
            }}
          >
            <View style={{ width: 50 }}>
              <Text style={{ fontSize: ".85vw" }}>Booked Patients</Text>
            </View>
            <View style={{ paddingLeft: 5 }}>
              <Text style={{ fontSize: "2.6vw", fontWeight: "500" }}>{this.state.appointmentStat && this.state.appointmentStat.today_patients ? this.state.appointmentStat.today_patients :"0"}</Text>
            </View>
          </View>
          <TouchableOpacity style={[styles.arrivedStyle]} >
            {this.renderStatValue(
              require("../../../../assets/images/checked_in_patients_icon.png"),
              "Arrived Patients",
              this.state.appointmentStat && this.state.appointmentStat.checked_in_patients ?
              this.state.appointmentStat.checked_in_patients :"0",this.state.selectedAppointmentStatus === "Arrived" ? color.themeDark :color.black
            )}
            {this.renderStatValue(
              require("../../../../assets/images/with_doctor_icon.png"),
              "With Doctor",
              this.state.appointmentStat && this.state.appointmentStat.with_doctor ?
              this.state.appointmentStat.with_doctor : "0",this.state.selectedAppointmentStatus === "With Doctor" ? color.themeDark :color.black
            )}
            {this.renderStatValue(
              require("../../../../assets/images/checked_out_icon.png"),
              "Completed Patients",
              this.state.appointmentStat && this.state.appointmentStat.checked_out_patients ?
              this.state.appointmentStat.checked_out_patients : "0",this.state.selectedAppointmentStatus === "Completed" ? color.themeDark :color.black
            )}
          </TouchableOpacity>
        </View>
      </View>
    );
  };


  sendDilationData(item = "") {
    // alert(JSON.stringify(item))
    var data = {
      "appointment_id": item.appointment_id,
      "dilation": this.state.dilation
    }

    var service_url = Constants.OPTHALMOLOGT_DILATION;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.sendDilationDataSuccess,
      this.sendDilationDataFailure
    );
  }

  sendDilationDataSuccess = success => {
   // console.log("vitalsPostSuccess ****************> " + JSON.stringify(success));
    if (success.status == "success"){
        var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 

        this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"));
    }

  }
  sendDilationDataFailure = error => {
    console.log("vitalsPosterror ****************> " + JSON.stringify(error));
  }

  // showPendingRQAndUnlickDRNotesView(image,key,tooltip){
  //   return(
  //     <TouchableOpacity onPress={()=>{
  //       if(key == "Appointment_Request")
  //         this.appointmentRequest();
  //       else if(key == "unLockDoctorNotes")
  //         this.props.unLockDoctorNotesModalFlag(true, this.state.unLockDoctor)

  //     }} style={{marginHorizontal:10,marginBottom:10, backgroundColor:color.themeDark,borderRadius:4,paddingHorizontal:5,paddingVertical:5,alignItems:'center',alignSelf:'center'}}>
  //      <Tooltip placement="top" title={tooltip}>
  //       <Image
  //       source={image}
  //       style={{height:30,width:30}}
  //       />
  //       </Tooltip>
  //     </TouchableOpacity>
  //   )
  // }
  renderPatientTypeDifference(text,color,count){
    return(
      <View style={[styles.patientTypeViewStyle]}>
        <RoundIcon name={"controller-record"} color={color} size="0.95vw"/>
        <Text style={[styles.patientTypeText]}>{text}{"  ( "}{count}{" )"}</Text>
      </View>
    )
  }

  renderHeaderView = () => {
    return (
      <View>
        <View style={[styles.titleContainer]}>
          <View style={[styles.titleHeader]}>
            <Text style={styles.appointmentDiv}>
            {"Appointments"}
            </Text>
            <View style={[styles.datePickerComponent]}>
              {/* {this.showCalendar()} */}
           {this.state.isQMSEnable && this.state.tokenGenerationType != "Doctor"?
             <TouchableOpacity
                style={[styles.todayPatientstyle1]}
                onPress={() => {
                  this.setState({
                    selectedAppointmentStatus:"On Queue",
                    isOnQueueClick: true,
                    onPressTodayPatient: false,
                    appointmentData: []
                  }, () => {
                    this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                    this.getAppointmentStat()
                    this.props.getSelectedAppointmentStatus("On Queue")
                  })
                }}
              >
                <View style={[styles.todayPatientstyle2, {alignSelf: "center" }]}>
                  <Text style={{ fontSize: "0.84vw", fontWeight: "500", color: this.state.isOnQueueClick === true ? color.themeDark : color.black}}>On Queue</Text>
                </View>
                <View style={{ paddingLeft: 5 }}>
                  <Text style={{ fontSize: "2.6vw", fontWeight: "500", color: this.state.isOnQueueClick === true ? color.themeDark : color.black }}>{this.state.appointmentStat && this.state.appointmentStat.on_queue ? this.state.appointmentStat.on_queue : "0"}</Text>
                </View>
              </TouchableOpacity> : null
             }
             { this.state.userType != Constants.ROLE_COUNSELLOR ?  <TouchableOpacity
                style={[styles.todayPatientstyle1]}
                onPress={() => {
                  this.setState({
                    selectedAppointmentStatus: "",
                    onPressTodayPatient: true,
                    isOnQueueClick: false,
                    appointmentData: []
                  }, () => {
                    var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate;
                    this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                    this.getAppointmentStat()
                  })
                }}
              >
                <View style={[styles.todayPatientstyle2]}>
                  <Text style={{ fontSize: "0.84vw", fontWeight: "500", color: this.state.onPressTodayPatient === true ? color.themeDark : color.black }}>Booked Patients</Text>
                </View>
                <View style={{ paddingLeft: 5 }}>
                  <Text style={{ fontSize: "2.6vw", fontWeight: "500", color: this.state.onPressTodayPatient === true ? color.themeDark : color.black }}>{this.state.appointmentStat && this.state.appointmentStat.today_patients ? this.state.appointmentStat.today_patients : "0"}</Text>
                </View>
              </TouchableOpacity> : null
             }
             
              <Text style={styles.dateLable}>Select Date</Text>
              <DatePicker
              clearIcon={false}
              defaultValue={moment(this.state.selectedDate)}
              format={"YYYY-MM-DD"}
              style={{borderColor: color.themeDark, borderWidth: 2, width: "10.1vw", fontSize:'1.1vw', height:"2.1vw"}}   
              onChange={(date) => {
                var fulldate = new Date(date);
                var converted_date = moment(fulldate).format("YYYY-MM-DD");
                
                this.getAppointments(converted_date); 
                this.props.currentAppointmentsDate(converted_date)
                this.setState({
                  selectedDate: converted_date
                }, () => {
                  this.getAppointmentStat()
                })
                
              }}          
            />
          </View>
          </View>
        </View>
      <View style={[styles.headerView]}>
        <View style={styles.appointmentContainer}>

          {
            this.state.userType == Constants.ROLE_OPTOMETRY || this.state.userType == Constants.ROLE_COUNSELLOR ? null :
            <TouchableOpacity disabled={this.state.selectedClinicOrDoctor.value=="Nursing  "? true : false}
              style={{ marginLeft: "4vw" }}
              onPress={() => {
                this.props.changeScreen("demographics")
                // this.props.navigation.navigate("demographics")
              }}
            >
              <Tooltip placement="topLeft" title={"Create Patient"}>
              <View
                style={{
                  boxShadow: '0px 3px 6px #00000049',
                  opacity: 1,
                  borderRadius: "50vw",
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: "3vw",
                  height: "3vw",
                  backgroundColor: this.state.selectedClinicOrDoctor.value=="Nursing  "? color.grayShade : color.white,
                }}
              >
                
                <Icon name="user-plus" size="1.7vw" color={this.state.selectedClinicOrDoctor.value=="Nursing  "?color.white:color.themeDark} />
                
              </View>
              </Tooltip>
            </TouchableOpacity>
          }
          { this.props.userType === Constants.ROLE_OPTOMETRY || this.state.userType == Constants.ROLE_COUNSELLOR ? null :
           <View style={{flexDirection:"column"}}>
            {this.renderPatientTypeDifference("New Patient",color.red,this.state.appointmentCount ? this.state.appointmentCount.new_patient : 0)}
            {this.renderPatientTypeDifference("Review Patient",color.lightBlue,this.state.appointmentCount ? this.state.appointmentCount.review_patient : 0)}
            {this.renderPatientTypeDifference("Established Patient",color.themeDark,this.state.appointmentCount ? this.state.appointmentCount.old_patient : 0)}

           </View>
          }
            {/* {this.renderIsAvailableIndicatuion()} */}     
              {/* {"New Patient"}
            </Text>
          </View>
        </TouchableOpacity> */}
         {/* QMS */}
              {
                this.state.isFoAdmin == "true" && this.state.userType == Constants.ROLE_FO ?
                <View style={styles.switchDiv}> 
                {/* {this.renderIsAvailableIndicatuion()} */}
                <Text style={styles.outText}>{"Out"}</Text>
                <SwitchToggle 
            containerStyle={{
              width: "3.6vw",
              height: "2vw",
              borderRadius: "2vw",
              backgroundColor: "#ccc",
              padding: ".3vw"
            }}
            circleStyle={{
              width: "1.4vw",
              height: "1.4vw",
              borderRadius: "2vw",
              backgroundColor: "white" // rgb(102,134,205)
            }}
            circleColorOff="white"
            circleColorOn={color.themeDark}
            style={{background:color.themeDark}}
            switchOn={this.state.isAvailableIndicatuionFlag} 
            onPress={()=>{
              this.isAvailableIndicatuionChangeFlag() 
              // this.setState({isAvailableIndicatuionFlag:!this.state.isAvailableIndicatuionFlag})}
            }}/>
                            <Text style={styles.inText}>{"In"}</Text>
                </View> : null
              }
            {/* QMS */}
           
        </View>

        {/* <View style={{ flex: 0.35 }}>
          {this.showCalendar()}
        </View> */}


        <View style={styles.dataContainer}>
          

          {/* <TouchableOpacity
            onPress={()=> {
              this.appointmentRequest();
              
            }
            //   this.setState({
            // })

          }
            style={{ marginTop: 10 }}
          >
            {this.renderHeaderBtn(require("../../../../assets/images/Appointment_Request.png"),
              "",
              false, false)}
          </TouchableOpacity>

          <TouchableOpacity
           onPress={()=> { this.props.unLockDoctorNotesModalFlag(true, this.state.unLockDoctor) }}
            style={{ marginTop: 10 }}
          >
            {this.renderHeaderBtn(require("../../../../assets/images/unclock.png"),
              "",
              false, false)}
          </TouchableOpacity> */}

          {/* <TouchableOpacity
            style={{ marginTop: 10 }}
          >
            {this.renderHeaderBtn(require("../../../../assets/images/clock_icon.png"),
              "Today",
              false, true)}
          </TouchableOpacity>

          <TouchableOpacity
            // style={{width:80}}
            style={{ marginTop: 10 }}
            onPress={() => this.PrintAppointmentListToken()}
          >
            {this.renderHeaderBtn(require("../../../../assets/images/opthamology/print_appointment_color.png"),
              "Appointments",
              false, true)}
          </TouchableOpacity>

          {this.showPendingRQAndUnlickDRNotesView(
            require("../../../../assets/images/Appointment_Request.png"),
            "Appointment_Request",
            "Appointment Requests"
          )}

          {
            this.state.userType == Constants.ROLE_FO ?
            null :
            this.showPendingRQAndUnlickDRNotesView(
              require("../../../../assets/images/Unlock_Doctor_Note.png"),
              "unLockDoctorNotes",
              "UnLock Doctor Notes"
            )
          } */}

        {this.state.userType == Constants.ROLE_COUNSELLOR ? null : 
        <View style={[styles.totalPatientDiv]}>
          <View style={[styles.arrivedStyle]}>
          <TouchableOpacity 
             onPress={()=>{
               this.setState({
                selectedAppointmentStatus:"Checked_In",
                onPressTodayPatient:false,
                isOnQueueClick: false,
                appointmentData: []

               },()=>{
                var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
               })
             }}
          >
            {this.renderStatValue(
              "", // require("../../../../assets/images/checked_in_patients_icon.png"),
              "Checked In Patients",
              this.state.appointmentStat && this.state.appointmentStat.total_checked_in ?
              this.state.appointmentStat.total_checked_in :"0",this.state.selectedAppointmentStatus === "Checked_In" ? color.themeDark : color.black ,this.state.selectedAppointmentStatus === "Checked_In" ? color.themeDark : color.black 
            )}
           </TouchableOpacity>
          <TouchableOpacity 
             onPress={()=>{
               this.setState({
                selectedAppointmentStatus:"Arrived",
                onPressTodayPatient:false,
                isOnQueueClick: false,
                appointmentData: []

               },()=>{
                var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
               })
             }}
          >
            {this.renderStatValue(
              "", // require("../../../../assets/images/checked_in_patients_icon.png"),
              "Arrived Patients",
              this.state.appointmentStat && this.state.appointmentStat.checked_in_patients ?
              this.state.appointmentStat.checked_in_patients :"0",this.state.selectedAppointmentStatus === "Arrived" ? color.themeDark : color.black ,this.state.selectedAppointmentStatus === "Arrived" ? color.themeDark : color.black 
            )}
           </TouchableOpacity>
           {((this.state.userType === Constants.ROLE_OPT) || (this.state.userType === Constants.ROLE_OPTOMETRY) || this.state.clinicType) ?
           <TouchableOpacity 
           onPress={()=>{
             this.setState({
              selectedAppointmentStatus:"Optometry",
              onPressTodayPatient:false,
              isOnQueueClick: false,
              appointmentData: []

             },()=>{
              var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
              this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
              this.getAppointmentStat()

            })
           }}
         > 
          {this.renderStatValue(
            "", // require("../../../../assets/images/with_doctor_icon.png"),
            "Optometry",
            this.state.appointmentStat && this.state.appointmentStat.with_optometry ?
            this.state.appointmentStat.with_optometry : "0", this.state.selectedAppointmentStatus === "Optometry" ? color.themeDark : color.black , this.state.selectedAppointmentStatus === "Optometry" ? color.themeDark : color.black 
          )}
         </TouchableOpacity>
         : null 
           }
            <TouchableOpacity 
             onPress={()=>{
               this.setState({
                selectedAppointmentStatus:"With Nurse",
                onPressTodayPatient:false,
                isOnQueueClick: false,
                appointmentData: []

               },()=>{
                var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()

              })
             }}
           > 
           {this.renderStatValue(
              "", // require("../../../../assets/images/with_doctor_icon.png"),
              "With Nurse",
              this.state.appointmentStat && this.state.appointmentStat.with_nurse ?
              this.state.appointmentStat.with_nurse : "0", this.state.selectedAppointmentStatus === "With Nurse" ? color.themeDark : color.black , this.state.selectedAppointmentStatus === "With Nurse" ? color.themeDark : color.black 
            )}
            </TouchableOpacity>

           <TouchableOpacity 
             onPress={()=>{
               this.setState({
                selectedAppointmentStatus:"With Doctor",
                onPressTodayPatient:false,
                isOnQueueClick: false,
                appointmentData: []

               },()=>{
                var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()

              })
             }}
           > 
            {this.renderStatValue(
              "", // require("../../../../assets/images/with_doctor_icon.png"),
              "With Doctor",
              this.state.appointmentStat && this.state.appointmentStat.with_doctor ?
              this.state.appointmentStat.with_doctor : "0", this.state.selectedAppointmentStatus === "With Doctor" ? color.themeDark : color.black , this.state.selectedAppointmentStatus === "With Doctor" ? color.themeDark : color.black 
            )}
           </TouchableOpacity>
           {((this.state.userType === Constants.ROLE_OPT) || (this.state.userType === Constants.ROLE_OPTOMETRY) || this.state.clinicType) ? 
           <TouchableOpacity 
           onPress={()=>{
             this.setState({
              selectedAppointmentStatus:"Scan/Surgery",
              onPressTodayPatient:false,
              isOnQueueClick: false,
              appointmentData: []

             },()=>{
              var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
              this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
              this.getAppointmentStat()

            })
           }}
         > 
          {this.renderStatValue(
            "", // require("../../../../assets/images/with_doctor_icon.png"),
            "Scan/Surgery",
            this.state.appointmentStat && this.state.appointmentStat.scan ?
            this.state.appointmentStat.scan : "0", this.state.selectedAppointmentStatus === "Scan/Surgery" ? color.themeDark : color.black , this.state.selectedAppointmentStatus === "Scan/Surgery" ? color.themeDark : color.black 
          )}
         </TouchableOpacity>
         : null
           }
           
           <TouchableOpacity
              style={{}}
              onPress={()=>{
                this.setState({
                  selectedAppointmentStatus:"Completed",
                  onPressTodayPatient:false,
                  isOnQueueClick: false,
                  appointmentData: []
                },()=>{
                  var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
                  this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                  this.getAppointmentStat()
                })
              }}
           > 
            {this.renderStatValue(
              "", // require("../../../../assets/images/checked_out_icon.png"),
              "Completed Patients",
              this.state.appointmentStat && this.state.appointmentStat.checked_out_patients ?
              this.state.appointmentStat.checked_out_patients : "0", this.state.selectedAppointmentStatus === "Completed" ? color.themeDark : color.black, this.state.selectedAppointmentStatus === "Completed" ? color.themeDark : color.black 
            )}
           </TouchableOpacity> 
           <TouchableOpacity
              style={{}}
              onPress={()=>{
                this.setState({
                  selectedAppointmentStatus:"Cancelled",
                  onPressTodayPatient:false,
                  isOnQueueClick: false,
                  appointmentData: []
                },()=>{
                  var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; 
                  this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                  this.getAppointmentStat()
                })
              }}
           > 
            {this.renderStatValue(
              "",
              "Cancelled Patients",
              this.state.appointmentStat && this.state.appointmentStat.cancelled_patients ?
              this.state.appointmentStat.cancelled_patients : "0", this.state.selectedAppointmentStatus === "Cancelled" ? color.themeDark : color.black, this.state.selectedAppointmentStatus === "Cancelled" ? color.themeDark : color.black 
            )}            
           </TouchableOpacity> 
          </View>
        </View>
        }
          
        </View>
        {/* <TouchableOpacity
          style={{ marginLeft: 5 }}
          onPress={() => {
            this.props.changeScreen("demographics")
            // this.props.navigation.navigate("demographics")
          }}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              flex: 0.2,
              backgroundColor: color.themeDark,
              borderRadius: 5,
              minHeight: 35,
              paddingHorizontal: 10
            }}
          >
            <Icon name="plus" size={18} color={color.white} />
            <Text
              style={{
                fontSize: 10,
                color: color.white,
                fontWeight: "700",
                marginLeft: 5
              }}
            >
              {"New Patient"}
            </Text>
          </View>
        </TouchableOpacity> */}
      </View>
      </View>
    );
  };

  renderAppointmentTitle = () => {
    return (
      <View>
      {(Constants.ROLE_COUNSELLOR == this.state.userType) ? 
      <View
      style={[
        {  
          alignSelf: 'center',
          width: "97%",
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: color.appointmentTableHead,
          height: "3.25vw",
          borderRadius: ".5vw",
          marginTop:5
        }]}>
        <View style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }} >
          
          {
            this.state.sortValue === "" ? 
            <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
              this.setState({
                sortValue: "asc",
                pageLoading: true,
                appointmentData: [],
                appointmentCount: {},
                appointmentStat: {}
              }, () => {
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
              })
            }}>
            <Text style={[Style.appointmentTableHeadText, {marginRight: 10}]}>PATIENT'S DETAILS</Text>
            <Icon name="sort" size={"1vw"} color={color.black} />
            </TouchableOpacity> : this.state.sortValue === "asc" ? 
            <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
              this.setState({
                sortValue: "des",
                pageLoading: true,
                appointmentData: [],
                appointmentCount: {},
                appointmentStat: {}
              }, () => {
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
              })
            }}>
            <Text style={[Style.appointmentTableHeadText, {marginRight: 10}]}>PATIENT'S DETAILS</Text>
            <Icon name="sort-up" size={"1vw"} color={color.black} />
            </TouchableOpacity> : this.state.sortValue === "des" ? 
            <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
              this.setState({
                sortValue: "",
                pageLoading: true,
                appointmentData: [],
                appointmentCount: {},
                appointmentStat: {}
              }, () => {
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
              })
            }}>
            <Text style={[Style.appointmentTableHeadText, {marginRight: 10}]}>PATIENT'S DETAILS</Text>
            <Icon name="sort-down" size={"1vw"} color={color.black} />
            </TouchableOpacity> : null
          }
        </View>
        <View style={{ paddingLeft: 20, width: "17%", alignItems: "flex-start" }} >
          <Text style={Style.appointmentTableHeadText}>GENDER</Text>
        </View>
        <View style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }} >
          <Text style={Style.appointmentTableHeadText}>MOBILE NUMBER</Text>
        </View>
        <View style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }} >
          <Text style={Style.appointmentTableHeadText}>REFERRED DOCTOR</Text>
        </View>
        <View style={{ paddingHorizontal: 20, width: "20%", alignItems: "flex-start" }} >
          <Text style={Style.appointmentTableHeadText}>SURGERY SUGGEST</Text>
        </View>

      </View>
      :
      <View
        style={[
          // Style.appointmentTableRow
          , {
            // marginLeft: 15,
            // marginRight: 15, 
            // alignContent:'center',    
            alignSelf: 'center',

            width: "91vw",
            flexDirection: "row",
            // justifyContent: "space-evenly",
            alignItems: "center",
            backgroundColor: color.appointmentTableHead,
            height: "3.25vw",
            borderRadius: ".5vw",
            zIndex: -1
            // paddingLeft:20
          }]}
      >
        <View style={{ width: "15.3vw", flexDirection: "row", paddingLeft: '2vw'}} >
          
          {
            this.state.sortValue === "" ? 
            <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
              this.setState({
                sortValue: "asc",
                pageLoading: true,
                appointmentData: [],
                appointmentCount: {},
                appointmentStat: {}
              }, () => {
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
              })
            }}>
            <Text style={[Style.appointmentTableHeadText, {marginRight: 10}]}>PATIENT'S DETAILS</Text>
            <Icon name="sort" size={"1.4vw"} color={color.black} />
            </TouchableOpacity> : this.state.sortValue === "asc" ? 
            <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
              this.setState({
                sortValue: "des",
                pageLoading: true,
                appointmentData: [],
                appointmentCount: {},
                appointmentStat: {}
              }, () => {
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
              })
            }}>
            <Text style={[Style.appointmentTableHeadText, {marginRight: 10}]}>PATIENT'S DETAILS</Text>
            <Icon name="sort-up" size={"1.4vw"} color={color.black} />
            </TouchableOpacity> : this.state.sortValue === "des" ? 
            <TouchableOpacity style= {{flexDirection: "row"}} onClick={() => {
              this.setState({
                sortValue: "",
                pageLoading: true,
                appointmentData: [],
                appointmentCount: {},
                appointmentStat: {}
              }, () => {
                this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"))
                this.getAppointmentStat()
              })
            }}>
            <Text style={[Style.appointmentTableHeadText, {marginRight: 10}]}>PATIENT'S DETAILS</Text>
            <Icon name="sort-down" size={"1.4vw"} color={color.black} />
            </TouchableOpacity> : null
          }

        </View>
        {/* <View style={{ width: "6%", flexDirection: "row", paddingLeft: 20 }} >
          <Text style={Style.appointmentTableHeadText}>AGE</Text>
        </View> */}
        <View style={{ width: "6vw", alignItems: 'center' }}>
          <Text style={Style.appointmentTableHeadText}>MOBILE NO</Text>
        </View>

        <View style={{ width: '6vw', alignItems: "center", }}  >
          <Text style={Style.appointmentTableHeadText}>TIME</Text>
        </View>
        {/* <View style={{ width: '6%', alignItems: "center", }}  >
          <Text style={Style.appointmentTableHeadText}>ARR.TIME</Text>
        </View> */}

        <View style={{ width: '7.5vw', alignItems: "center", }}  >
          <Text style={Style.appointmentTableHeadText}>ARR.TIME / WAITING TIME</Text>
        </View>

        <View style={{ width: "8vw", alignItems: "center" }} >
          <Text style={Style.appointmentTableHeadText}>TOKEN NO</Text>
        </View>

        <View style={{ width: '9vw' , alignItems: "center" }} >
          <Text style={Style.appointmentTableHeadText}>PURPOSE OF VISIT</Text>
        </View>
        {/* {
          this.state.userType == Constants.ROLE_OPT || this.state.clinicType ? */}
        <View style={{ width: '5vw', alignItems: "center", }} >
          <Text style={Style.appointmentTableHeadText}>PATIENT'S TAG</Text>
        </View> 
        <View style={{ width: '5vw', alignItems: "center", }} >
          <Text style={Style.appointmentTableHeadText}>PENDING AMOUNT (₹)</Text>
        </View> 
        {/* } */}

        {/* <View style={{ width: "9%", alignItems: "center", }} >
          <Text style={Style.appointmentTableHeadText}>WAITING TIME</Text>
        </View> */}

        <View style={{ width: "18vw", alignItems: 'center' }}>
          <Text style={Style.appointmentTableHeadText}>STATUS</Text>
        </View>

        <View style={{ width: "12vw", alignItems: 'center' }}>
          <Text style={Style.appointmentTableHeadText}>ACTION</Text>
        </View>
      </View>
      }

      </View>


      
    );
  };

  onclickAppointment(item, index,screenName="optDoctorNotes") {
    var fields = this.state;
    let appointmentData = fields["appointmentData"];

    fields["patientId"] = item.patient_id;
    fields["selectedIndex"] = index;
    // fields["selectedItem"] = appointmentData[index];        // this.state.appointmentData[index]
    fields["selectedItem"] = item;        // this.state.appointmentData[index]
    // fields["appointmentData"] = JSON.parse(JSON.stringify(appointmentData));

    this.setState({ fields }, () => {

     var patientAppointment = item;
     var userType = this.state.userType;
     var selectedClinicOrDoctor = this.state.selectedClinicOrDoctor;

      this.props.changeScreen(screenName,patientAppointment,userType,selectedClinicOrDoctor)
      // this.props.navigation.navigate("optDoctorNotes", {
      //   // patientAppointment: this.state.selectedItem,
      //   // userType: this.state.userType,
      //   patientAppointment: item,
      //   userType: this.state.userType,
      // })
    });


    // alert(JSON.stringify(item))

    //this.setState({ patientId: item.patient_id })

    // this.getAppointmentComments(item.appointment_id);


  }

  getHHMMSS(ms) {
     var days = Math.floor(ms / (24*60*60*1000));
     var daysms=ms % (24*60*60*1000);
     var hours = Math.floor((daysms)/(60*60*1000));
     var hoursms=ms % (60*60*1000);
     var minutes = Math.floor((hoursms)/(60*1000));
     var  minutesms=ms % (60*1000);
     var sec = Math.floor((minutesms)/(1000));
      // return days+":"+hours+":"+minutes+":"+sec;

      // hours = (hours >=0 || hours <=9) ? "0"+hours :hours
      // minutes = (minutes >=0 || minutes <=9) ? "0"+minutes :minutes
      // sec = (sec >=0 || sec <=9) ? "0"+sec :sec

      hours = (parseInt(hours) >=0 && parseInt(hours) <=9) ? "0"+hours :hours
      minutes = (parseInt(minutes) >=0 && parseInt(minutes) <=9) ? "0"+minutes :minutes
      sec = (parseInt(sec) >=0 && parseInt(sec) <=9) ? "0"+sec :sec

      // hours = (hours.length == 0) ? "0"+hours :hours
      // minutes = (minutes.length == 0) ? "0"+minutes :minutes
      // sec = (sec.length == 0) ? "0"+sec :sec

      return hours+":"+minutes+":"+sec;
  }

  onPressCounsellorPatient = (patientId, appointmentId) => {

    this.setState({
      isCounsellorPatientSelected: true
    })

    var serviceUrl = Constants.COUNSELLOR_PATIENT_TRANSMIT_DETAIL + 
      "?patient_id=" + patientId + "&clinic_id=" + this.state.selectedClinicOrDoctor.id + "&appointment_id=" + appointmentId ;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.onPressCounsellorPatientSuccess,
      this.onPressCounsellorPatientFailure
    );
  }

  onPressCounsellorPatientSuccess = (response) => {
    if(response.status === "success"){
      var SurgeyTime = (response.data.surgery_time).split(":")
      var surgeryHour = parseInt(SurgeyTime[0]) > 12 ? parseInt(SurgeyTime[0]) - 12 : parseInt(SurgeyTime[0])
      surgeryHour = (surgeryHour).toString()
      this.setState({
          patientTransmittedCounsellingData: response.data,
          counsellingTransmitId: response.data.id,
          transmitDoctorId: response.data.doctor_id,
          selectedEyeType: { value: response.data.eye_type === "Right Eye (OD)"? 1 :response.data.eye_type === "Left Eye (OS)"? 2 :response.data.eye_type === "Both Eye (OU)"? 3 : "" , label: response.data.eye_type },
          selectedSurgeryId: response.data.surgery_id,
          selectedPackageId: response.data.package_id,
          surgeonName: response.data.surgeon_name,
          anesthetist: response.data.anaesthetist,
          // counsellor: response.data.counsellor,
          notes: response.data.notes,
          surgeryName: response.data.surgery_name,
          packageName: response.data.package_name,
          packageAmount: response.data.amount,
          packageDiscountAmt: response.data.discount,
          packageDiscountPercentage: response.data.discount_percentage ? response.data.discount_percentage : 0,
          selectedGST: response.data.gst_percentage ? response.data.gst_percentage : 0,
          selectedGSTtype: response.data.gst_type ? response.data.gst_type : "",
          packageBillAmount: response.data.net_amount ? response.data.net_amount : response.data.amount,
          approvalStatus: response.data.status,
          transmittedCounsellorId: response.data.counsellor_id,
          surgeryDate: response.data.surgery_date,
          lensPower: response.data.iol_power ? response.data.iol_power : "",
          hour12Formet: SurgeyTime[0] ? surgeryHour : 12,
          minutes: SurgeyTime[1] ? SurgeyTime[1] : '00',
          findAmPm: SurgeyTime[0] ? parseInt(SurgeyTime[0]) > 11 ? 'pm' : 'am' : 'am',
          showTimer: false,

      },() => {
        this.counsellingPackageListGet()
      })
    }
  }

  counsellingPackageListGet = () => {
    var serviceUrl = Constants.OPT_COUNSELLING_SURGERY_PACKAGE_GET + 
      "?clinic_id=" + this.state.selectedClinicOrDoctor.id + "&surgery_id=" + this.state.selectedSurgeryId + "&doctor_id=" + this.state.transmitDoctorId;

    OpthamologyService.getInstance().getComplaints(
      serviceUrl,
      this,
      this.counsellingPackageListGetSuccess,
      this.counsellingPackageListGetFailure
    );
  }

  counsellingPackageListGetSuccess = (response) => {
    if(this.state.selectedPackageId && (response.data.package_search).length > 0){
      var packageData = response.data.package_search
      for(let i = 0; i < (packageData).length; i++){
        if (packageData[i].id == this.state.selectedPackageId){
          this.setState({
            searchPackage: packageData[i].package_name,
            selectedPackageAmount: packageData[i].package_amount,
          })
        }
      }
    }
    this.setState({
      totalPackageData: response.data.package_search,
      suggestionPackageData: response.data.package_search
    })
  }

    renderAppointments(item, index) {
    // console.log("item.appointment_status ------ "+JSON.stringify(item));
    let currentDateTime = new Date()
    let currentDateTimeFormatted = moment(currentDateTime); //.tz('UTC'); //.format("DD-MM-YYYY HH:mm:ss")

    let arrivedDateTimeFormatted = "";
    if (item.patient_arrived_time) {

      let t = moment(item.patient_arrived_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
      arrivedDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
    }
    let arrivedTimeFormate=""
    if(item.patient_arrived_time){
      let time=moment(item.patient_arrived_time);
      arrivedTimeFormate=moment(time).format("LT")  //change 24 hr time format to 12 hr format
    }


    let withDateTimeFormatted = "";
    if (item.patient_with_doctor_time) {

      let t = moment(item.patient_with_doctor_time);  //.tz('UTC') //tz("Asia/Kolkata")
      withDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
    }

    let timer = item.patient_arrived_time ?
      item.patient_with_doctor_time ?
        this.getHHMMSS(
          moment(moment(withDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
        )
        :
        this.getHHMMSS(
          moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
        )
      : "-"



      var dialationTime  = ""
      

      let getDialationTime = "";
      if (item.dilation_start_time) {
  
        let t = moment(item.dilation_start_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
        getDialationTime = moment(t).format("DD-MM-YYYY HH:mm:ss")
      }

      if(getDialationTime){

       dialationTime =  this.getHHMMSS(
          moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(getDialationTime, "DD-MM-YYYY HH:mm:ss"))
        )
      }
      
    let { userType } = this.state;
    return (
      <View>
      {(Constants.ROLE_COUNSELLOR == this.state.userType) ? 
          <View
            style={[
              {  
                width: "97%",
                flexDirection: "row",
                alignItems: "center",
                paddingVertical: "0.65vw",
                alignSelf: 'center',
                borderRadius: 4
              }]}>
              <TouchableOpacity
              style={{ paddingLeft: 20, flexDirection: "row", width: "20%", alignItems: "flex-start" }} 
              onPress={() => { 
                this.setState({
                  selected_patient_id : item.patient_id,
                  selected_appointment_id : item.appointment_id
                }),
                this.onPressCounsellorPatient(item.patient_id, item.appointment_id)
                this.getDoctor()
                }}>
                <Image
                  source={item.patient_photo_url
                    ? { uri: item.patient_photo_url }
                    : ImagePath.DefaultProfile
                  }
                  style={[Style.appointmentTableRowImg,{borderWidth:2}]}
                />
                <View style={{ marginLeft: 5 }}>
                  <View style={{ flexDirection: "row", alignItems: "center"}}>
                  <Tooltip placement="topLeft" title={item.patient_name}>               
                    <Text style={{fontSize: "0.9vw", fontWeight: "500"}}>
                      {item.patient_name.length > 12 ? item.patient_name.slice(0,12) + ".." : item.patient_name}
                    </Text>
                  </Tooltip> 
                    <Text
                      style={{
                        fontSize: ".75vw",
                        fontWeight: "500",
                          marginLeft: 5
                    }}>
                        ({item.patient_age ? (item.patient_age) : null})
                    </Text>

                  </View> 
                  <Text
                    style={{
                      fontSize: ".75vw",
                      marginTop: 5,
                      marginBottom: 5
                    }}
                  >
                    {item.patient_account_number}
                  </Text>
                </View>

              </TouchableOpacity>
              <TouchableOpacity style={{ paddingLeft: 20, width: "17%", alignItems: "flex-start" }}
                onPress={() => {
                  this.setState({
                    selected_patient_id : item.patient_id,
                    selected_appointment_id : item.appointment_id
                  }),
                  this.onPressCounsellorPatient(item.patient_id, item.appointment_id)
                  this.getDoctor()
                  }}>
                {/* <View > */}

                  <Text style={{
                    fontSize: ".8vw",
                    // justifyContent:"center",
        // color: color.appointmentTableHeadText,
        // alignSelf: "center",
        textAlign: "center"
                  }} >
                    {item.patient_gender === "m"? "Male" :item.patient_gender === "f"? "Female" : "Transgender"}
                  </Text>

                {/* </View> */}
              </TouchableOpacity>
              <TouchableOpacity style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }}
                onPress={() => { 
                  this.setState({
                    selected_patient_id : item.patient_id,
                    selected_appointment_id : item.appointment_id
                  }),
                  this.onPressCounsellorPatient(item.patient_id, item.appointment_id)
                  this.getDoctor()
                  }}>
                <View >

                  <Text style={{
                    fontSize: ".8vw",
                    // justifyContent:"center"
                  }} >
                    {item.patient_mobile_number}
                  </Text>

                </View>
              </TouchableOpacity>
              <TouchableOpacity style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }}
                onPress={() => { 
                  this.setState({
                    selected_patient_id : item.patient_id,
                    selected_appointment_id : item.appointment_id
                  }),
                  this.onPressCounsellorPatient(item.patient_id, item.appointment_id)
                  this.getDoctor()
                  }}>
                <View >

                  <Text style={{
                    fontSize: ".8vw",
                    // justifyContent:"center"
                  }} >
                    {item.doctor_name}
                  </Text>

                </View>
              </TouchableOpacity>
              <TouchableOpacity style={{ paddingLeft: 20, width: "20%", alignItems: "flex-start" }}
                onPress={() => { 
                  this.setState({
                    selected_patient_id : item.patient_id,
                    selected_appointment_id : item.appointment_id
                  }),
                  this.onPressCounsellorPatient(item.patient_id, item.appointment_id)
                  this.getDoctor()
                  }}>
                <View >

                  <Text style={{
                    fontSize: ".8vw",
                    // justifyContent:"center"
                  }} >
                    {item.surgery_name}
                  </Text>

                </View>
              </TouchableOpacity>

            </View>
       :
        <Tooltip key={index} style={{zIndex:-10}} placement="top" title={item.appointment_status === "Cancelled" 
? (item.cancel_reason ? "Patient appointment is cancelled because of "+ (item.cancel_reason)
: "Patient appointment is cancelled"): null}>

      <View style={{
        // alignItems:'center',
        alignSelf:'center',
        zIndex:-10,
        borderBottomColor: "#888888", borderBottomWidth: ".1vw", paddingLeft: ".75vw", paddingRight: ".75vw",width: "91vw"}}>

      <View

        style={{
          // alignSelf:'center',
          borderRadius:10,
          backgroundColor:item.appointment_status === "Cancelled" ? "#F2F2F8" : null,
          // width: "97%",
          paddingTop: "0.6vw",
          zIndex:-10,
          paddingBottom: "0.6vw",
          flexDirection: "row",
          // borderBottomColor: "#888888", borderBottomWidth: 1, marginLeft: 15, marginRight: 15
        }}
      // onPress={() => {

      //   var fields = this.state;
      //   let appointmentData = fields["appointmentData"];

      //   fields["selectedIndex"] = index;
      //   fields["selectedItem"] = this.state.appointmentData[index];
      //   fields["appointmentData"] = JSON.parse(JSON.stringify(appointmentData));
      //   this.setState({ fields},()=>{
      //     this.props.navigation.navigate("optDoctorNotes", {
      //       patientAppointment: this.state.selectedItem,
      //       userType: this.state.userType
      //     })
      //   });


      //   // alert(JSON.stringify(item))

      //   this.setState({ patientId: item.patient_id })

      //   this.getAppointmentComments(item.appointment_id);


      // }}
      >
        {/* <View
          style={[{flexDirection: "row", justifyContent: "space-evenly", alignItems: "center",  
          
          // backgroundColor: this.state.selectedIndex === index ? color.themeBackgroud : color.white
          },
          //  Style.appointmentTableRow
           ]} > */}
      
        <TouchableOpacity
          disabled={item.appointment_status === "Cancelled" ? true : false}
          style={{ width: "14vw", flexDirection: "row", paddingLeft: ".5vw", alignItems: 'center' }} onPress={() => { this.onclickAppointment(item, index) }}>

          <Image
            source={item.patient_photo_url
              ? { uri: item.patient_photo_url }
              : ImagePath.DefaultProfile
            }
            style={[Style.appointmentTableRowImg,{borderWidth:2,borderColor:item.patient_type=="new_patient"?color.red:item.patient_type=="review_patient"?color.lightBlue:item.patient_type=="old_patient"?color.themeDark:color.white}]}
          />
          <View style={{ marginLeft: ".2vw" }}>
            {/*
            <Text style={{
              fontSize: 14, fontWeight: "500",
            }} >
              {item.patient_name}({item.patient_age?(item.patient_age.slice(0,item.patient_age.length - 10)) : null})
            </Text>
            */}

            <View style={{ flexDirection: "row", alignItems: "center"}}>
            <Tooltip placement="topLeft" title={item.patient_name}>               
              <Text style={{fontSize: "0.9vw", fontWeight: "500"}}>
                {item.patient_name.length > 12 ? item.patient_name.slice(0,12) + ".." : item.patient_name}
              </Text>
            </Tooltip> 
            {this.props.userType === Constants.ROLE_OPTOMETRY ? null : 
               <Text
                 style={{
                   fontSize: ".8vw",
                   fontWeight: "500",
                    marginLeft: ".2vw"
               }}>
                  ({item.patient_age ? item.patient_age : null} {item.patient_gender  ?  "/ " + item.patient_gender[0] : null })
               </Text>
             }

            </View> 
            <Text
              style={{
                fontSize: ".8vw",
                // color:
                //   this.state.selectedIndex === index
                //     ? color.white
                //     : 
                //     "#888888",
                // color.lightGray,
                marginTop: ".2vw",
                marginBottom: ".2vw"
              }}
            >
              {item.patient_account_number}
              <span style={{marginLeft:"0.2vw", fontWeight: 500, fontSize: "0.9vw"}}>{item.is_transfered ? "(Transfered)" : ""}</span>
            </Text>
            {this.state.selectedClinicOrDoctor.value === "All" ?
               <Tooltip placement="topLeft" title={item.doctor_name==" "?item.nurse_name:item.doctor_name}>
                 <Text style={[styles.toolTipStyle]}>
                   {
                    item.doctor_name==" "? <Text style={[styles.toolTipStyle]}>
                      {item.nurse_name}
                    </Text>: <Text style={[styles.toolTipStyle]}>{"Dr. "}{item.doctor_name.length > 20 ? item.doctor_name.slice(0, 20) + ".." : item.doctor_name}</Text>
                   }
                   
                 </Text>
               </Tooltip> : null
            }
           
          </View>

        </TouchableOpacity>
        {/* <TouchableOpacity style={{ width: '6%', alignItems: "center", justifyContent: "center" }}
          // disabled={item.appointment_status === "Cancelled" ? true : false}
          onPress={() => { this.onclickAppointment(item, index) }}>
          <View >

            <Text style={{
              fontSize: 12,
              justifyContent:"center"
              // color: this.state.selectedIndex === index ? color.white : color.themeBlue
            }} >
              {item.patient_age}
            </Text>

          </View>
        </TouchableOpacity> */}
        <TouchableOpacity style={{ width: '8vw', alignItems: "center", justifyContent: "center" }}
          disabled={item.appointment_status === "Cancelled" ? true : false}
          onPress={() => { this.onclickAppointment(item, index) }}>
          <View >

            <Text style={{
              fontSize: ".95vw",
              justifyContent:"center"
              // color: this.state.selectedIndex === index ? color.white : color.themeBlue
            }} >
              {item.patient_mobile_number}
            </Text>

          </View>
        </TouchableOpacity>

        <TouchableOpacity style={{ width: '4.5vw', alignItems: "center", justifyContent: "center" }}
          disabled={item.appointment_status === "Cancelled" ? true : false}
          onPress={() => { this.onclickAppointment(item, index) }}>
          <View>

            <Text style={{
              fontSize: ".85vw",
              justifyContent: "center",
              // color: this.state.selectedIndex === index ? color.white : color.themeBlue 
            }} >
              {item.appointment_start_time}
            </Text>

          </View>
        </TouchableOpacity>
      {/* <TouchableOpacity style={{width:"6%",alignItems:"center",justifyContent:"center"}}
          onPress={() => { this.onclickAppointment(item, index) }}
      >
        <Text style={{fontSize:12,justifyContent:"center"}}>{arrivedTimeFormate}</Text>
      </TouchableOpacity> */}
      
      <TouchableOpacity style={{ width: "8.2vw", alignItems: "center", justifyContent: "center" }}
          disabled={item.appointment_status === "Cancelled" ? true : false}
          onPress={() => { this.onclickAppointment(item, index) }}>
            <Text style={{fontSize:".8vw",justifyContent:"center"}}>{item.appointment_status === "Cancelled" ? null : arrivedTimeFormate}</Text>

          <View>

            {item.appointment_status === "Cancelled" ?
            <Text style={[{ fontSize: ".8vw", justifyContent: "center" } ]}>-</Text> :
              Constants.ROLE_OPT == this.state.userType ? 
            
            <Text style={[{
              fontSize: ".8vw",
              justifyContent: "center",
              // color: this.state.selectedIndex === index ? color.white : "#293948"
            },
            moment("01:30:00" ,"HH:mm:ss")  < moment(timer ,"HH:mm:ss") ? {color:"red",fontWeight:"500",
            textShadowColor: 'red', textShadowOffset: { width: 0.5, height: 0.5 }, textShadowRadius: 1,
          }:{}
            ]} >
              {timer}
            </Text>
    
            :
            <Text style={[{ fontSize: ".8vw", justifyContent: "center", } ]} >
              {timer}
            </Text>
            }

          </View>
        </TouchableOpacity>

      
       
        <TouchableOpacity style={{ width: "8vw", alignItems: "center", justifyContent: "center" }}
          disabled={item.appointment_status === "Cancelled" ? true : false}
          onPress={() => { this.onclickAppointment(item, index) }}>
          <View>

            <Text style={{
              fontSize: ".85vw",
              justifyContent: "center",
              // color: this.state.selectedIndex === index ? color.white : color.themeBlue 
            }} >
              {/* {
                (this.state.isFoAdmin == "true" || this.state.isQMSAccessAvailableUser) ?
               item.qms_token_digit :item.token_number} */}
               {item.token_number}
            </Text>

          </View>
        </TouchableOpacity>

        <TouchableOpacity style={{  overflow: 'hidden',width:  '9vw', alignItems: "center", justifyContent: "center" }}
          disabled={item.appointment_status === "Cancelled" ? true : false}
          onPress={() => { this.onclickAppointment(item, index) }}>
          <View>

            <Tooltip placement="topLeft" title={item.purpose_of_visit}>               
            <Text style={{
              fontSize: ".85vw",
              // color: this.state.selectedIndex === index ? color.white : color.themeBlue  
            }}
            numberOfLines={2}
            ellipsizeMode="tail"
            >
              {item.purpose_of_visit}
            </Text>
            </Tooltip> 

          </View>
        </TouchableOpacity>

        <TouchableOpacity style={{width:'5vw' ,alignItems:"center",justifyContent:"center"}}
          onPress={() => { this.onclickAppointment(item, index) }}
      >
        {/* {
          this.state.userType == Constants.ROLE_OPT || this.state.clinicType ? */}
        <Text style={{fontSize:".85vw",justifyContent:"center"}}>{item.patient_tag_name}</Text>
    {/* } */}
      </TouchableOpacity>
        <TouchableOpacity style={{width:'5vw' ,alignItems:"center",justifyContent:"center"}}
          onPress={() => { this.onclickAppointment(item, index) }}
      >
        {/* {
          this.state.userType == Constants.ROLE_OPT || this.state.clinicType ? */}
        <Text style={{fontSize:".85vw",justifyContent:"center"}}>{item.pending_amount}</Text>
    {/* } */}
      </TouchableOpacity>

        

        {/* <View
            style={{
              width: 20,
              alignItems: "center"
            }}
          >
            {this.state.selectedIndex === index ? (
              <Image
                source={require("../../../../assets/images/appointment_arrow_icon.png")}
              />
            ) : null}
          </View> */}

        <View style={[Style.homeScreenStatusView,{backgroundColor:'transprant',width:"17vw", marginTop:'.7vw' }]}>
          <View>

            <View style={{ flexDirection: 'row',justifyContent:"center",alignItems:'center' }}>           

              <TouchableOpacity
                disabled={item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  // if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                  //   this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  // }else{
                    this.changeState("checkIn", index, "Arrived", item) 
                  // }
                  }
                  
                } 
                style={[
                  styles.statusIconStyles,(item.appointment_status == "Arrived" && item.qmsTokenStatus !== "process") ? {backgroundColor:color.themeDark}:null
                  ]}>
                <Tooltip placement="top" title={ item.appointment_status === "Cancelled" ? null :"Arrived"}>
                  {
                     (item.appointment_status == "Arrived" && item.qmsTokenStatus !== "process") ?
                      <Image 
                      source={ImagePath.Arrived}
                       style={[styles.statusIconHeight ]} /> :
                      <Image 
                      source={ImagePath.Arrived}
                       style={[styles.statusIconHeight ,{tintColor:color.black}]} />
                  }
                </Tooltip>
              </TouchableOpacity>
              {
                item.appointment_status !== "Booked"?             

              <TouchableOpacity
                disabled={item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                    this.changeState("Waiting_Comments", index, "Waiting", item) 
                  }}               
                 
                style={[
                  styles.statusIconStyles,(item.appointment_status == "Waiting") ? {backgroundColor:color.themeDark}:null
                  ]}>
                <Tooltip placement="top" title={ item?.appointment_status === "Waiting" ? typeof(item?.waiting_comment) === "string" ? item?.waiting_comment : item?.waiting_comment[0]?.comments  :"Waiting Comments" }
                 ><div>
                  {
                     (item?.appointment_status == "Waiting" && item?.qmsTokenStatus !== "process") ?
                      <Image 
                      source={ImagePath.Message}
                       style={[styles.statusIconHeight ]} /> :
                      <Image 
                      source={ImagePath.Message}
                       style={[styles.statusIconHeight ,{tintColor:color.black}]} />
                  }
                </div></Tooltip>
              </TouchableOpacity> : null}


                  {
                    (item.qms_token &&  this.state.isFoAdmin == "true" || (userType !== Constants.ROLE_FO && item.qms_token))  ?
                    <TouchableOpacity
                        disabled={
                          // !this.state.isAvailableIndicatuionFlag || 
                          item.appointment_status === "Cancelled" || item.appointment_status == "Completed"  ? true : false}
                        onPress={() => { 

                          // this.speak(item.qms_token_digit)

                          if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                            this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                          }else{

                            this.validateCallOptionForCall(item,"process")

                            // this.changeTokenStatusInQMS(item,"process")
                          }
                          

                         }} style={[
                           styles.statusIconStyles,(item.appointment_status !== "With Doctor" &&  item.qmsTokenStatus == "process"  && item.appointment_status !== "Cancelled"
                            && item.appointment_status !== "Optometry" && item.appointment_status !== "Scan/Surgery" 
                           ) ? {backgroundColor:color.themeDark}:null
                           ]}>
                        <Tooltip placement="top" title={ item.appointment_status === "Cancelled" ? null :"Call"}>
                            {
                              (item.appointment_status !== "With Doctor" && item.qmsTokenStatus == "process" && item.appointment_status !== "Cancelled"
                              && item.appointment_status !== "Optometry" && item.appointment_status !== "Scan/Surgery" ) ?
                              <Image 
                              source={ImagePath.Speaker}
                              style={[styles.statusIconHeight]} /> 
                              :
                              <Image
                              source={ImagePath.Speaker}
                               style={[styles.statusIconHeight,{tintColor:color.black}]} /> 

                            }
                              {/* <Image source={require("../../../../assets/images/statusIcon/NewStatusIcons/LED_Sound.png")} style={[styles.statusIconHeight,{tintColor:color.black}]} />  */}
                        </Tooltip>
                      </TouchableOpacity>
                      : null
                  }





            {userType == Constants.ROLE_OPT || userType == Constants.ROLE_OPTOMETRY || ( userType == Constants.ROLE_FO && ( this.state.selectedClinicOrDoctor.serviceType == "Ophtalmology" ||  this.state.selectedClinicOrDoctor.serviceType == "OPTHOMOLOGY" || this.state.selectedClinicOrDoctor.serviceType == "OPT" || this.state.selectedClinicOrDoctor.serviceType == "OPHTHALMOLOGY" ) || (this.state.clinicType && ((this.state.selectedClinicOrDoctor && this.state.selectedClinicOrDoctor.value && (this.state.selectedClinicOrDoctor.value).trim() == "Nursing") || this.state.selectedClinicOrDoctor.value == "All")))?
              <TouchableOpacity
                disabled={ 
                  // (this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag) || 
                  item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                    this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  }else{
                    this.changeState("optimetry", index, "Optometry", item) 
                  }
                  // this.changeState("optimetry", index, "Optometry", item) 
                }
                }
                style={[
                  styles.statusIconStyles,item.appointment_status == "Optometry" ? {backgroundColor:color.themeDark}:null
                  ]}
                
                // style={[item.appointment_status == "Optometry" ? Style.homeScreenSelectedStates : null, ]}
                >
                <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? "Patient appointment is cancelled":"Optometry"}>                                

                  {
                    item.appointment_status == "Optometry" ?
                      <Image
                      source={ImagePath.Optometry}
                       style={[styles.statusIconHeight ]}/> :
                      <Image 
                      source={ImagePath.Optometry}
                       style={[styles.statusIconHeight ,{tintColor:color.black}]} />

                  }
                </Tooltip>
              </TouchableOpacity>:null}

              <TouchableOpacity
                disabled={ 
                  // (this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag) || 
                  item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  
                  if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                    this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  }else{
                    this.changeState("withNurse", index, "With Nurse", item)

                    // if((item.qms_token &&  this.state.isFoAdmin == "true" || (userType !== Constants.ROLE_FO && item.qms_token)) && item.appointment_status !== "Cancelled"){
                    //   this.changeTokenStatusInQMS(item,"process")
                    // }
                  }

                  // this.changeState("withDoctor", index, "With Doctor", item)
                 }} 
                style={[  styles.statusIconStyles,item.appointment_status == "With Nurse" ? {backgroundColor:color.themeDark}:null ]}
                // style={[item.appointment_status == "With Doctor" ? Style.homeScreenSelectedStates : null, ]}
                >
                <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null :"With Nurse"}>
                  {
                    item.appointment_status == "With Nurse" ?
                      <Image 
                      source={ImagePath.WithNurse}
                       style={[styles.statusIconHeight,{tintColor:color.white}]}/> :
                      <Image  
                      source={ImagePath.WithNurse}
                       style={[styles.statusIconHeight ,{tintColor:color.black}]} />
                  }
                </Tooltip>
              </TouchableOpacity>

              <TouchableOpacity
                disabled={ 
                  // (this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag) || 
                  item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  
                  if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                    this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  }else{
                    this.changeState("withDoctor", index, "With Doctor", item)

                    // if((item.qms_token &&  this.state.isFoAdmin == "true" || (userType !== Constants.ROLE_FO && item.qms_token)) && item.appointment_status !== "Cancelled"){
                    //   this.changeTokenStatusInQMS(item,"process")
                    // }
                  }

                  // this.changeState("withDoctor", index, "With Doctor", item)
                 }} 
                style={[  styles.statusIconStyles,item.appointment_status == "With Doctor" ? {backgroundColor:color.themeDark}:null ]}
                // style={[item.appointment_status == "With Doctor" ? Style.homeScreenSelectedStates : null, ]}
                >
                <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null :"With Doctor"}>
                  {
                    item.appointment_status == "With Doctor" ?
                      <Image 
                      source={ImagePath.WithDoctor}
                       style={[styles.statusIconHeight ]}/> :
                      <Image  
                      source={ImagePath.WithDoctor}
                       style={[styles.statusIconHeight ,{tintColor:color.black}]} />
                  }
                </Tooltip>
              </TouchableOpacity>
              {userType == Constants.ROLE_OPT || userType == Constants.ROLE_OPTOMETRY || ( userType == Constants.ROLE_FO && (this.state.selectedClinicOrDoctor.serviceType == "Ophtalmology" ||  this.state.selectedClinicOrDoctor.serviceType == "OPTHOMOLOGY" || this.state.selectedClinicOrDoctor.serviceType == "OPT" || this.state.selectedClinicOrDoctor.serviceType == "OPHTHALMOLOGY" || (this.state.clinicType && ((this.state.selectedClinicOrDoctor) && this.state.selectedClinicOrDoctor.value && (this.state.selectedClinicOrDoctor.value).trim() == "Nursing" || this.state.selectedClinicOrDoctor.value == "All")))) ?
              <TouchableOpacity
                disabled={ 
                  // (this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag) || 
                  item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                    this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  }else{
                    this.changeState("scanAndSurgery", index, "Scan/Surgery", item) 
                  }
                  // this.changeState("scanAndSurgery", index, "Scan/Surgery", item) 
                }} 
                // style={[item.appointment_status == "Scan/Surgery" ? Style.homeScreenSelectedStates : null,]}
                style={[styles.statusIconStyles,item.appointment_status == "Scan/Surgery" ? {backgroundColor:color.themeDark}:null ]}

                >
                <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Scan/Surgery"}>

                  {
                    item.appointment_status == "Scan/Surgery" ?
                      <Image 
                      source={ImagePath.ScanOrSurgery}
                      style={[styles.statusIconHeight ]}/> :
                      <Image
                      source={ImagePath.ScanOrSurgery}
                      style={[styles.statusIconHeight ,{tintColor:color.black}]} />
                  }</Tooltip>
              </TouchableOpacity>:null}
              <TouchableOpacity
                disabled={ 
                  // (this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag) || 
                  item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                    this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  }else{
                    this.changeState("checkedOut", index, "Completed", item) 
                  }
                  // this.changeState("checkedOut", index, "Completed", item) 
                }} 
                style={[styles.statusIconStyles,item.appointment_status == "Completed" ? {backgroundColor:color.themeDark}:null ]}
                // style={[item.appointment_status == "Completed" ? Style.homeScreenSelectedStates : null, ]}
                >
                <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Completed"}>
                  {
                    item.appointment_status == "Completed" ?
                      <Image
                      source={ImagePath.Completed}
                        style={[styles.statusIconHeight ]}/> :
                      <Image
                      source={ImagePath.Completed}
                        style={[styles.statusIconHeight ,{tintColor:color.black}]} />
                  }</Tooltip>
              </TouchableOpacity>
            { this.state.roleType === "Doctor" && this.state.selectedAppointmentStatus === "With Doctor" ? 
             <TouchableOpacity style= {{ marginLeft: "0.5vw" }}
                onPress = { () => {
                  this.setState({
                    isPatientTransfer: true,
                    transferPatient: item,
                    typedIndex: index
                  })
                }}
             > 
                 <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Transfer"}>
                      <Image
                      source={ImagePath.transferApp}
                        style={[styles.statusIconHeight, { tintColor: color.themeDark} ]}/> 
                 </Tooltip>
              </TouchableOpacity> : null
            } 
            </View>


            {
              item.appointment_status == "Optometry" && (userType == Constants.ROLE_OPT || userType == Constants.ROLE_OPTOMETRY ||  ( userType == Constants.ROLE_FO && (this.state.selectedClinicOrDoctor.serviceType == "Ophtalmology" ||  this.state.selectedClinicOrDoctor.serviceType == "OPTHOMOLOGY" || this.state.selectedClinicOrDoctor.serviceType == "OPT"))) ?
                <View style={{ flexDirection: 'row', justifyContent: "center", alignContent: "center", marginTop: 15 }}>
                  <View >
                    <Picker
                      selectedValue={item.dilation}
                      style={{ borderRadius: "10vw", height: "2vw", width: "10vw", fontSize: ".85vw", backgroundColor: "white", color: 'black' }}
                      itemStyle={{ fontSize: ".75vw" }}
                      onValueChange={(itemvalue) => {
                        this.setState({
                          dilation: itemvalue
                        })
                      }}
                    >
                      <Picker.Item label="Tropicamide Plus" value="Tropicamide Plus" />
                      <Picker.Item label="Homide" value="Homide" />
                      <Picker.Item label="Cylopentolate" value="Cylopentolate" />
                    </Picker>
                  </View>
                  <View>


                    

                    <TouchableOpacity
                      disabled={item.appointment_status === "Cancelled" ? true : false}
                      style={[{ 


                       
                        backgroundColor: color.themeDark, borderRadius: "10vw", padding: ".5vw", marginLeft: 10, paddingLeft: "1.3vw", paddingRight: "1.3vw" },
                      
                        // parseFloat("00:20:00") < parseFloat(dialationTime) 
                        
                        moment("00:20:00" ,"HH:mm:ss")  < moment(dialationTime ,"HH:mm:ss") 
                        
                        
                        ? {
                          shadowOffset: { width: "0vw", height: ".3vw" },
                          shadowOpacity: 0.6, shadowRadius: 3,
                          elevation: 5,
                        borderColor:"red",borderWidth:".1vw",
                        } :{}

                      ]}
                      onPress={() => {
                        if(dialationTime){

                        }else{
                          this.sendDilationData(item)
                        }
                        }}
                    >

                    {
                      dialationTime ? 
                      <Text style={{fontSize:".85vw",
                        // color: parseFloat("00:20:00") > parseFloat(dialationTime) ?  color.black : "red",
                        color: parseFloat("00:20:00") < parseFloat(dialationTime) ?  color.white : color.white,
                        fontWeight:'600'}}>{dialationTime}</Text>
                      : 
                      <Text style={{ color: 'white', fontSize: ".85vw" }}>Start</Text>
                      // null
                    }

                      
                    </TouchableOpacity>
                  </View>
                </View> : null
            }

          </View>



        </View>
       
        <View style={{ width: "12vw", backgroundColor: "transprant", paddingLeft: "1.2vw" ,zIndex:-10}}>
          <View style={{ flexDirection: 'row', display:"flex",justifyContent:"center"}}>
            {/* <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { this.changeState("printFlag", index,"",item) }} style={[Style.homeScreenSelectedStates]}>
              <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Print"}>
                <Image 
                source={ImagePath.PrintIcon}
                 style={[Style.appointmentTableStatusView, { height: "1vw", width: "1vw" }]} />
              </Tooltip>
            </TouchableOpacity> */}
            {/* <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { this.changeState("createBillFlag", index,"",item) }} style={[Style.homeScreenSelectedStates, {backgroundColor: item.bill_paid && item.is_payment_complete ? color.lime : item.is_payment_complete ? color.themeDark : color.red }]}>
              <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Create Bill"}>
                <Image
                source={ImagePath.CreateBill} 
                style={[Style.appointmentTableStatusView, { height: "1vw", width: "1vw" }]} />
              </Tooltip>
            </TouchableOpacity> */}
            {/* <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { this.changeState("messageFlag", index) }} style={[Style.homeScreenSelectedStates, { position: 'absolute', marginLeft: 55, zIndex: this.state.messageFlag && (this.state.selectedStatusIndex == index + 1) ? 1 : -1 }]}>
              <Tooltip placement="top" title={"Message"}>
                <Image source={require("../../../../assets/images/statusIcon/icon/message.svg")} style={[Style.appointmentTableStatusView, { height: 17, width: 17, }]} />
              </Tooltip>
            </TouchableOpacity> */}
            <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { this.changeState("nextAppointmentFlag", index, "", item) }} style={[Style.homeScreenSelectedStates, ]}>
              <Tooltip placement="top" title={<Text style={{ color: 'white', fontSize: ".85vw" }}>
                {item.appointment_status === "Cancelled" ? null :
                "Next Appointment"}</Text>}>
                <Image
                source={ImagePath.NextAppointment}
                style={[Style.appointmentTableStatusView, { height: "1vw", width: "1vw" }]} />
              </Tooltip>
            </TouchableOpacity>
            {item.appointment_status != "Completed" ?
            <TouchableOpacity
              onPress={() => {this.setState({isTransfer : true, TranferData : item})}} style={[Style.homeScreenSelectedStates, ]}>
              <Tooltip placement="top" title={<Text style={{ color: 'white', fontSize: ".85vw" }}>
                {"Transfer Appointment"}</Text>}>
                <Image
                source={ImagePath.TransferIcon}
                style={[Style.appointmentTableStatusView, { height: "1vw", width: "1vw", tintColor:"white" }]} />
              </Tooltip>
            </TouchableOpacity> : null}

            {
              
              (
                (this.state.userType == Constants.ROLE_FO && this.state.isFoAdmin == "true" && item.qms_token) || 
                (this.state.userType !== Constants.ROLE_FO && item.qms_token && this.state.isQMSAccessAvailableUser)
                ) ?
              
              <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { 
                this.props.changeQMSSelectedToken(item)
                this.getShiftCounterList(item)
                
               }} style={[Style.homeScreenSelectedStates, ]}>
              <Tooltip placement="top" title={<Text style={{ color: 'white', fontSize: ".85vw" }}>
                {item.appointment_status === "Cancelled" ? null :
                "Shift Counter"}</Text>}>
                <Image 
                source={ImagePath.ShiftCounter1}
                style={[Style.appointmentTableStatusView, { height: "1vw", width: "1vw" }]} />
              </Tooltip>
            </TouchableOpacity>
          : null  
          }
               



            <TouchableOpacity 
            disabled={
              item.appointment_status === "Cancelled" || 
              item.appointment_status === "With Doctor"||
              item.appointment_status === "Completed" ||
              item.appointment_status === "Scan/Surgery"              
              ? true :  false}
            onPress={() => { this.changeState("cancelFlag", index, "Cancelled", item) }} style={[Style.homeScreenSelectedStates, ]}>
              <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Cancel Appointment"}>
                <Image 
                source={ImagePath.Remove}
                style={[Style.appointmentTableStatusView, { height: "1vw", width: "1vw" }]} />
              </Tooltip>
            </TouchableOpacity>
          </View>
        </View>


        </View>
      </View>
        </Tooltip>
      }
      </View>
    );
  }
  changeState(state, index, type, item) {
    if (state == "checkIn") {
      // alert(JSON.stringify(item))
      this.setState({ checkIn: true, optimetry: false, withDoctor: false, scanAndSurgery: false, checkedOut: false, selectedStatusIndex: index + 1 })
      this.changeAppointmentStatus(type, item.appointment_id,item);
      
    }
    else if (state == "Waiting_Comments") {
      this.setState({WaitingComments:true, checkIn: false, optimetry: false, withNurse:false,withDoctor: false, scanAndSurgery: false, checkedOut: false, selectedStatusIndex: index + 1 })
      this.WaitingCommentsVisible(type, item.appointment_id)
    }
    else if (state == "optimetry") {
      this.setState({ checkIn: false, optimetry: true, scanAndSurgery: false, checkedOut: false, selectedStatusIndex: index + 1 })
      this.changeAppointmentStatus(type, item.appointment_id);
    } else if (state == "withNurse") {
      this.setState({ checkIn: false, optimetry: false,withNurse:true, withDoctor: false, scanAndSurgery: false, checkedOut: false, selectedStatusIndex: index + 1 })
      this.changeAppointmentStatus(type, item.appointment_id, item);
    } else if (state == "withDoctor") {
      this.setState({ checkIn: false, optimetry: false,withNurse:false, withDoctor: true, scanAndSurgery: false, checkedOut: false, selectedStatusIndex: index + 1 })
      this.changeAppointmentStatus(type, item.appointment_id, item);
    } else if (state == "scanAndSurgery") {
      this.setState({ checkIn: false, optimetry: false,withNurse:false, withDoctor: false, scanAndSurgery: true, checkedOut: false, selectedStatusIndex: index + 1 })
      this.changeAppointmentStatus(type, item.appointment_id);
    } else if (state == "checkedOut") {
      this.setState({ checkIn: false, optimetry: false,withNurse:false, withDoctor: false, scanAndSurgery: false, checkedOut: true, selectedStatusIndex: index + 1 })
      this.changeAppointmentStatus(type, item.appointment_id,item);
    } else if (state == "printFlag") {
      this.printAppointmentList(item);
      this.setState({ printFlag: true, createBillFlag: false, messageFlag: false, nextAppointmentFlag: false, cancelFlag: false })
    } else if (state == "createBillFlag") {
      this.setState({ printFlag: false, createBillFlag: true, messageFlag: false, nextAppointmentFlag: false, cancelFlag: false })
      this.onclickAppointment(item, index,"billingScreen")
    } else if (state == "messageFlag") {
      this.setState({ printFlag: false, createBillFlag: false, messageFlag: true, nextAppointmentFlag: false, cancelFlag: false })
    } else if (state == "nextAppointmentFlag") {
      this.setState({
        printFlag: false, createBillFlag: false, messageFlag: false, nextAppointmentFlag: true
      }, () => {
        this.setState({
          patientId: item.patient_id,

          // ShowCreateappointment: true,
          // ShowCreateAppointmentPatientId: item.patient_id,
        }, () => {
          this.setModalVisible("")
        })
      })
    } else if (state == "cancelFlag") {
      this.setState({ printFlag: false, createBillFlag: false, messageFlag: false, cancelFlag: true, nextAppointmentFlag: false },
         () => {
          this.CancelAppointmentVisible(type, item.appointment_id)
        })
    }
  }

  WaitingCommentsVisible = (type, item) =>{
    this.props.WaitingCommentsVisible(true, type, item)

    this.setState({
      WaitingCommentsVisible: true
    })
}
  
  printAppointmentList = ( item) => {

    if(item.token_number){
    let states = this.state;
    var serviceUrl ;
    if(Constants.ROLE_FO == this.state.userType )
     serviceUrl = Constants.APPOINTMENT_LIST_PRINT_API_FO + "?appointment_id=" + item.appointment_id;
    else
     serviceUrl = Constants.APPOINTMENT_LIST_PRINT_API_DR + "?appointment_id=" + item.appointment_id ;
    OpthamologyService.getInstance().documentUploadGet(
      serviceUrl,
      // this,
      this.printAppointmentListSuccess,
      this.printAppointmentListFailure,
      "pdf"
    );
    }
  };

  printAppointmentListSuccess = response => {
    if (response) {
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
    //  console.log(file)
      window.open(fileURL);
    }
  };

  printAppointmentListFailure = error => {
   // console.log("Get clinic list error response");
    console.log(error);
  };


  renderRightBtn = (name, outline) => {
    return (
      // <View style={{ alignItems: "center", marginBottom: 10 }}>
      //   <TouchableOpacity
      //   // onPress={() => this.saveHistory()}
      //   >
      <View
        style={{
          height: 35,
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          flexDirection: "row",
          borderColor: color.themeDark, //outline ? color.white : color.themeBlue,
          borderWidth: 1,
          backgroundColor: outline ? color.themeShadeBackground : color.themeDark,
          padding: 5,
          // paddingBottom: 0,
          justifyContent: "space-evenly",
          marginBottom: 10
        }}
      >
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
            color: outline ? color.themeDark : color.white,
            fontWeight: "700"
          }}
        >
          {name}
        </Text>
      </View>
      //   </TouchableOpacity>
      // </View>
    );
  };

  renderRightBtnAppointment = (name, outline) => {
    return (
      // <View style={{ alignItems: "center", marginBottom: 10 }}>
      //   <TouchableOpacity
      //   // onPress={() => this.saveHistory()}
      //   >
      <View
        style={{
          height: 35,
          width: "60%",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: 6,
          flexDirection: "row",
          borderColor: outline ? color.white : color.white,
          borderWidth: 1,
          backgroundColor: outline ? color.themeBlue : color.themeDark,
          marginLeft: 15,
          // paddingBottom: 0,
          justifyContent: "space-evenly",
          marginTop: 10
        }}
      >
        <Text
          style={{
            fontSize: 13,
            textAlign: "center",
            color: "#ffffff",
            fontWeight: "700"
          }}
        >
          {name}
        </Text>
      </View>
      //   </TouchableOpacity>
      // </View>
    );
  };

  commonFunc = () => {
   // console.log("Hello");
  }

  setModalVisible = status => {
    this.setState({
      modalOpenSrc: status
    })

    var service_url = ""
    if(this.state.userType == Constants.ROLE_FO){
      service_url = Constants.FO_PATIENT_INFO + this.state.patientId;
    }else{
      service_url = Constants.DOCTOR_PATIENT_INFO + this.state.patientId;
    }

    // var service_url = Constants.FO_PATIENT_INFO + this.state.patientId;
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getPatientInfoSuccess,
      this.getPatientInfoFailure
    );
  }

  getPatientInfoSuccess = response => {
    if (response.status === "success") {
      // console.log("response =================> " + JSON.stringify(response))

      let states = this.state;
      // states["modalVisible"] = status;
      states["modalVisible"] = true;

      this.props.modelClose(true,"",this.state.patientId,response,"", this.state.modalOpenSrc === "fromSeachBar" ? false : true)

      // this.setState({
      //   states,
      //   modalVisible:true
      // });

      this.setState({
        nextAppoinmentatPientDetails: response,
        states,
        modalVisible: true
      })
    }
  };

  getPatientInfoFailure = error => {
    //console.log("opthamology complaints GET error response");
    console.log(error);
  }

  CancelAppointmentVisible = (type, item) =>{
      this.props.CancelAppointmentVisible(true, type, item)

      this.setState({
        CancelAppointmentVisible: true
      })
  }
  // paginationChangedValues(data){
  //   this.setState({ appointmentData:data })
  // }

//   onPressEyeType = (key, selectedvalue) => {
//     if(this.state.selectedEyeType  && this.state.selectedEyeType.value && selectedvalue.value == this.state.selectedEyeType.value){
//         this.setState({
//           selectedEyeType:{}
//         })
//       }else{
//         this.setState({
//           selectedEyeType:selectedvalue
//         })
//       }
// }

renderIdButton = image => {
  return (
    <View
      style={{
        width: 27,
        height: 27,
        borderRadius: 20,
        shadowOpacity: 0.2,
        shadowRadius: 4,
        shadowOffset: {
          height: 2,
          width: 2
        },
        elevation: 4,
        backgroundColor: "#FCFEFF",
        alignItems: "center",
        justifyContent: "center",
        marginRight: 20
      }}
    >
      <Icon name={image} color={this.state.isPackageEdit? color.themeDark : color.newPencilColor} size={15} />
      {/* <Image
          source={image}
          style={{ width: 40, height: 40 }}
          resizeMode="contain"
        /> */}
    </View>
  );
};

searchDoctorSuggestion = (value) => {
  let { doctorSuggestionData } = this.state;
  let tempDescList = this.state.counsellorDoctorData
  if (tempDescList.length > 0) {
  var searchListTemp = [];
  for (let i = 0; i < tempDescList.length; i++) {

      if ((tempDescList[i].first_name.toLowerCase() + " " + tempDescList[i].initial.toLowerCase()).indexOf(value.toLowerCase()) > -1) {
      searchListTemp.push(tempDescList[i]);
      }
  }
  doctorSuggestionData = searchListTemp;
  } else {
      doctorSuggestionData = this.state.billingDescriptionList;
  }
  this.setState({
      doctorSuggestionData
  })
}

timePicker = () => {
  var getHours = []
  var getMinutes = [];
  for (var i = 1; i <= 12; i++) {
    var hour = i < 10 ? '0' + i : i;
    getHours.push(hour)
  }
  for (var j = 0; j <= 59; j++) {
    var minutes = j < 10 ? '0' + j : j;
    getMinutes.push(minutes)
  }

  return(
    <View style={[styles.preferTime,{ borderColor: color.themeDark, borderWidth: 1}]}>
      <View style={{ flexDirection: 'column' }}>
      <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
        <TouchableOpacity onPress={() => { this.setState({ showTimer: !this.state.showTimer}) }} style={{ justifyContent: 'center' }}>
          <Text style={{fontSize: '1vw'}}>{this.state.hour12Formet}{"  :  "}{this.state.minutes}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => { this.setState({ showTimer: !this.state.showTimer}) }} style={{ justifyContent: 'center', marginLeft: 5 }}>
          <Icon
            size={'1vw'}
            name={"angle-down"}
          />
        </TouchableOpacity>
      </View>
      {
        this.state.showTimer ?
          <TouchableWithoutFeedback onPress={() => { this.setState({ showTimer: true }) }}>
            <View style={{ marginTop: '2.4vw', flexDirection: 'row', backgroundColor: '#F2F2F2', position: 'absolute', zIndex: 1, borderRadius: 5 }}>
              <ScrollView style={{ height: '9.35vw' }} showsVerticalScrollIndicator={false}>
                <View style={styles.preferTimeHour}>
                  {
                    getHours.map((hour) => {
                      return <TouchableOpacity onPress={() => { this.changeTime(hour, 'hr') }} style={{ padding: 2, width: '2vw' }}><Text style={{ color: hour === this.state.hour12Formet ? null : '#d0cece', fontSize: '1vw' }}>{hour}</Text></TouchableOpacity>
                    })
                  }
                </View>
              </ScrollView>
              <ScrollView style={{ height: '9.35vw' }} showsVerticalScrollIndicator={false}>
                <View style={styles.preferTimeMin}>
                  {
                    getMinutes.map((min) => {
                      return <TouchableOpacity onPress={() => { this.changeTime(min, 'mm') }} style={{ padding: 2, width: '2vw' }}><Text style={{ color: min === this.state.minutes ? null : '#d0cece', fontSize: '1vw' }}>{min}</Text></TouchableOpacity>
                    })
                  }
                </View>
              </ScrollView>
            </View>
          </TouchableWithoutFeedback>
          :
          null
      }
      </View>
      <View style={{ justifyContent: 'center', flexDirection: 'row' }}>
        <TouchableOpacity onPress={() => { this.changeTime('am', 'ampm') }}>
          <Text style={{ color: this.state.findAmPm === "am" ? color.themeDark : null, marginRight: '1.2vw', marginLeft: '1.56vw', fontSize: '1vw' }}>{"am"}</Text>
        </TouchableOpacity>

        <TouchableOpacity onPress={() => { this.changeTime('pm', 'ampm') }}>
          <Text style={{ color: this.state.findAmPm === "pm" ? color.themeDark : null, fontSize: '1vw' }}>{"pm"}</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
  }

  changeTime(time, type) {
    if (type === 'hr') {
      this.state.hour12Formet = time;
      this.setState({
        hour12Formet: time
      })
    } else if (type === 'mm') {
      this.setState({
        showTimer: false,
        minutes: time
      })
      this.state.minutes = time;

    } else if (type === 'ampm') {
      this.state.findAmPm = time;
      this.setState({
        findAmPm: time
      })
    }
  }
  renderFOQmsHeader = () => {
    return(
      <View>
         <CommonHistorySectionHeader
                heading1={"Patient Details"}
                heading2={"Mobile Number"}
                heading3={"Time"}
                heading4={"ARR.Time / Waiting Time"}
                heading5={"Token"}
                heading6={"Patient's Tag"}
                heading7={"Speclization"}
                heading8={"Doctor's"}
                heading9={"Room No"}
                heading10={"Action"}
                noOfColumn={10}
                columnSize={[0.16, 0.1, 0.07, 0.13, 0.06, 0.08, 0.13, 0.1, 0.07, 0.1]}
              />
      </View>
    )
  }
   getBillTableTitle(title, flexWidth = 0.05, fontsize="0.7vw") {
    return (
        <View style={{ flex: flexWidth}}>
            <Text style={{ fontSize: fontsize }}>{title}</Text>
        </View>)
  }
  renderDoctorQMSHeader = () => {
    return(
     
      <CommonHistorySectionHeader
                heading1={"Patient Details"}
                heading2={"Mobile Number"}
                heading3={"Time"}
                heading4={"ARR.Time / Waiting Time"}
                heading5={"Token"}
                heading6={"Patient's Tag"}
                heading7={"Notes"}
                heading8={"Action"}
                heading9={""}
                noOfColumn={9}
                columnSize={[0.16, 0.12, 0.1, 0.15, 0.1, 0.13, 0.13, 0.1, 0.02]}
              />
      
    )
  }
  updateCallOption = (typedIndex) => {
    var url = Constants.DOCTOR_UPDATE_POST
    var { appointmentData } = this.state
    for( let i=0; i<appointmentData.length; i++ ) {
     if(typedIndex === i) { 
     if( this.state.userType === "FO") {
      var data = {
        "appointment_id": appointmentData[i].appointment_id,
        "doctor_id": parseInt(appointmentData[i].doctor_id)
      }
     } else {
      var data = {
        "appointment_status": "With Doctor",
        "appointment_id": appointmentData[i].appointment_id,
      }
     }
    }
     
    }
    OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.updateCallOptionSuccess,
      this.updateCallOptionFailure
    );
  }    
  
  updateCallOptionSuccess = (response) => {
    if(response.status == "success") {
      let { appointmentData, typedIndex } = this.state
      this.getAppointments(today);
      this.getAppointmentStat()
      this.setState({
        isConformation: false,
        appointmentData, 
      })
      this.props.showResposeValue("success", response.message)
    } else {
      appointmentData[typedIndex].doctor_id = null

      this.setState({
        isConformation: false,
        appointmentData
      })
      this.props.showResposeValue("error", response.message)
    }
  }
  updateCallOptionFailure = (error) =>{
    this.props.showResposeValue("error", error.message)

  }
  onClickCallOption = (index) => {
    var { appointmentData, selectedIndex } = this.state
      if(selectedIndex === index) {
       appointmentData[index].isCallOption = true
       this.setState({
         appointmentData
       }, () => {
         this.renderDoctorQMSTable()
         this.updateCallOption(index)
       })
      }
  }
  renderDoctorQMSTable = () => {
    return(
      <View>
       { this.state.appointmentData && this.state.appointmentData.length > 0 ? 
        this.state.appointmentData.map((item, index) => { 
          let currentDateTime = new Date()
          let currentDateTimeFormatted = moment(currentDateTime); 
          let arrivedDateTimeFormatted = "";
    if (item.patient_arrived_time) {

      let t = moment(item.patient_arrived_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
      arrivedDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
    }
    let arrivedTimeFormate=""
    if(item.patient_arrived_time){
      let time=moment(item.patient_arrived_time);
      arrivedTimeFormate=moment(time).format("LT")  //change 24 hr time format to 12 hr format
    }


    let withDateTimeFormatted = "";
    if (item.patient_with_doctor_time) {

      let t = moment(item.patient_with_doctor_time);  //.tz('UTC') //tz("Asia/Kolkata")
      withDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
    }

    let timer = item.patient_arrived_time ?
      item.patient_with_doctor_time ?
        this.getHHMMSS(
          moment(moment(withDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
        )
        :
        this.getHHMMSS(
          moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
        )
      : "-"



      var dialationTime  = ""
      

      let getDialationTime = "";
      if (item.dilation_start_time) {
  
        let t = moment(item.dilation_start_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
        getDialationTime = moment(t).format("DD-MM-YYYY HH:mm:ss")
      }

      if(getDialationTime){

       dialationTime =  this.getHHMMSS(
          moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(getDialationTime, "DD-MM-YYYY HH:mm:ss"))
        )
      }
          return( 
        <TouchableOpacity
          disabled={item.appointment_status === "Cancelled" ? true : false}
          style={{ flex: 1, flexDirection: "row", paddingVertical: ".5vw", justifyContent: "center", alignItems: "center", borderBottomWidth: 1, borderBottomColor: '#888888' }} onPress={() => { this.onclickAppointment(item, index) }}>
          <View style = {{ flex: 0.16, flexDirection: "row", paddingHorizontal: 20  }}>
            <Image
            source={item.patient_photo_url
              ? { uri: item.patient_photo_url }
              : ImagePath.DefaultProfile
            }
            style={[Style.appointmentTableRowImg,{borderWidth:2,borderColor:item.patient_type=="new_patient"?color.red:item.patient_type=="review_patient"?color.lightBlue:item.patient_type=="old_patient"?color.themeDark:color.white}]}
          />
             <View style={{ marginLeft: "0.5vw" }}>  
               <Text style={{ color: 'black', fontSize: '1vw' }} >{item.patient_name}</Text>
               <Text style={{ color: '#ADB4C1', fontSize: '0.8vw' }}> {item.patient_account_number}</Text>
             </View>          
          </View>
          <Text style={{ flex: 0.12 }}>{item.patient_mobile_number}</Text>
          <Text style={{ flex: 0.1 }}>{item.appointment_start_time}</Text>
          <TouchableOpacity style={{ flex: 0.15, alignItems: "center", justifyContent: "center" }}
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { this.onclickAppointment(item, index) }}>
              <Text style={{ fontSize: ".8vw", justifyContent: "center" }}>{item.appointment_status === "Cancelled" ? null : arrivedTimeFormate}</Text>
              <View>
                {item.appointment_status === "Cancelled" ?
                  <Text style={[{ fontSize: ".8vw", justifyContent: "center" }]}>-</Text> :
                  Constants.ROLE_OPT == this.state.userType ?

                    <Text style={[{
                      fontSize: ".8vw",
                      justifyContent: "center",
                      // color: this.state.selectedIndex === index ? color.white : "#293948"
                    },
                    moment("01:30:00", "HH:mm:ss") < moment(timer, "HH:mm:ss") ? {
                      color: "red", fontWeight: "500",
                      textShadowColor: 'red', textShadowOffset: { width: 0.5, height: 0.5 }, textShadowRadius: 1,
                    } : {}
                    ]} >
                      {timer}
                    </Text>

                    :
                    <Text style={[{ fontSize: ".8vw", justifyContent: "center", }]} >
                      {timer}
                    </Text>
                }

               </View>
           </TouchableOpacity>
          <Text style={{ flex: 0.1 }}>{item.token_number}</Text>
          <Text style={{ flex: 0.13 }}>{item.patient_tag_name}</Text>
          <Text style={{ flex: 0.13 }}>{item.notes}</Text>
          <View style ={{ flex: 0.1, }}>
          <TouchableOpacity style={[styles.addButton, { width: "80%", backgroundColor: color.white, borderWidth: 1, borderColor: color.black }]} 
             onPress={() => {
              this.setState({
                selectedIndex: index
              }, () => {
                this.onClickCallOption(index)
              })
             }}>
             <View style={{ flexDirection: "row" }}>
               <Image source={ ImagePath.call } style={[styles.PrintIconImg, {tintColor: color.black}]} />
               <Text style={[styles.addText, { color: color.black }]}>{"Call"}</Text>
             </View>
           </TouchableOpacity>
           </View>
           <Text style={{ flex: 0.02 }}></Text>
         </TouchableOpacity>
        )}) 
         : 
         <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
         <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
       </View>
       }
      </View>
    )
  }
  renderFOQmsTable = () => {
    var { appointmentData, speclizationList } = this.state
    return(
      <View>
       { appointmentData && appointmentData.length > 0 ? 
        appointmentData.map((item, index) => { 
          let currentDateTime = new Date()
          let currentDateTimeFormatted = moment(currentDateTime); 
          let arrivedDateTimeFormatted = "";
    if (item.patient_arrived_time) {

      let t = moment(item.patient_arrived_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
      arrivedDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
    }
    let arrivedTimeFormate=""
    if(item.patient_arrived_time){
      let time=moment(item.patient_arrived_time);
      arrivedTimeFormate=moment(time).format("LT")  //change 24 hr time format to 12 hr format
    }


    let withDateTimeFormatted = "";
    if (item.patient_with_doctor_time) {

      let t = moment(item.patient_with_doctor_time);  //.tz('UTC') //tz("Asia/Kolkata")
      withDateTimeFormatted = moment(t).format("DD-MM-YYYY HH:mm:ss")
    }

    let timer = item.patient_arrived_time ?
      item.patient_with_doctor_time ?
        this.getHHMMSS(
          moment(moment(withDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
        )
        :
        this.getHHMMSS(
          moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(arrivedDateTimeFormatted, "DD-MM-YYYY HH:mm:ss"))
        )
      : "-"



      var dialationTime  = ""
      

      let getDialationTime = "";
      if (item.dilation_start_time) {
  
        let t = moment(item.dilation_start_time);  //.tz('UTC') //tz("Asia/Kolkata") or UTC
        getDialationTime = moment(t).format("DD-MM-YYYY HH:mm:ss")
      }

      if(getDialationTime){

       dialationTime =  this.getHHMMSS(
          moment(moment(currentDateTimeFormatted, "DD-MM-YYYY HH:mm:ss")).diff(moment(getDialationTime, "DD-MM-YYYY HH:mm:ss"))
        )
      }
    
    
         return(  
          <View
            style={{ flex: 1, flexDirection: "row", paddingVertical: ".5vw", justifyContent: "center", alignItems: "center", borderBottomWidth: 1, borderBottomColor: '#888888' }} >
 
          {/* <View style={{ flexDirection: "row",, paddingVertical: "0.5vw", backgroundColor: index % 2 === 0 ? color.white : color.appointmentTableHead, justifyContent: "center", alignItems: "center" }}> */}
            <TouchableOpacity
            disabled={item.appointment_status === "Cancelled" ? true : false}
            style={{ flex: 0.16, flexDirection: "row", paddingVertical: ".5vw", paddingHorizontal: 20 }} onPress={() => { this.onclickAppointment(item, index) }}>
 
              <Image source={item.patient_photo_url ? item.patient_photo_url : ImagePath.DefaultProfile
              }
              style={[Style.appointmentTableRowImg,{borderWidth:2,borderColor:item.patient_type=="new_patient"?color.red:item.patient_type=="review_patient"?color.lightBlue:item.patient_type=="old_patient"?color.themeDark:color.white}]}
                resizeMode='stretch' />
              <View style = {{ marginLeft: "0.5vw" }}>  
               <Text style={{ color: 'black', fontSize: '1vw' }} >{item.patient_name}</Text>
               <Text style={{ color: '#ADB4C1', fontSize: '0.8vw' }}> {item.patient_account_number}</Text>
             </View> 
            </TouchableOpacity>
            <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              style={{ flex: 0.1, flexDirection: "row", paddingVertical: ".5vw" }} onPress={() => { this.onclickAppointment(item, index) }}>
 
            <Text>{item.patient_mobile_number}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              style={{ flex: 0.07, flexDirection: "row", paddingVertical: ".5vw" }} onPress={() => { this.onclickAppointment(item, index) }}>
               <Text>{item.appointment_start_time}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={{ flex: 0.13 }}
              disabled={item.appointment_status === "Cancelled" ? true : false}
              onPress={() => { this.onclickAppointment(item, index) }}>
              <Text style={{ fontSize: ".8vw", justifyContent: "center" }}>{item.appointment_status === "Cancelled" ? null : arrivedTimeFormate}</Text>
              <View>
                {item.appointment_status === "Cancelled" ?
                  <Text style={[{ fontSize: ".8vw", justifyContent: "center" }]}>-</Text> :
                  Constants.ROLE_OPT == this.state.userType ?

                    <Text style={[{
                      fontSize: ".8vw",
                      justifyContent: "center",
                      // color: this.state.selectedIndex === index ? color.white : "#293948"
                    },
                    moment("01:30:00", "HH:mm:ss") < moment(timer, "HH:mm:ss") ? {
                      color: "red", fontWeight: "500",
                      textShadowColor: 'red', textShadowOffset: { width: 0.5, height: 0.5 }, textShadowRadius: 1,
                    } : {}
                    ]} >
                      {timer}
                    </Text>

                    :
                    <Text style={[{ fontSize: ".8vw", justifyContent: "center", }]} >
                      {timer}
                    </Text>
                }

               </View>
           </TouchableOpacity> 
           <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              style={{ flex: 0.06, flexDirection: "row", paddingVertical: ".5vw" }} onPress={() => { this.onclickAppointment(item, index) }}>
                <Text>{item.token_number}</Text>
           </TouchableOpacity>
           <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              style={{ flex: 0.08, flexDirection: "row", paddingVertical: ".5vw" }} onPress={() => { this.onclickAppointment(item, index) }}>
            <Text>{item.patient_tag_name}</Text>
           </TouchableOpacity> 
           <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              style={{ flex: 0.13, flexDirection: "row", paddingVertical: ".5vw" }} onPress={() => { this.onclickAppointment(item, index) }}>
               <Text>{item.specialization__name}</Text>
           </TouchableOpacity> 
          <View style = {{ flex: 0.1 }}>
           <Picker
              
              selectedValue={appointmentData[index].doctor_id}
              disabled={this.state.isLocked}
              style={[styles.SelectTemplateDropDown,Style.allButtonBorderRadius, {width: "90%"}]}
              itemStyle={{ fontSize: '0.8vw' }}
              onValueChange={(itemvalue) => {
                appointmentData[index].doctor_id = itemvalue 
                this.setState({ 
                  isConformation: true,
                  typedIndex: index
                })
              }}
            >
              <Picker.Item key={-1} label={"Select Doctor"} value={0} />
              { item.doctorSelectionList && item.doctorSelectionList.length > 0 && item.doctorSelectionList.map((i, index) => (
                <Picker.Item key={index} label={i.doctor_name} value={i.doctor_id} />
              ))}
            </Picker>
          </View>
          <TouchableOpacity
              disabled={item.appointment_status === "Cancelled" ? true : false}
              style={{ flex: 0.07, flexDirection: "row", paddingVertical: ".5vw", justifyContent: "center", alignItems: "center" }} onPress={() => { this.onclickAppointment(item, index) }}>
                <Text>{item.room_no}</Text>
          </TouchableOpacity>      
          <View style={{ flexDirection: "row", flex: 0.1, alignItems: "center" }}>
           <TouchableOpacity
                disabled={item.appointment_status === "Cancelled" ? true : false}
                onPress={() => { 
                  // if(this.state.isFoAdmin == "true" && !this.state.isAvailableIndicatuionFlag){
                  //   this.props.showResposeValue("error",Messages.QMSCheckinValidation);
                  // }else{
                    this.changeState("checkIn", index, "Arrived", item) 
                  // }
                  }
                  
                } 
                style={[
                  styles.statusIconStyles,(item.patient_arrived_time) ? {backgroundColor:color.themeDark}:null, { padding: "0.6vw" } 
                  ]}>
                <Tooltip placement="top" title={ item.appointment_status === "Cancelled" ? null :"Arrived"}>
                      <Image 
                       source={ImagePath.Arrived}
                       style={[styles.statusIconHeight ,{tintColor:item.patient_arrived_time ? color.white : color.black}]} />
                </Tooltip>
              </TouchableOpacity>
              <TouchableOpacity
                disabled={
                  item.appointment_status === "Cancelled" ||
                    item.appointment_status === "With Doctor" ||
                    item.appointment_status === "Completed" ||
                    item.appointment_status === "Scan/Surgery"
                    ? true : false}
                onPress={() => { this.changeState("cancelFlag", index, "Cancelled", item) }} style={[styles.statusIconStyles,]}>
                <Tooltip placement="top" title={item.appointment_status === "Cancelled" ? null : "Cancel Appointment"}>
                  <Image
                    source={ImagePath.Remove}
                    style={[Style.appointmentTableStatusView, { height: "1.3vw", width: "1.3vw", tintColor: color.black }]} />
                </Tooltip>
              </TouchableOpacity>
          </View>
         {/* </View> */}
         {this.state.CancelAppointmentVisible  && this.props.cancelAppointmentReason?
                    this.changeAppointmentStatus(
                      this.props.CancelAppointment_type, this.props.CancelAppointment_item, this.props.cancelAppointmentReason
                    ) : null}  
         </View>

         )})
         : 
         <View style={{ flex: 1, flexDirection: "row", justifyContent: "center", alignContent: "center", minHeight: "10vw" }}>
          <Text style={{ color: color.placeholder, marginTop: "10vw" }}>{"No records to be shown"}</Text>
         </View>
       }
      </View>
    )
  }
  clearData = () => {
    this.setState({
      isTransfer: false,
      doctor_id: "",
      selectedAppointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedAppointmentTime: moment(new Date()).format("hh:mm"),
      purposeOfVisitsInNextAppoinment: ""
    })
  }

  PostTransferAppointment = () => {
    var url = ""
    var { selectedClinicOrDoctor} = this.state
    url = Constants.DOCTOR_CREATE_APPOINTMENT
    var states = this.state
    var data = {
      "patient_id": parseInt(states.TranferData.patient_id ),
      "clinic_id": parseInt(selectedClinicOrDoctor.id),
      "doctor_id": parseInt(this.state.doctor_id),
      "purpose_of_visit": this.state.purposeOfVisitsInNextAppoinment,
      "appointment_patient_type": states.TranferData.appointment_patient_type,
      "appointment_date": this.state.selectedAppointmentDate,
      "appointment_start_time": this.state.selectedAppointmentTime + ":00",
    }
    if(this.state.doctor_id === "") {
      this.props.showResposeValue("error", "Please select the doctor")
    }else if(this.state.purposeOfVisitsInNextAppoinment === ""){
      this.props.showResposeValue("error", "Please select the Purpose of Visit")
    } else { 
    OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.TransferAppointSuccess,
      this.TransferAppointFailure
    );
    }
  }

  TransferAppointSuccess = (response) => {
    if(response && response.status === "success") {
       this.changeAppointmentStatus("Completed", this.state.TranferData.appointment_id, this.state.TranferData, true )
       setTimeout(() => {
        this.props.showResposeValue("success", response.message)
       }, 1000);
    } else {
      this.props.showResposeValue("error", response.message)
    }
    this.setState({
      isTransfer: false,
      doctor_id: "",
      selectedAppointmentDate: moment(new Date()).format("YYYY-MM-DD"),
      selectedAppointmentTime: moment(new Date()).format("hh:mm"),
      purposeOfVisitsInNextAppoinment: ""
    })
  }

  TransferAppointFailure = () => {
    this.props.showResposeValue("error", error.message)
  }

  onPressTransferData = () => {
    var url = ""
    var service_url = ""
    url = Constants.DOCTOR_CREATE_APPOINTMENT
    service_url = Constants.LABORATORY_TRANSMIT
    var { transferPatient, selectedClinicOrDoctor, selectLaboratory } = this.state
    var today = new Date()
    var converted_date = moment(today).format("YYYY-MM-DD")
    var converted_Time = moment(today).format("hh:mm:ss")
    var service_data = {
      // "test_order_id":id,
      "patient_id": parseInt(transferPatient.patient_id ),
      "laboratory_id": selectLaboratory,
      "appointment_id": parseInt(transferPatient.appointment_id )
      
    }
    var data = {
      "patient_id": parseInt(transferPatient.patient_id ),
      "clinic_id": parseInt(selectedClinicOrDoctor.id),
      "doctor_id": parseInt(this.state.selectedDoctor),
      "purpose_of_visit": "",
      "appointment_patient_type": "",
      "appointment_date": converted_date,
      "appointment_start_time": converted_Time,
      "create_without_schedule": true,
      "patient_tags": "",
      "specialization_id": parseInt(this.state.selectDepartment)
    }
    if( this.state.selectDepartment === "" && this.state.selectedDoctor === "") {
      this.props.showResposeValue("error", "Please select the specilization or doctor")
    } else { 
    OpthamologyService.getInstance().postComplaints(
      url,
      data,
      this,
      this.onPressTransferDataSuccess,
      this.onPressTransferDataFailure
    );
    }
    // } else {
    //   OpthamologyService.getInstance().postComplaints(
    //     service_url,
    //     service_data,
    //     this,
    //     this.onPressTransferDataSuccess,
    //     this.onPressTransferDataFailure
    //   );
    // }
  }
  onPressTransferDataSuccess = (response) => {
    if(response && response.status === "success") {
       this.setState({
         isPatientTransfer: false
       })
       this.getAppointmentStat()
       this.getAppointments()
       this.changeState("checkedOut", this.state.typedIndex, "Completed", this.state.transferPatient) 
       this.props.showResposeValue("success", response.message)
    } else {
      this.props.showResposeValue("error", response.message)

    }

  }
  onPressTransferDataFailure = (error) => {
    this.props.showResposeValue("error", error.message)

  }
  renderPickerView = (doctor, department) => {
    var selectedDepartment = this.state.selectDepartment != "" ? this.state.selectDepartment : department
    return (
      <View style = {{  flexDirection: "row", justifyContent: "space-between", flex: 1}}>
        <View style = {{ flex: 0.5 }}>
          <Text style={{ fontSize: '0.87vw', fontWeight: "bold" }}>{"Specialization"}</Text>
          <Picker
            selectedValue={selectedDepartment}
            disabled={this.state.isLocked}

            style={[Style.allButtonBorderRadius, { width: "80%", marginTop: "0.5vw" }]}
            itemStyle={{ fontSize: '0.7vw', color: "black" }}
            onValueChange={(itemvalue) => {
              this.setState({
                selectDepartment: itemvalue,
              })
            }}
          >
            <Picker.Item key={-1} label={"Select Specialization"} value={0} />
            { doctor && this.state.departmentsList.length > 0 && this.state.departmentsList.map((i, index) => (
              <Picker.Item key={index} label={i.value} value={i.id} />
            ))}
          </Picker>
        </View>
        <View style={{ flex: 0.5 }}>
          <Text style={{ fontSize: '0.87vw', fontWeight: "bold" }}>{"Doctor's Name"}</Text>
          <Picker
            selectedValue={this.state.selectedDoctor}
            disabled={this.state.isLocked}
            style={[ Style.allButtonBorderRadius, { width: "80%", marginTop: "0.5vw" }]}
            itemStyle={{ fontSize: '0.7vw', color: "black" }}
            onValueChange={(itemvalue) => {
              this.setState({
                selectedDoctor: itemvalue,
              })
            }}
          >
            <Picker.Item key={-1} label={"Select Doctor"} value={0} />
            {doctor && doctor.length > 0 && doctor.map((i, index) => (
              <Picker.Item key={index} label={i.value} value={i.id} />
            ))}
          </Picker>
        </View>
      </View>
    )
  }

  renderSerach = () => {
    let purposeOfVisit = this.state.popListData ? this.state.popListData.map(item => item.reason) : []

    return (
      purposeOfVisit.length > 0 ?
        purposeOfVisit.map((item) => (
          <Option key={item} value={item}>{item}</Option>
        )) : []
    )
  }

  formatDate = (input) => {
    let Date = "-"
    if(input !== null && input !== undefined && input !== ""){
    let datePart = input.match(/\d+/g),
        year = datePart[0].substring(0),
        month = datePart[1], day = datePart[2];
        if(year.length == 4){
            Date = day + '-' + month + '-' + year;
        }else{
            Date = year + '-' + month + '-' + day;
        }

    }
    return Date
}

  purposeOfVisitListScrollView() {
    return (
      <ScrollView style={{ position: 'absolute', zIndex: 2, marginTop: 120, maxHeight: 250, maxWidth: 500, borderRadius: 4, borderWidth: 1, borderColor: "#888888", backgroundColor: color.white }} showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false}>
        {
          this.state.purposeOfVisitList && this.state.purposeOfVisitList.length > 0 ?
            this.state.purposeOfVisitList.map((item, index) => {
              return (
                <TouchableOpacity key={index} style={{ paddingHorizontal: 15 }}
                  onPress={() => {
                    this.setState({
                      purposeOfVisitsInNextAppoinment: item,
                      purposeOfVisitList: []
                    })
                  }}
                >
                  <Text style={{ color: color.black, fontSize: 13 }}>{item}</Text>
                </TouchableOpacity>
              )
            })
            : null
        }
      </ScrollView>
    )
  }

  TransferAppointment() {
    var fields = this.state;
    return (
      <View style={{ position: 'absolute' }}>
        <View style={styles.TransferPopUpView}>
          <TouchableWithoutFeedback>
            <View style={styles.TransferAppointScrn}>
              <View style={styles.TransferAppointHead}>
                <Text style={styles.TransferAppointText}>{"Transfer Appointment"}</Text>
                <TouchableOpacity onPress={() => { this.clearData() }}>
                  <Icon name="close" size={24} color={"#000000"} />
                </TouchableOpacity>
              </View>

              <View style={{ flexDirection: 'row', zIndex: 1, marginTop: "1vw", height:"3vw" }}>
                <View>
                  <Image
                    source={this.state.TranferData.patient_photo_url ? { uri: this.state.TranferData.patient_photo_url } : require("../../../../assets/images/sample_profile.png")}
                    style={[styles.patientImage,{borderWidth:2,borderColor:this.state.TranferData.patient_type=="new_patient"?color.red:this.state.TranferData.patient_type=="review_patient"?color.lightBlue:this.state.TranferData.patient_type=="old_patient"?color.themeDark:color.white}]}
                  />
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', marginLeft: "1vw", width: '100%' }}>
                  <View style={{ justifyContent: 'center', flex: 0.7, paddingLeft: 10 }}>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={{ fontSize: '1.15vw' }}>{this.state.TranferData.patient_name}</Text>
                      <View style={styles.patientDetails}><Text style={{ fontSize: '1vw', color: 'black' }}>{this.state.TranferData.patient_gender}{"  |  "}{this.state.TranferData.patient_age ? this.state.TranferData.patient_age : "-"}{"  |  "}{this.state.TranferData.patient_dob ? this.formatDate(this.state.TranferData.patient_dob) : "-"}</Text></View>
                    </View>
                    <View style={{ flexDirection: "row" }}>
                      <Text style={styles.patientUniqueDetails}>{this.state.TranferData.patient_account_number} {" | "} {this.state.TranferData.patient_mobile_number}</Text>
                    </View>
                    {this.state.TranferData.address_line_1 || this.state.TranferData.address_line_2 ?
                      <View style={{ width: "10vw", marginLeft: '6.3vw' }}>
                        <Tooltip placement="topLeft" style={{ width: "5vw", height: "2vw", marginTop: 5 }} title={this.state.TranferData.address_line_1 + "," + this.state.TranferData.address_line_2} >
                          <Text style={styles.patientUniqueDetails}>{this.state.TranferData.address_line_1}{","}</Text>
                          <Text style={styles.patientUniqueDetails} numberOfLines={0.5} ellipsizeMode="tail">{this.state.TranferData.address_line_2} </Text>
                        </Tooltip>
                      </View> : null
                    }
                  </View>
                </View>
              </View>

              <View style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                <View style={{width:"50%"}}>
                  <Text style={{ color: "#888888", marginTop: "1vw", fontSize: '1vw' }}>Appointment Date</Text>
                  <DatePicker
                    clearIcon={false}
                    value={moment(this.state.selectedAppointmentDate)}
                    format={"DD-MM-YYYY"}
                    disabled={true}
                    style={{ borderColor: color.themeDark, borderWidth: 2, width: "90%", marginTop: 5, zIndex: -1 }}
                    onChange={(date) => {
                      var fulldate = new Date(date);
                      var converted_date = moment(fulldate).format("YYYY-MM-DD");
                      this.setState({
                        selectedAppointmentDate: converted_date,
                        durationValue: 0,
                        showDurationType: false,
                        selectedDurationType: {
                          id: 1,
                          value: "Days"
                        }
                      })
                    }}
                  />
                </View>
                <View style={{ width: "50%" }}>
                  <Text style={{ color: "#888888", marginTop: "1vw", fontSize: '1vw' }}>Appointment Time</Text>
                  <TimePicker
                    clearIcon={false}
                    format="h:mm"
                    disabledHours={() => {
                      const currentHour = moment().hour();
                      const disabledHours = [];
                      for (let i = 0; i < currentHour; i++) {
                        disabledHours.push(i);
                      }
                      return disabledHours;
                    }}
                    onChange={(time, timeString) => {
                      this.setState({ selectedAppointmentTime: timeString }, () => {console.log(timeString);})
                    }}
                    defaultValue={moment(this.state.selectedAppointmentTime, "hh:mm")}
                    style={{ borderColor: color.themeDark, borderWidth: 2, width: "90%", marginTop: 5, zIndex: -1 }} />
                </View>
              </View>

              <View>
                <Picker
                  style={{
                    borderColor: "#888888",
                    borderWidth: 1,
                    marginTop: '1.2vw',
                    height: 35,
                    borderWidth: 1,
                    borderRadius: '0.2vw',
                    alignItems: "center",
                    flexDirection: "row",
                    paddingLeft: '0.65vw',
                    paddingLeft: '0.65vw',
                    paddingRight: '0.65vw',
                    width: "95%",
                    zIndex: -1,
                    fontSize: '0.91vw',
                    backgroundColor: "rgb(248, 252, 255)"
                  }}
                  selectedValue={this.state.doctor_id}
                  onValueChange={(itemValue) => {
                    let states = this.state
                    states.doctor_id = itemValue;
                    this.setState({
                      doctor_id: JSON.parse(JSON.stringify(states.doctor_id))
                    })
                  }}
                >
                  <Picker.Item label={"Select Doctor"} value={""} />
                  {this.state.DoctorLists?.map((doc) => {
                    var docName = (doc.title != null ? doc.title : "") + " " + doc.first_name + " " + (doc.initial != null ? doc.initial : "" )
                    return (
                      <Picker.Item label={docName} value={doc.doctor_id} />
                    )
                  })}
                </Picker>
              </View>

              <View>
                <Text style={{ color: "#888888", marginTop: "1.2vw", fontSize: '1vw' }}>Purpose of visit *</Text>
                <AutoComplete
                  style={{ fontSize: '1vw', color: "grey", width: "95%", marginTop: 5, height: '5.5vw', borderRadius: 10, borderColor: "grey", borderWidth: 1 }}
                  value={this.state.purposeOfVisitsInNextAppoinment}
                  onChange={(value) => {
                    this.setState({ purposeOfVisitsInNextAppoinment: value })
                  }}
                  dataSource={this.renderSerach()}
                  filterOption={(inputValue, option) => option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                />
                {
                  this.purposeOfVisitListScrollView()
                }
              </View>

              <View style={{ flexDirection: "row", alignSelf: "center", zIndex: -10, marginTop: -10 }}>
                <TouchableOpacity onPress={() => { this.PostTransferAppointment()}} style={{ backgroundColor: color.themeDark, padding: 7, borderRadius: 4, width: '8vw', justifyContent: 'center', marginRight: 5 }}>
                  <Text style={{ textAlign: 'center', color: 'white', fontSize: '1vw' }}>Transfer</Text>
                </TouchableOpacity>
                <TouchableOpacity onPress={() => { this.clearData() }} style={styles.closeButton}>
                  <Text style={{ textAlign: 'center', fontSize: '1vw' }}>Close</Text>
                </TouchableOpacity>
              </View>

            </View>
          </TouchableWithoutFeedback>
        </View>
      </View>
    )
  }

  HomeFilterSearchPopUp () {
    return(
      <View style={styles.FilterPopUpView}>
        <View style={styles.FiterTop}>
          <Text style={styles.FilterHeadTxt}>Search Filters</Text>
          <TouchableOpacity onPress={() => {
            this.setState({
              AddressSearchKey:"",
              FatherHusSearchKey:"",
              FilterPopupErrorText:""
            }, ()=>{this.props.FilterSearchClose()}) 
            }}>
            <Icon name="close" size={"1.2vw"} color={color.red} />
          </TouchableOpacity>
        </View>
        <View>
          <View style={styles.FilterRows}>
                <TextInput
                  style={styles.SearchInput}
                  placeholder="Enter Address"
                  value={this.state.AddressSearchKey}
                  onChangeText={(text) => {
                    this.setState({
                      AddressSearchKey: text
                    })
                  }}
                />
          </View>
          <View style={styles.FilterRows}>
                <TextInput
                  style={styles.SearchInput}
                  placeholder="Enter Father / Husband Name"
                  value={this.state.FatherHusSearchKey}
                  onChangeText={(text) => {
                    this.setState({
                      FatherHusSearchKey: text
                    })
                  }}
                />
          </View>
        </View>
        <View>
          <Text style={styles.filterPopErrorText}>{this.state.FilterPopupErrorText}</Text>
        </View>
        <View style={styles.FilterBtnView}>
          <TouchableOpacity
            onPress={() => { 
              if(this.state.AddressSearchKey != "" || this.state.FatherHusSearchKey != ""){
                this.setState({
                  FilterPopUpOpen : false,
                  FilterPopupErrorText : ""
                }, ()=>{this.searchPatient()})
              }else{
                this.setState({
                  FilterPopupErrorText : "Enter Address or Father/Husband Name !"
                })
                setTimeout(()=>this.setState({FilterPopupErrorText:""}), 2000)
              }
               }}
            style={styles.BtnTouch}>
            <Text style={styles.ButtonText}>{"Search"}</Text>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => { this.setState({ AddressSearchKey:"", FatherHusSearchKey:"", FilterPopupErrorText:""}) }}
            style={[styles.BtnTouch, { backgroundColor: color.white, borderColor: color.themeDark, borderWidth: 1 }]}>
            <Text style={[styles.ButtonText, { color: color.themeDark }]}>{"Clear"}</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  }
  render() {
    this.state.currentAppointmentsDateValue = this.props.currentAppointmentsDateValue
    this.checkValidateTheDays();
    var states = this.state
    var { transferPatient, originalDoctorList, departmentsList } = this.state
    var department = ""
    var doctor = []
    if(this.state.selectDepartment == "") {
        // doctor = this.state.doctorsList
    } else {
     if(originalDoctorList && originalDoctorList.length > 0 ) {  
      for(let i =0 ; i < originalDoctorList.length ; i++ ){
        if(originalDoctorList[i].speciality_id === parseInt(this.state.selectDepartment)) {
          doctor.push(originalDoctorList[i])
         
        }
      }
     }
      
    }
    doctor = this.state.selectDepartment != "" ? doctor : this.state.originalDoctorList
    if(this.state.selectedDoctor == "") {
      // department.push(this.state.departmentsList)

    } else {
      for(let i =0 ; i < departmentsList.length ; i++ ){
        if(departmentsList[i].doctor_id === parseInt(this.state.selectedDoctor)) {
          department = departmentsList[i].id
         
        }
      }
    }
    return (
      <TouchableWithoutFeedback onPress={() => { 
        this.setState({ 
          dropDownViewFlag: false, 
          searchPatientData: [], 
          },()=>{
            this.props.clearSearchTextBoxValue()
            }) }}>
        <View>
        <View 
        // style={Style.rootView}
        >
          {/* side menu */}
          {/* <View style={[Style.leftBarView]}>
            <SideMenu navigation={this.props.navigation} />
          </View> */}
          {/* first view */}
          <View
          // style={[Style.leftView, { width: platform === "web" ? "100%" : "100%" }]}
          >
            {/* <View style={Style.leftBarView}></View> */}
            <View style={{ marginTop: platform === "web" ? 0 : 20, width: "100%" }}>

              {/* {this.renderStatView()} */}

              {/* <View style={[Style.contentPageLine,{zIndex:-1}]} ></View> */}
              <View style={{zIndex:-1}}>
                {this.renderHeaderView()}

              { !this.state.isOnQueueClick ? 

               <View>
                {this.renderAppointmentTitle()}

                {/* {
                  this.state.appointmentData.length > 0 ?
                    <Pagination
                      totalItems={this.state.appointmentData}
                      paginationChangedValues={this.paginationChangedValues.bind(this)} />
                    : null
                } */}
                

                <View style={{ zIndex: -10 }} onLayout={(event) => {
                            var { x, y, width, height } = event.nativeEvent.layout;
                            console.log("height" + height)}}>
                  <ScrollView
                    horizontal={false}
                    showsVerticalScrollIndicator={true}

                    style={{
                      height: "65vh"
                      // backgroundColor:color.appointmentTableHead,
                    }}
                    contentContainerStyle={{ flex: 1 }} >
                    {
                      this.state.appointmentData && this.state.appointmentData.length ?
                        this.state.appointmentData.map((item, index) => {
                          var data = item;
                          if(data.qms_token){
                          }else{
                            data["qms_token"]=""
                          }

                          if(data.qms_token_digit){ 
                          }else{
                            data["qms_token_digit"]=""
                          }

                          if(data.qmsTokenStatus){ 
                          }else{
                            data["qmsTokenStatus"]=""
                          }

                          

                          return this.renderAppointments(item, index)
                        }) :

                        <View style={[styles.loaderStyle]}>
                          { this.state.pageLoading ? 
                            <ActivityIndicator size="large" color={color.themeDark} /> : <Text style={{ fontSize: "2vw" }}>{"No Records.."}</Text>
                          }
                        </View>
                    }
                    {this.state.WaitingCommentsVisible  && this.props.WaitingComments?                                       
                    this.changeAppointmentStatus(
                      this.props.WaitingComments_type, this.props.WaitingComments_item, this.props.WaitingComments
                    ) : null}
                    {this.state.CancelAppointmentVisible  && this.props.cancelAppointmentReason?
                    this.changeAppointmentStatus(
                      this.props.CancelAppointment_type, this.props.CancelAppointment_item, this.props.cancelAppointmentReason
                    ) : null}                  
                  </ScrollView>
                </View>
               </View> : this.state.isOnQueueClick && this.state.userType === "FO" ?
                 <View style = { styles.onQueueContainer }>
                   {this.renderFOQmsHeader()}
                   {this.renderFOQmsTable()}
                    
                 </View> :
                 <View style = { styles.onQueueContainer }>
                   {this.renderDoctorQMSHeader()}
                   {this.renderDoctorQMSTable()}
                 </View>

                }


                {
                  this.state.searchPatientData && !this.state.FilterPopUpOpen &&this.state.searchPatientData.length > 0  ?

                    <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "94vw", height:"90.6vh", alignItems: 'flex-start', paddingLeft:20,paddingBottom: 30 }}>

                      <View style={{ width: "40vw", backgroundColor: 'white', borderBottomRightRadius: 10, borderBottomLeftRadius: 10, paddingBottom: 10 }}>
                        <ScrollView showsHorizontalScrollIndicator={false} showsVerticalScrollIndicator={false} style={{ maxHeight: "60vh" }}>
                          {
                            this.state.searchPatientData.map((item, index) => {
                              return (
                                <View key={index} style={{ flexDirection: 'row', justifyContent: 'flex-start', flex: 1, paddingTop: '0.32vw',marginLeft:'1vw', paddingBottom: "0.32vw", borderBottomColor: "#E4E8F1", borderBottomWidth: 1, paddingRight:'10vw' }}>
                                  <View style={{ flexDirection: 'row', alignItems: 'center', width:'30vw'}}>
                                    <Image source={item.photo_url ? item.photo_url : ImagePath.DefaultProfile
                                    }
                                      style={Style.appointmentTableRowImg}
                                      resizeMode='stretch' />
                                    <Text style={{ color: 'black', marginLeft: 10 , fontSize:'1vw'}} >{item.first_name}</Text>
                                    <Text style={{ color: '#ADB4C1', marginLeft: 10, fontSize:'1vw' }}> {item.patient_account_number}{item.patient_account_number ? " | " : null}{item.mobile_number}</Text>
                                  </View>

                                  {this.state.searchPatientData && Object.keys(item.appointment).length > 0 ?
                                    // <View style={{ justifyContent: 'center', marginRight: 10, flexDirection: "row", alignItems:'center' }}>
                                    //   <Text style={{ fontSize:'1vw'}}>{item.appointment.appointment_date}</Text>
                                    //   <Tooltip placement="topLeft" title={"Edit patient details"}>               
                                    //   <TouchableOpacity style={{ height: 40, justifyContent: 'center', borderRadius: 10, marginLeft: 10}}
                                    //       onPress={() => {
                                    //         this.props.changeScreen("demographics", {}, "", {}, "", {}, {}, false, item.id)
                                    //       }}
                                    //     >
                                    //        <Image 
                                    //           source={require('../../../../assets/images/Active/Edit.svg')}
                                    //           style={{ height: '1.5vw', width: '1.5vw' }} 
                                    //         />
                                    //   </TouchableOpacity> 
                                    // </Tooltip>  
                                    // </View>
                                    // :
                                    <View style={{ flexDirection: "row",  alignItems:'center' }}>
                                    <Tooltip placement="topLeft" title={"Patient records"}>               
                                      <TouchableOpacity style={{ height: 40, justifyContent: 'center', borderRadius: 10,  marginRight:10 }}
                                          onPress={() => {
                                            item["patient_id"] = item.id; 
                                            this.props.changeScreen("reportsScreen",item, this.state.userType, this.state.selectedClinicOrDoctor,"",{},{},false,"",{},false,false,false,false,{},"",true)
                                          }}
                                        >
                                           <Image 
                                              source={require('../../../../assets/images/Active/Edit.svg')}
                                              style={{ height: '1.5vw', width: '1.5vw' }} 
                                            />
                                      </TouchableOpacity> 
                                    </Tooltip>  
                                    <Tooltip placement="topLeft" title={"appointment"}>               

                                      <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', borderRadius: 10, marginLeft: 10 }} onPress={() => {
                                        this.props.clearSearchTextBoxValue()
                                        this.getcurrenDateMonthYear()
                                        this.setState({

                                          patientId: item.id,
                                          searchPatientData: [],

                                          ShowCreateappointment: true,
                                          ShowCreateAppointmentPatientId: item.id,
                                          searchBar: "",
                                          AddressSearchKey:"",
                                          FatherHusSearchKey:""
                                        }, () => {
                                          this.props.FilterSearchClose()
                                          // alert(this.state.ShowCreateAppointmentPatientId)
                                          this.setModalVisible("fromSeachBar")
                                        })

                                       }}>
                                            <Image 
                                              source={require('../../../../assets/images/Active/Appointment.svg')}
                                              style={{ height: '1.5vw', width: '1.5vw', tintColor: color.themeDark }} 
                                            />            
                                      </TouchableOpacity>
                                     </Tooltip> 
                                    </View> 
                                     :
                                    <View style={{ flexDirection: "row",  alignItems:'center' }}>
                                    <Tooltip placement="topLeft" title={"Patient records"}>               
                                      <TouchableOpacity style={{ height: 40, justifyContent: 'center', borderRadius: 10,  marginRight:10 }}
                                          onPress={() => {
                                            item["patient_id"] = item.id; 
                                            this.props.changeScreen("reportsScreen",item, this.state.userType, this.state.selectedClinicOrDoctor,"",{},{},false,"",{},false,false,false,false,{},"",true)
                                          }}
                                        >
                                           <Image 
                                              source={require('../../../../assets/images/Active/Edit.svg')}
                                              style={{ height: '1.5vw', width: '1.5vw' }} 
                                            />
                                      </TouchableOpacity> 
                                    </Tooltip>  
                                    <Tooltip placement="topLeft" title={"appointment"}>               

                                      <TouchableOpacity style={{ alignItems: 'center', justifyContent: 'center', borderRadius: 10, marginLeft: 10 }} onPress={() => {
                                        this.props.clearSearchTextBoxValue()
                                        this.getcurrenDateMonthYear()
                                        this.setState({

                                          patientId: item.id,
                                          searchPatientData: [],

                                          ShowCreateappointment: true,
                                          ShowCreateAppointmentPatientId: item.id,
                                          searchBar: "",
                                          AddressSearchKey:"",
                                          FatherHusSearchKey:""
                                        }, () => {
                                          this.props.FilterSearchClose()
                                          // alert(this.state.ShowCreateAppointmentPatientId)
                                          this.setModalVisible("fromSeachBar")
                                        })

                                       }}>
                                            <Image 
                                              source={require('../../../../assets/images/Active/Appointment.svg')}
                                              style={{ height: '1.5vw', width: '1.5vw', tintColor: color.themeDark }} 
                                            />            
                                      </TouchableOpacity>
                                     </Tooltip> 
                                    </View>  
                               }  
                                </View>
                              );
                            })

                          }
                        </ScrollView>
                      </View>

                    </View>
                    : null
                }

              </View>




              {/* <View style={{ marginTop: 22 }}> */}

              {/* </View> */}

            </View> 
          </View>
          
        </View>
        <View style={{zIndex:1, position:'absolute', width:'100%'}}>
        {this.state.isCounsellorPatientSelected && this.state.patientTransmittedCounsellingData ? 
        <View style={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: "center", paddingLeft:10, justifyContent: "center" }}>
          <View style={{ 
          position:"absolute",
          backgroundColor:color.white, 
          border: "1px solid lightgrey",
          borderRadius: 10,
          padding: 10,
          height: "75vh",
          width:"80%",

        }}>
          <View style={{height:'72vh', justifyContent: "flex-start" }}>
          <View style={{flex: 1, flexDirection: "row", maxHeight: '5vh', zIndex: 0}}>
          <View style={{flex: 0.96, maxHeight: "fit-content"}}>
          <Text style={{fontSize: '1.5vw', fontWeight: "bold", color: color.themeDark}}>{"Package Details"}</Text>
          </View>
          <View style={{flex: 0.04, maxHeight: "fit-content"}}>
            <TouchableOpacity onPress={() => {
              this.setState({
                isCounsellorPatientSelected: false,
                selected_appointment_id: null,
                selected_patient_id: null,
                isPackageEdit: false,
                searchPackageOpen: false,
                totalPackageData: [],
                suggestionPackageData: [],
              })
            }} style={{ paddingHorizontal: 6, justifyContent: "center", alignItems: 'flex-end', alignContent: 'flex-end', alignSelf: "center", paddingVertical: 3, backgroundColor: color.themeDark, borderRadius: 50 }}>
              <Icon size={'1.6vw'} name={"close"} color={"#fff"} />
            </TouchableOpacity>
          </View>
          </View>
          <View style={{flex: 1, flexDirection: 'row', marginBottom: '0.5vh', maxHeight: '12vh', zIndex: 0}}>
            <View style={{flex: 0.15, height: '12vh', justifyContent: 'space-evenly'}}>
              <Text style={{fontSize: '0.9vw'}}>Surgery Name</Text>
              {this.state.is_OPT ? <Text style={{fontSize: '0.9vw'}}>Lens Power</Text> : null}
              {this.state.is_OPT ?<Text style={{fontSize: '0.9vw'}}>Eye Type</Text> : null}
            </View>
            <View style={{flex: 0.05, height: '12vh', justifyContent: 'space-evenly'}}>
              <Text style={{fontSize: '0.9vw'}}>{":"}</Text>
              {this.state.is_OPT ?<Text style={{fontSize: '0.9vw'}}>{":"}</Text>: null}
              {this.state.is_OPT ?<Text style={{fontSize: '0.9vw'}}>{":"}</Text>: null}
            </View>
            <View style={{flex: 0.80, height: '12vh', justifyContent: 'space-evenly'}}>
              <Text style={{fontSize: '0.9vw'}}>{states.surgeryName}</Text>
              {this.state.is_OPT ?<Text style={{fontSize: '0.9vw'}}>{states.lensPower}</Text>: null}
              {this.state.is_OPT ?<Text style={{fontSize: '0.9vw'}}>{states.selectedEyeType.label}</Text>: null}
            </View>
          </View>
          {this.tableHeader()}
          <View style={{flexDirection: "row", flex: 1, marginTop: '4vh', justifyContent: 'space-between', maxHeight: '10vh', zIndex: 2}}>
            <View style={{flex: 0.23}}>
            <Text style={{fontSize: '0.9vw', fontWeight: "bold", marginBottom: 5}}>Surgeon Name *</Text>
            <TextInput 
            style={{borderWidth: 1, borderRadius: 2, padding: 3, borderColor: color.themeDark, height: '4vh', fontSize: '0.9vw'}}
            value={this.state.surgeonName}
            onBlur={() => { 
              this.setState({
                isSurgieonDoctorSuggestion: false
              })   
            }}
            onChangeText={(text) => {
              if((text).trim() != ""){
                this.setState({
                    isSurgieonDoctorSuggestion: true
                })
                this.searchDoctorSuggestion(text)
            }else{
                this.setState({
                    isSurgieonDoctorSuggestion: false
                })
            }
              this.setState({
                surgeonName: text
              })
            }}
            />
            {(this.state.isSurgieonDoctorSuggestion && this.state.doctorSuggestionData && (this.state.doctorSuggestionData).length > 0 ) ? 
                
              <View style={{ zIndex: 2, position: 'absolute', marginLeft: "5%", maxHeight: '15.56vw', width: "90%", marginTop: '3.5vw' }}>
                  <ScrollView
                  style={{
                      height: '15vw', marginBottom: '0.8vw', flex: 1,
                      backgroundColor: 'white', borderColor: color.themeBlue, borderWidth: 2, borderRadius: 4
                  }}
                  >
                      {(this.state.doctorSuggestionData).map((value,index) => 
                          <View>
                              <TouchableOpacity 
                              key={index}
                              style={{marginTop: 10, paddingLeft: 10}}
                              onPress={() => {
                                  var doctorFullName = value.first_name + " " + value.initial
                                  this.setState({
                                    surgeonName: doctorFullName,
                                    isSurgieonDoctorSuggestion: false
                                  })
                              }}
                              >
                                  <Text style={{fontSize: '0.9vw'}}>{ value.first_name + " " + value.initial }</Text>
                              </TouchableOpacity>
                          </View>
                      )}
                  </ScrollView>
              </View>
              : null
          
            }
            </View>
            <View style={{flex: 0.23}}>
            <Text style={{fontSize: '0.9vw', fontWeight: "bold", marginBottom: 5}}>Anaesthetist *</Text>
            <TextInput 
            style={{borderWidth: 1, borderRadius: 2, padding: 3, borderColor: color.themeDark, height: '4vh', fontSize: '0.9vw'}}
            value={this.state.anesthetist}
            onBlur={() => { 
              this.setState({
                isAnaesthetistDoctorSuggestion: false
              })   
            }}
            onChangeText={(text) => {
              if((text).trim() != ""){
                this.setState({
                  isAnaesthetistDoctorSuggestion: true
                })
                this.searchDoctorSuggestion(text)
              }else{
                  this.setState({
                    isAnaesthetistDoctorSuggestion: false
                  })
              }
              this.setState({
                anesthetist: text
              })
            }}
            />
            {(this.state.isAnaesthetistDoctorSuggestion && this.state.doctorSuggestionData && (this.state.doctorSuggestionData).length > 0 ) ? 
                
              <View style={{ zIndex: 1, position: 'absolute', marginLeft: "5%", maxHeight: 200, width: "90%", marginTop: 45 }}>
                  <ScrollView
                  style={{
                    height: '15vw', marginBottom: '0.8vw', flex: 1,
                    backgroundColor: 'white', borderColor: color.themeBlue, borderWidth: 2, borderRadius: 4
                  }}
                  >
                      {(this.state.doctorSuggestionData).map((value,index) => 
                          <View>
                              <TouchableOpacity 
                              key={index}
                              style={{marginTop: 10, paddingLeft: 10}}
                              onPress={() => {
                                  var doctorFullName = value.first_name + " " + value.initial
                                  this.setState({
                                    anesthetist: doctorFullName,
                                    isAnaesthetistDoctorSuggestion: false
                                  })
                              }}
                              >
                                  <Text style={{fontSize: '0.9vw'}}>{ value.first_name + " " + value.initial }</Text>
                              </TouchableOpacity>
                          </View>
                      )}
                  </ScrollView>
              </View>
              : null
          
            }
            </View>
            <View style={{flex: 0.23}}>
            <Text style={{fontSize: '0.9vw', fontWeight: "bold", marginBottom: 5}}>Surgery Date *</Text>
            <DatePicker
              clearIcon={false}
              disabledDate={current => {
                return current && current < moment().startOf('day');
              }}
              value={this.state.surgeryDate != "" ? moment(this.state.surgeryDate) : ""}
              format={"YYYY-MM-DD"}  
              style={{borderColor: color.themeDark, borderWidth: 1, height: "4vh"}}   
              onChange={(date) => {
                this.setState({
                  surgeryDate: moment(date).format("YYYY-MM-DD")
                })
              }}          
            />
            </View>
            <View style={{flex: 0.23}}>
            <Text style={{fontSize: '0.9vw', fontWeight: "bold", marginBottom: 5}}>Surgery Time *</Text>
            {this.timePicker()}
            </View>
            
          </View>
          <View style={{maxHeight: "12vh", marginTop: '2vh', zIndex: 0}}>
          <Text style={{fontSize: '0.9vw', fontWeight: "bold", marginBottom: 5}}>Notes</Text>
          <TextInput 
          style={{borderWidth: 1, borderRadius: 4, padding: 3, borderColor: color.themeDark, height: '10vh', width: "90%", fontSize: '0.9vw'}}
          value={this.state.notes}
          multiline={true}
          onChangeText={(text) => {
            this.setState({
              notes: text
            })
          }}
          />
          </View>
          <View style={{flexDirection: "row", justifyContent: "center", marginVertical: '3vh', width: "fit-content", alignSelf: "center", maxHeight: '5vh', zIndex: 0}}>
          <TouchableOpacity
          onPress={() => {
            this.counsellorRetransmit()
          }}
          disabled={this.state.approvalStatus === "Completed"}
          style={{marginRight:"2vh", paddingVertical: 8, paddingHorizontal: 20, backgroundColor: this.state.approvalStatus === "Completed" ? color.lightGray : color.themeDark, borderRadius: 4}}
          >
            <Text style={{fontWeight: "500", color: color.white, fontSize: '0.9vw'}}>SAVE</Text>
          </TouchableOpacity>
          <TouchableOpacity
          style={{paddingVertical: 8, paddingHorizontal: 20, backgroundColor: color.themeDark, borderRadius: 4, marginLeft: "2vh"}}
          onPress = {() => {
            this.setState({
              isCounsellorPatientSelected: false,
              selected_appointment_id: null,
              selected_patient_id: null,
              isPackageEdit: false,
            })
          }}
          >
            <Text style={{fontWeight: "500", color: color.white, fontSize: '0.9vw'}}>CANCEL</Text>
          </TouchableOpacity>
          </View>

          </View>
          </View>
        </View>
        : null
        }
        </View>
        {this.state.isConformation && this.state.isConformation ? 
        <View style={{backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: "center", paddingLeft:10, justifyContent: "center" }}>
          <View style={{ 
          position:"absolute",
          backgroundColor:color.white, 
          border: "1px solid lightgrey",
          borderRadius: 10,
          padding: 10,
          height: "15vh",
          width:"20%",

        }}>
                <Text style={{ fontSize: '1.17vw', fontWeight: "bold", marginBottom: '2vw', textAlign: 'center' }}>{"Are you sure ?"}</Text>
                <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", alignContent: "center" }}>
                  <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%"}}
                    onPress={() => {
                      this.updateCallOption(this.state.typedIndex)
                    }}>
                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Yes"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%", marginLeft: "1vw" }}
                    onPress={() => {
                      this.setState({
                        isConformation: false
                      }, () => {
                        // this.props.discardDataReset(true)
                      })
                    }}
                  >
                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"No"}</Text>
                  </TouchableOpacity>
                </View>
              </View>
           
        </View>: null }  
          {this.state.isPatientTransfer && this.state.isPatientTransfer && transferPatient && Object.keys(transferPatient).length > 0 ?
            <View style={{ backgroundColor: "rgba(0, 0, 0, 0.5)", position: 'absolute', width: "100%", height: screenHeight - 65, alignItems: "center", paddingLeft: 10, justifyContent: "center" }}>
              <View style={{
                position: "absolute",
                backgroundColor: color.white,
                border: "1px solid lightgrey",
                borderRadius: 10,
                padding: 10,
                height: "35vh",
                width: "23%",

              }}>
                <Text style={{ fontSize: '1.17vw', fontWeight: "bold", alignSelf: "center" }}>{"Transfer an Appointment"}</Text>
                <View style={{ alignItems: 'center', marginTop: "1vw", flexDirection: "row" }}>
                 <View style={{ flexDirection: "row" }}> 
                  <Image source={transferPatient.photo_url ? transferPatient.photo_url : ImagePath.DefaultProfile}
                    style={[Style.appointmentTableRowImg, { borderWidth: 2, borderColor: transferPatient.patient_type == "new_patient" ? color.red : transferPatient.patient_type == "review_patient" ? color.lightBlue : transferPatient.patient_type == "old_patient" ? color.themeDark : color.white }]}
                    resizeMode='stretch' />
                </View>  
                <View>
                 <Text style={{ color: 'black', fontSize: '1vw', marginLeft: "0.5vw" }} >{transferPatient.patient_name}</Text>
                 <View style={{ flexDirection: "row", marginLeft: "0.5vw" }}>
                  <Text style={{ color: '#ADB4C1', fontSize: '0.8vw' }} >{transferPatient.patient_gender} {" | "}</Text>
                  <Text style={{ color: '#ADB4C1', fontSize: '0.8vw' }} >{transferPatient.patient_age} {" | "}</Text>
                  <Text style={{ color: '#ADB4C1', fontSize: '0.8vw' }}>{moment(transferPatient.patient_approx_dob).format("DD-MM-YYYY")}</Text>
                 </View>
                 <View style={{ flexDirection: "row", marginLeft: "0.5vw" }}>
                    <Text style={{ color: '#ADB4C1', fontSize: '0.8vw'}} >{transferPatient.patient_account_number} {" | "} </Text>
                    <Text style={{ color: '#ADB4C1', fontSize: '0.8vw' }}> {transferPatient.patient_mobile_number}</Text>
                 </View>
                  
                </View>
                
                </View>  
                  {/* <View style = {{ margin: "0.5vw" }}>
                    <Text style={{ fontSize: '0.87vw', fontWeight: "bold" }}>{"Laboratory"}</Text>
                    <Picker
                      selectedValue={this.state.selectLaboratory}
                      disabled={this.state.isLocked}
                      style={[Style.allButtonBorderRadius, { width: "40%", marginTop: "0.5vw" }]}
                      itemStyle={{ fontSize: '0.8vw' }}
                      onValueChange={(itemvalue) => {
                        this.setState({
                          selectLaboratory : itemvalue,
                        })
                      }}
                    >
                      <Picker.Item key={-1} label={"Select Department"} value={0} />
                      { this.state.laboratoryList && this.state.laboratoryList.length > 0 && this.state.laboratoryList.map((i, index) => (
                        <Picker.Item key={index} label={i.laboratory_name} value={i.laboratory_id} />
                      ))}
                    </Picker>
                  </View> */}
                  <View style= {{ margin: "0.5vw" }}>
                    {this.renderPickerView(doctor, department)}
                  </View>
                  <View style={{ backgroundColor: color.white, flexDirection: "row", flex: 1, justifyContent: "center", alignContent: "center", marginTop: "1.5vw" }}>
                  <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%"}}
                    onPress={() => {
                      this.onPressTransferData() 
                    }}>
                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Transfer"}</Text>
                  </TouchableOpacity>
                  <TouchableOpacity
                    style={{ alignItems: 'center', justifyContent: 'center', backgroundColor: color.themeDark, borderRadius: '0.26vw', height: "1.8vw", width: "30%", marginLeft: "1vw" }}
                    onPress={() => {
                      this.setState({
                        isPatientTransfer: false,
                        selectedDoctor: "",
                        selectDepartment: ""
                      })
                    }}
                  >
                    <Text style={{ color: 'white', fontSize: "0.78vw", }}>{"Cancel"}</Text>
                  </TouchableOpacity>
                </View>
              </View>
            </View> : null}
            {
              this.state.FilterPopUpOpen ? this.HomeFilterSearchPopUp() : null
            }
            {
              this.state.isTransfer ? this.TransferAppointment() : null
            }
        </View>
      </TouchableWithoutFeedback>
    );
  }

  searchPackageMapping(value) {
    let { suggestionPackageData } = this.state;
    let tempDescList = this.state.totalPackageData
    if (tempDescList.length > 0) {
      var searchListTemp = [];
      for (let i = 0; i < tempDescList.length; i++) {

        if (tempDescList[i].package_name.toLowerCase().indexOf(value.toLowerCase()) > -1) {
          searchListTemp.push(tempDescList[i]);
        }
      }
      suggestionPackageData = searchListTemp;
    } else {
      suggestionPackageData = this.state.totalPackageData;
    }
    this.setState({
      suggestionPackageData
    })
  }

  tableHeader = () => {
    let {searchPackage, searchPackageOpen, selectedPackageAmount, selectedPackageId} = this.state
    return(
    <View style={{maxHeight: '15vh', zIndex: 3,}}>
    <View
      style={[
        {  
          alignSelf: 'center',
          flexDirection: "row",
          alignItems: "center",
          backgroundColor: color.appointmentTableHead,
          height: '5vh',
          zIndex: 3,
          // borderRadius: 4,
          width:"100%"
        }]}>
        <View style={{ marginLeft: 10, width: "28%", alignItems: "flex-start" }} >
          <Text style={Style.appointmentTableHeadText}>Package Name</Text>
        </View>
        <View style={{ marginLeft: 10, width: "17%", alignItems: "center" }} >
          <Text style={Style.appointmentTableHeadText}>Amount</Text>
        </View>
        <View style={{ marginLeft: 10, width: "18%", alignItems: "center" }} >
          <Text style={Style.appointmentTableHeadText}>Discount</Text>
        </View>
        <View style={{ marginLeft: 10, width: "19%", alignItems: "center" }} >
          <Text style={Style.appointmentTableHeadText}>GST</Text>
        </View>
        <View style={{ marginLeft: 10, width: "18%", alignItems: "flex-start" }} >
          <Text style={Style.appointmentTableHeadText}>Bill Amount</Text>
        </View>

      </View>
      <View
      style={[
        {  
          alignSelf: 'center',
          flexDirection: "row",
          alignItems: "center",
          height: '8vh',
          // borderRadius: 4,
          marginTop: 10,
          width:"100%"
        }]}>
          <View style={{ width: "28%", alignItems: "flex-start" }} >
          <TextInput

          style={{
            borderColor: color.themeDark,
            borderRadius: 2,
            borderWidth: 1,
            width: "100%",
            height: '2.27vw',
            padding: '0.97vw',
            fontSize: '0.9vw'
          }} value={
            this.state.searchPackage
          }
          onFocus={() => {
            searchPackageOpen = true;
            this.setState({
              searchPackageOpen,
            })
          }}
          onChangeText={(text) => {
            if (text != "") {
              searchPackageOpen = true;
              searchPackage = text
              this.setState({
                searchPackage,
                searchPackageOpen
              }, () => {
                this.searchPackageMapping(searchPackage);
              })
            } else {
              searchPackageOpen = false;
              searchPackage = text;
              this.setState({
                searchDescriptionOpen,
                searchPackage,
              }, () => {
                this.searchPackageMapping(searchPackage);
              })
            }
          }}
          />
          {this.state.searchPackageOpen ? 
          <View style={{ zIndex: 3, position: 'absolute', marginTop: '3vw', maxHeight: '16.27vw', minWidth: "100%", maxWidth: "100%", backgroundColor: color.white, borderColor: color.themeBlue, borderWidth: 2, borderRadius: 4 }}>
            <ScrollView
              style={{
                maxHeight: '16vw', flex: 1,
                backgroundColor: 'white', borderColor: color.lightGray, borderWidth: 1, borderRadius: 4
              }}
            >
            {this.state.suggestionPackageData.map((value, index) =>
              <View style={{ height: '2vw' }}>
                <TouchableOpacity key={index}
                  style={{ marginLeft: 5, marginTop: 5 }}
                  onPress={
                    () => {
                      if(value.id != selectedPackageId){
                        this.setState({
                        selectedPackageId: value.id,
                        selectedPackageAmount: value.package_amount,
                        searchPackage: value.package_name,
                        packageDiscountAmt: 0,
                        packageDiscountPercentage: 0,
                        selectedGST: 0,
                        selectedGSTtype: "",
                        packageBillAmount: value.package_amount,
                        searchPackageOpen: false,
                        packageAmount: value.package_amount,
                        })
                      }else{
                        this.setState({
                          searchPackageOpen: false,
                        })
                      }

                    }
                  }
                >
                  <Text style={{fontSize: '0.9vw'}}>{value.package_name}</Text>

                </TouchableOpacity>
              </View>
            )}
            </ScrollView>
          </View>
          : null
          }
          </View>
          <View style={{ marginLeft: 10, width: "17%", alignItems: "center" }} >
          <Text style={{fontSize: '0.9vw'}}>{"₹ " + selectedPackageAmount}</Text>
          </View>
          <View style={{ marginLeft: 10, width: "18%", alignItems: "center", flexDirection: "row", borderWidth: 1, justifyContent: "space-between" }} >
          <Text style={{paddingHorizontal: 5, backgroundColor: color.themeDark, fontWeight: "bold", fontSize: '0.9vw'}}>₹ </Text>
          <TextInput
          value={this.state.packageDiscountAmt} 
          type= "numeric"
          style={{width: '4.56vw', paddingLeft: 5, fontSize: '0.9vw'}} 
          onChangeText= {(text) => {
            this.onChangeDiscountAmt(text)
          }} />
          <Text style={{paddingHorizontal: 5, backgroundColor: color.themeDark, fontWeight: "bold",fontSize: '0.9vw'}}>%</Text>
          <TextInput 
          style={{ width: '2.6vw',paddingLeft: 5, fontSize: '0.9vw'}}
          type="numeric" 
          value={this.state.packageDiscountPercentage}
          onChangeText= {(text) => {
            this.onChangeDiscountPercentage(text)
          }}
          />
          </View>
          <View style={{ marginLeft: 10, width: "19%", alignItems: "center", flexDirection:"column" }} >
          {this.renderGSTtypeBtn()}
          <Picker
            selectedValue={this.state.selectedGST}
            style={{  height: '1.95vw', width: "60%", fontSize: '0.9vw', backgroundColor: "white", color: 'black' }}
            itemStyle={{ fontSize: '0.8vw' }}
            onValueChange={(itemvalue) => {
              if(this.state.selectedGSTtype != ""){
              this.setState({
                selectedGST: itemvalue
              }, () => {
                this.TotalAmtCalculation()
              }) 
              }
            }}
          >
            <Picker.Item label="0%" value= {0} />
            <Picker.Item label="5%" value= {5} />
            <Picker.Item label="12%" value={12} />
            <Picker.Item label="18%" value={18} />
          </Picker>
          </View>
          <View style={{ marginLeft: 10, width: "18%", alignItems: "flex-start" }} >
          <Text style={{fontSize: '0.9vw'}}>{"₹ " + parseFloat(this.state.packageBillAmount).toFixed(2)}</Text>
          </View>

        </View>
      </View>
    )
  }

  convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(' ');

    let [hours, minutes] = time.split(':');

    // if (hours == '12') {
    //   hours = '00';
    // }

    if (modifier == 'PM') {
      hours = parseInt(hours, 10) + 12;
    }

    return `${hours}:${minutes}:${"00"}`;
  }

  counsellorRetransmit = () => {

    var checkAMPM;

    if (this.state.findAmPm == "pm") {
      checkAMPM = "PM"
    } else {
      checkAMPM = "AM"
    }

    var tempTime = (this.state.hour12Formet) + ":" + this.state.minutes + " " + checkAMPM;
    var appointmentTime = this.convertTime12to24(tempTime);
    var data = {}
    var states = this.state

    data = {
      id: states.counsellingTransmitId,
      doctor_id: states.transmitDoctorId,
      patient_id: states.selected_patient_id,
      appointment_id: states.selected_appointment_id,
      clinic_id: states.selectedClinicOrDoctor.id,
      surgery_id: states.selectedSurgeryId,
      package_id: states.selectedPackageId,
      eye_type: states.selectedEyeType.label,
      surgeon_name: states.surgeonName,
      anaesthetist: states.anesthetist,
      counsellor_name: states.loginCounsellorName,
      notes: states.notes,
      discount_amount: states.packageDiscountAmt,
      gst_percentage: states.selectedGST,
      gst_type: states.selectedGSTtype,
      net_amount: states.packageBillAmount,
      status: "Completed",
      discount_percentage: states.packageDiscountPercentage,
      surgery_date: states.surgeryDate,
      iol_power: states.lensPower,
      surgery_time: appointmentTime,
    }
    var service_url = Constants.OPT_COUNSELLOR_RETRANSMIT_POST;
    OpthamologyService.getInstance().postComplaints(
      service_url,
      data,
      this,
      this.counsellorRetransmitSuccess,
      this.counsellorRetransmitFailure
    );
  }

  counsellorRetransmitSuccess=(response) => {
    if(response.status === "success"){
      this.setState({
        isCounsellorPatientSelected: false,
        selected_appointment_id: null,
        selected_patient_id: null,
        isPackageEdit: false,
        selectedTab : "surgery",
        lensPower: '',
        showTimer: false,
        hour12Formet: 12,
        minutes: '00',
        findAmPm: 'am',
        totalPackageData: [],
        suggestionPackageData: [],
      })
      this.props.showResposeValue("success",response.message)
    }else{
      this.props.showResposeValue("error",response.message)
    }
  }

  renderGSTtypeBtn = () => {
    var listOfGSTtype = [
      { label: "Excl", value: "Exclusive" },
      { label: "Incl", value: "Inclusive" }
    ]
    return (
      <View style={{ flex: 1,  width: "60%" }}>
        <View style={{marginVertical: '1.17vw', flexDirection: "row"}}>
          {
            listOfGSTtype.map((item, index) => {
              return (
                <TouchableOpacity onPress={() => {
                  this.setState({
                    selectedGSTtype: item.value,
                  }, () => {
                    this.TotalAmtCalculation()
                  })
                  
                }} 
                style={{flexDirection: "row", alignItems: "center", marginRight: '1.6vw'}} key={index}>
                  <View style={{
                    backgroundColor: (this.state.selectedGSTtype == item.value) ? color.themeDark : "",marginRight: 8, height: '0.9vw', width: '0.9vw', borderRadius: '4vw', borderWidth: 1, borderColor: "#91939F"
                  }}></View>
                  <Text style={{fontSize: '0.9vw'}}>{item.label}</Text>
                </TouchableOpacity>
              )
            })
          }
        </View>
        </View>
      )
  }

  onChangeDiscountAmt = (amt) => {
    if(amt === ""){
      amt = 0

    }
    if(this.state.selectedGSTtype === "Exclusive"){
      var GSTamt = (parseFloat(this.state.packageAmount) * (parseFloat(this.state.selectedGST))) / 100
      var DiscountPercentage = ((parseFloat(amt) * 100)/parseFloat(this.state.packageAmount)).toFixed(2)
      var BillAmount = (parseFloat(this.state.packageAmount) - parseFloat(amt)) + parseFloat(GSTamt)
    }else{
    var DiscountPercentage = ((parseFloat(amt) * 100)/parseFloat(this.state.packageAmount)).toFixed(2)
    var BillAmount = (parseFloat(this.state.packageAmount) - parseFloat(amt))
    }
    this.setState({
      packageDiscountAmt: amt,
      packageDiscountPercentage: DiscountPercentage,
      packageBillAmount: BillAmount
    })
  }

  onChangeDiscountPercentage = (percentage) => {
    if(percentage === ""){
      percentage = 0

    }
    if(this.state.selectedGSTtype === "Exclusive"){
      var GSTamt = (parseFloat(this.state.packageAmount) * (parseFloat(this.state.selectedGST))) / 100
      var DiscountAmt = (parseFloat(this.state.packageAmount) * ((parseFloat(percentage)/100))).toFixed(2)
      var BillAmount = (parseFloat(this.state.packageAmount) - parseFloat(DiscountAmt)) + parseFloat(GSTamt)
    }else{
      var DiscountAmt = (parseFloat(this.state.packageAmount) * ((parseFloat(percentage)/100))).toFixed(2)
      var BillAmount = (parseFloat(this.state.packageAmount) - parseFloat(DiscountAmt))
    }
  
    this.setState({
      packageDiscountAmt: DiscountAmt,
      packageDiscountPercentage: percentage,
      packageBillAmount: BillAmount
    })
  }

  TotalAmtCalculation = () =>{
    if(this.state.selectedGSTtype === "Exclusive"){
      var DisAmt = 0
      if(this.state.packageDiscountAmt === null){
        DisAmt = 0
      }else{
        DisAmt = this.state.packageDiscountAmt
      }
        var GSTamt = (parseFloat(this.state.packageAmount) * (parseFloat(this.state.selectedGST))) / 100
        var BillAmt = parseFloat(this.state.packageAmount) + parseFloat(GSTamt) - parseFloat(DisAmt)
      
      this.setState({
        packageBillAmount: BillAmt
      })
    }else if(this.state.selectedGSTtype === "Inclusive"){
      var DisAmt = 0
      if(this.state.packageDiscountAmt === null){
        DisAmt = 0
      }else{
        DisAmt = this.state.packageDiscountAmt
      }
        var BillAmt = parseFloat(this.state.packageAmount) - parseFloat(DisAmt)


      this.setState({
        packageBillAmount: BillAmt
      })
    }

  }

  showDateDropDownView = () => {
    var getdate = [];
    if (this.state.dropDownView === "month") {
      getdate = this.state.totalMonths;
    } else {
      var today = new Date();
      var year = today.getFullYear();
      // var currentYear = parseInt(today.getFullYear()); // 2021 
      for (var i = year+5; i > year-5; i--) {
        getdate.push(i);
      };
    }
    return (
      <View style={{ alignSelf: 'center', alignItems: 'center', width: '25%', backgroundColor: 'black', zIndex: 1, position: 'absolute', }}>
        {
          this.state.dropDownViewFlag ?
            <ScrollView style={styles.timeAndDateScroll} showsVerticalScrollIndicator={false}>
              <View style={{ backgroundColor: 'black' }}>
                {
                  getdate.map((value, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        style={{ padding: 5 }}
                        onPress={() => {
                          this.state.dropDownView === "month" ?
                            this.setState({ chengedMonthInNumber: index + 1, getMonth: value.month, dropDownViewFlag: !this.state.dropDownViewFlag, changedMonthDays: value.totalDays })
                            :
                            this.setState({ getYear: value, dropDownViewFlag: !this.state.dropDownViewFlag });
                        }}>
                        <Text style={{ color: "white" }}>{this.state.dropDownView === "month" ? value.month : value}</Text>
                      </TouchableOpacity>
                    )
                  })
                }
              </View>
            </ScrollView>
            :
            null
        }
      </View>
    )
  }
  currentDate() {
    var today = new Date();
    var date = today.getDate();
    var month = today.getMonth();
    var year = today.getFullYear();
    for (let i = 0; i < this.state.totalMonths.length; i++) {
      if (month === i) {
        this.setState({
          changedMonthDays: this.state.totalMonths[i].totalDays,
          getMonth: this.state.totalMonths[i].month,
          getYear: year,
          day1: date - 2,
          day2: date - 1,
          day3: date,
          day4: date + 1,
          day5: date + 2,
        })
      }
    }
  }
  showDropdown(data) {
    this.setState({
      dropDownViewFlag: !this.state.dropDownViewFlag,
      dropDownView: data
    })
  }
  onClickToday(){
      var today= new Date()
      var date= today.getDate()
      var month=today.getMonth()
      var year=today.getFullYear()
      var states=this.state
      for(let i=0;i<this.state.totalMonths.length;i++){
        if(month===i){
          states["chengedMonthInNumber"] = i + 1;
          states["changedMonthDays"] = this.state.totalMonths[i].totalDays;
          states["getMonth"] = this.state.totalMonths[i].month;
          states["getYear"] = year;
          states["getDate"] = date;
          states["day1"] = date - 2;
          states["day2"] = date - 1;
          states["day3"] = date;
          states["day4"] = date + 1;
          states["day5"] = date + 2;
          states["changedDay"] = date;
        } 
     }
     this.setState({
      states
    },()=>{
      this.todayAppointment();
      this.getAppointmentStat();
    })
     
  }
  showCalendar() {
    return (
      <View style={{ width: '35%', backgroundColor: "white", flexDirection: 'row', marginLeft: 10, flex: 1 }}>
        <View>
          <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
            <TouchableOpacity
              style={{ marginRight: 5 }}
              onPress={() => {
                this.showDropdown("month")
              }}>
              <Text>{this.state.getMonth}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginRight: 5 }}
              onPress={() => {
                this.showDropdown("year");
              }}>
              <Text>{this.state.getYear}</Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={()=>{this.onClickToday()}}>
              <Image 
              source={ImagePath.CalendarIcon}
              style={{height:20,width:20,tintColor:color.themeDark}}/>
            </TouchableOpacity>
            
            <View style={{ height: 15, width: 15 }}>
              {/* <Image
              // require("../../assets/images/Reports/Group 1558.svg")calendar_icon.png
              source={require("../../../../assets/images/calendar (2).svg")}
              style={{resizeMode:'center',height:15,width:15}}
              resizeMode="cover"
            /> */}
            </View>
          </View>
          <View>
            {this.showDateDropDownView()}
          </View>
          <View style={{ zIndex: -1, flexDirection: 'row', justifyContent: 'space-between', marginTop: 10, marginBottom: 10 }}>
            <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.changedDay - 1); }} style={{ padding: 5, justifyContent: 'center' }}>
              <Icon
                size={platform === "web" ? 25 : 30}
                name={"angle-left"}
              />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("sub", 2, this.state.day1); }}
              style={[this.state.changedDay === this.state.day1 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day1 ? { color: "white" } : null]}>{this.state.day1}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("sub", 1, this.state.day2); }}
              style={[this.state.changedDay === this.state.day2 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day2 ? { color: "white" } : null]}>{this.state.day2}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("add", 0, this.state.day3) }}
              style={[this.state.changedDay === this.state.day3 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day3 ? { color: "white" } : null]}>{this.state.day3}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.day4); }}
              style={[this.state.changedDay === this.state.day4 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day4 ? { color: "white" } : null]}>{this.state.day4}<Text style={{ fontSize: 7, }}></Text></Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { this.changeDay("add", 2, this.state.day5); }}
              style={[this.state.changedDay === this.state.day5 ? { backgroundColor: color.themeDark, padding: 5, borderRadius: 40 } : { padding: 5 }, { justifyContent: 'center' }]}
            >
              <Text style={[this.state.changedDay === this.state.day5 ? { color: "white" } : null]}>{this.state.day5}<Text style={{ fontSize: 7 }}></Text></Text>
            </TouchableOpacity>

            <TouchableOpacity onPress={() => { this.changeDay("add", 1, this.state.changedDay + 1); }} style={{ padding: 5, justifyContent: 'center' }}>
              <Icon
                size={platform === "web" ? 25 : 30}
                name={"angle-right"}
              // color={"#c7c4c3"}
              />
            </TouchableOpacity>
          </View>
        </View>
        {/* <View style={{justifyContent:'flex-end',marginBottom:10,marginLeft:5}}>
          <Image
              source={require("../../../../assets/images/clock_icon.png")}
              style={{resizeMode:'center',height:30,width:30}}
              resizeMode="cover"
            />
            <Text style={{fontSize:8,marginTop:6}}>{"Today"}</Text>
          </View> */}
      </View>
    )
  }

  checkValidateTheDays() {
    if (this.state.day5 === this.state.changedMonthDays) {
      //  do nothing
    } else {
      if (this.state.day5 > this.state.changedMonthDays) {
        this.setState({
          day1: this.state.day1 - 1,
          day2: this.state.day2 - 1,
          day3: this.state.day3 - 1,
          day4: this.state.day4 - 1,
          day5: this.state.day5 - 1,
        });
      }
    }

    if (this.state.day3 === 1) {
      this.setState({
        day1: this.state.day1 + 2,
        day2: this.state.day2 + 2,
        day3: this.state.day3 + 2,
        day4: this.state.day4 + 3,
        day5: this.state.day5 + 4,
      });
    }
    if (this.state.day3 === 2) {
      this.setState({
        day1: this.state.day1 + 1,
        day2: this.state.day2 + 1,
        day3: this.state.day3 + 1,
        day4: this.state.day4 + 1,
        day5: this.state.day5 + 1,
      });
    }

  }

  changeDay(method, value, selectedDay) {
    this.state.getDate = selectedDay; // get selected day

    if (this.state.changedMonthDays < selectedDay || selectedDay < 1) {
      // do nothing
    } else {
      this.setState({ changedDay: selectedDay })
    }


    if (method === "" && value === 0) {
      // do nothing
    } else {
      if (method === "sub") {
        if (this.state.day3 === 3) {
          // do nothing
        } else if (this.state.day1 === 2) {
          this.setState({
            day1: this.state.day1 - 1,
            day2: this.state.day2 - 1,
            day3: this.state.day3 - 1,
            day4: this.state.day4 - 1,
            day5: this.state.day5 - 1,
          });
        } else {
          this.setState({
            day1: this.state.day1 - value,
            day2: this.state.day2 - value,
            day3: this.state.day3 - value,
            day4: this.state.day4 - value,
            day5: this.state.day5 - value,
          });
        }
      } else {
        if (this.state.changedMonthDays === this.state.day5) {
          // do nothing
        } else if (this.state.changedMonthDays - 2 === this.state.day4) {
          this.setState({
            day1: this.state.day1 + 1,
            day2: this.state.day2 + 1,
            day3: this.state.day3 + 1,
            day4: this.state.day4 + 1,
            day5: this.state.day5 + 1,
          });
        } else {
          this.setState({
            day1: this.state.day1 + value,
            day2: this.state.day2 + value,
            day3: this.state.day3 + value,
            day4: this.state.day4 + value,
            day5: this.state.day5 + value,
          });
        }
      }
    }

    if ((this.state.changedMonthDays >= this.state.getDate && method === "add") || (this.state.getDate >= 1 && method === "sub")) {
      var selectedDateAndTime = this.state.getYear + "-" + this.state.chengedMonthInNumber + "-" + this.state.getDate; // selected date month and year (need to pass getAppointments function)


      this.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD")); // send selected date to Appointments
      this.props.currentAppointmentsDate(moment(this.state.selectedDate).format("YYYY-MM-DD"))

    } else {
      // do nothing
    }
  }

  modelTimerClose() {
    this.setState({
      // modalVisible:false,             // Next AppoinmentModal Close
      showNextAppoinmentTimer: false   // AppoinmentModal timer drop down close
    })
    // ,
  }
  modelClose(flag, status = "") {
    this.setState({
      modalVisible: flag,             // Next AppoinmentModal Close
      // showNextAppoinmentTimer:false   // AppoinmentModal timer drop down close
    })
    if (status == "success") {
      this.todayAppointment();
      this.getAppointmentStat();
    }
  }
  ShowCreateappointment(flag) {
    this.setState({
      ShowCreateappointment: flag,             // Create AppoinmentModal Close
      // showNextAppoinmentTimer:false   // AppoinmentModal timer drop down close
    })
  }

// 

  async getShiftCounterList(item){

    var getClientID ="";

    // if(this.state.isFoAdmin == "true"){ 
    //   counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
    // }else{
    //   counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
    // }
    // var {clientID} = this.state;

    if(this.state.isFoAdmin == "true"){
      getClientID = this.state.clientID
    }else{
      getClientID = this.state.selectedClinicOrDoctor.id
    }



  // var service_url = Constants.QMS_SHIFT_COUNTER_LIST_GET+"?client_id="+this.state.selectedClinicOrDoctor.id;
    var service_url = Constants.QMS_SHIFT_COUNTER_LIST_GET+getClientID;
   
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.getShiftCounterListSuccess,
      this.getShiftCounterListFailure,
      "",
      "qms"
    );
  }
  getShiftCounterListSuccess=success=>{
    // alert("get shift counter lists ----------------> "+JSON.stringify(success))

    if(success.status == "success" ){
      this.props.showSelectedShiftcounterList(true,success.data.counter_details)

    }else{
      this.props.showResposeValue("error",success.message)
    }

  }
  getShiftCounterListFailure=error=>{
   // alert("error *******************> "+JSON.stringify(error))

  }


  renderIsAvailableIndicatuion(){
    return(
      <CommonButton
      item={{}}
      // disable={this.state.isLocked}
      selectedvalue={{}}
      butttonText={"Is Available"}
      buttonType={this.state.isAvailableIndicatuionFlag ? "theme": "outlineNonTheme"}
      buttonIcon={""}
      rightIcon={false}
      buttonAction={this.isAvailableIndicatuionChangeFlag.bind(this)}
      buttonKey={"isAvailableIndicatuionFlag"} 
      />
    )
  }

  isAvailableIndicatuionChangeFlag(){

    this.isAvailableIndicatuionChangeAPICall()
  }

  isAvailableIndicatuionChangeAPICall(){

    var counterID ="";
    if(Object.keys(this.state.selectedClinicOrDoctor).length && this.state.selectedClinicOrDoctor.id){

    if(this.state.isFoAdmin == "true"){ 
      counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
    }else{
      counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
    }


    // var counterID = (this.state.clientID.toString())+this.state.selectedClinicOrDoctor.id.toString()

    var service_url = Constants.QMS_ACTIVATE_DEACTIVATE_COUNTER+counterID+"/";
    OpthamologyService.getInstance().postComplaints(
      service_url,
      JSON.stringify({}),
      this,
      this.isAvailableIndicatuionChangeAPICallSuccess,
      this.isAvailableIndicatuionChangeAPICallFailure,
      "",
      "qms"
    );
    }
  }

  isAvailableIndicatuionChangeAPICallSuccess=(success)=>{
    // alert("DR is Active and DeActive flag change ----------------> "+JSON.stringify(success))

    if(success.status=="success"){
      if(success.data && success.data.counter){
        if(success.data.counter.active_id){
          this.setState({
            isAvailableIndicatuionFlag:true
          })
        }else{
          this.setState({
            isAvailableIndicatuionFlag:false
          })
        }
        
      }
    }
  }

  isAvailableIndicatuionChangeAPICallFailure=(error)=>{  alert("*********error"+JSON.stringify(error))
  }

  // 

  getListfoActiveTokensForQMS(){
    // var counterID = (this.state.clientID.toString())+this.state.selectedClinicOrDoctor.id.toString()


    var counterID ="";
    if(Object.keys(this.state.selectedClinicOrDoctor).length && this.state.selectedClinicOrDoctor.id){

    if(this.state.isFoAdmin == "true"){ 
      counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
    }else{
      counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
    }

    // alert("getListfoActiveTokensForQMS last ---  "+counterID);

    // var service_url = Constants.QMS_COUNTER_ACTIVE_TOKENS+counterID+"/";
    // OpthamologyService.getInstance().getComplaints(
    //   service_url,
    //   this,
    //   this.getListfoActiveTokensForQMSSuccess,
    //   this.getListfoActiveTokensForQMSFailure,
    //   "",
    //   "qms"
    // );
    }
  }




  getListfoActiveTokensForQMSSuccess=success=>{
    // alert("get List of Active tokens ----------------> "+JSON.stringify(success))

    if(success.status== "success"){
      this.setState({
        getActiveTokensListFOrQMS:success.data ? success.data :[]
      },()=>{
        this.validateForShowCallTokenIcon()
      })
    }
  }
  getListfoActiveTokensForQMSFailure=error=>{}

  checkCounterActiveForQMS(){
    if(this.state.selectedClinicOrDoctor && this.state.selectedClinicOrDoctor.id){

      var counterID = "";
      if(this.state.isFoAdmin == "true"){ 
        counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
      }else{
        counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
      }

      // alert(counterID);


    // var counterID =this.state.clientID? (this.state.clientID.toString())+this.state.selectedClinicOrDoctor.id.toString():null

    var service_url = Constants.QMS_COUNTER_IS_ACTIVE+counterID+"/";
    OpthamologyService.getInstance().getComplaints(
      service_url,
      this,
      this.checkCounterActiveForQMSSuccess,
      this.checkCounterActiveForQMSFailure,
      "",
      "qms"
    );
  }
  }

  checkCounterActiveForQMSSuccess=success=>{

    // if(this.state.isFoAdmin == "true"){

    // }

    // conso
    // alert("success ---");
    console.log(JSON.stringify(success))

    if(success.status== "success"){
      if(success.data && success.data.counter)
      this.setState({
        isAvailableIndicatuionFlag:success.data.counter.is_active,
        isQMSAccessAvailableUser:true
      })
    }
  }
  checkCounterActiveForQMSFailure=error=>{}





  validateForShowCallTokenIcon(){

    // this.state.getActiveTokensListFOrQMS
    // this.state.appointmentData
    var {getActiveTokensListFOrQMS,appointmentData}=this.state
    // alert("validate 2 array list ----------------> "+JSON.stringify(getActiveTokensListFOrQMS))

    if(getActiveTokensListFOrQMS && getActiveTokensListFOrQMS.length > 0 && appointmentData.length > 0){
    var prepareNewArray = []
    for (let i = 0; i < appointmentData.length; i++) {
        var data =appointmentData[i]
        for (let j = 0; j < getActiveTokensListFOrQMS.length; j++) {
          // alert(appointmentData[i].appointment_id + "-----"+getActiveTokensListFOrQMS[j].customer_number[0])

            if(appointmentData[i].appointment_id == getActiveTokensListFOrQMS[j].customer_number[0]){
                data["qms_token"]=getActiveTokensListFOrQMS[j].token_id;
                data["qms_token_digit"]=getActiveTokensListFOrQMS[j].token_digit;
                data["qmsTokenStatus"]=getActiveTokensListFOrQMS[j].token_status;
            }
        }
        prepareNewArray.push(data)
    }

    //console.log(prepareNewArray)
    // alert("validate 2 array list ----------------> "+JSON.stringify(prepareNewArray))


    this.setState({
      appointmentData: prepareNewArray
    }, () => {
      // alert("afetr  set state *******************"+JSON.stringify(prepareNewArray))
    })

  }

  }

  changeTokenStatusInQMS(item,status){
   var data ={
      "token_id" : item.qms_token,
      "status" : status
  }
  //  // var counterID = (this.state.clientID.toString())+this.state.selectedClinicOrDoctor.id.toString()
  var counterID ="";
  if(Object.keys(this.state.selectedClinicOrDoctor).length && this.state.selectedClinicOrDoctor.id){

  if(this.state.isFoAdmin == "true"){ 
    counterID =this.state.clientID ? (this.state.clientID.toString())+(this.state.selectedClinicOrDoctor.id.toString()): null
  }else{
    counterID =this.state.clientID ? (this.state.selectedClinicOrDoctor.id.toString())+(this.state.clientID.toString()): null        
  }


  // alert(counterID)

  var service_url = Constants.QMS_CHANGE_TOKEN_STATUS+counterID+"/";

OpthamologyService.getInstance().postComplaints(
    service_url,
    JSON.stringify(data),
    this,
    this.changeTokenStatusInQMSSuccess,
    this.changeTokenStatusInQMSFailure,
    "",
    "qms",
    data
);
  }

  }

  changeTokenStatusInQMSSuccess=(success,data)=>{
    // console.log("token status change  0 ----- "+JSON.stringify(success))
    if(success && success.status == "success"){
      // this.getStatusChangeUpdate(data)
      if( data.status == "process" || data.status == "complete"){
        // alert(data)

        this.getListfoActiveTokensForQMS()
      }
      // this.props.showResposeValue('success',success.message)
    }else{
      // this.getStatusChangeUpdate(data)
      // this.props.showResposeValue('error',success.message)
    }
  }

  changeTokenStatusInQMSFailure=error=>{
    // alert("*********** success * "+JSON.stringify(success))
  }

   speak = (qmsToken) => {
    const thingToSay = "Token Number "+ qmsToken;
    Speech.speak(thingToSay);
  };


  async getStatusChangeUpdate () {



//     const clientID = await  AsyncStorage.getItem("UserID");
//     const getSelectedClinicOrDoctor = await  AsyncStorage.getItem("Selected_Clinic_Doctor");
//      var selectedClinicOrDoctor =  JSON.parse(getSelectedClinicOrDoctor)

//     var getClientID ="";

//     if(selectedClinicOrDoctor && selectedClinicOrDoctor.id){

  
//     if(this.state.isFoAdmin == "true"){
//       getClientID = clientID
//     }else{
//       getClientID = selectedClinicOrDoctor.id
//     }
//   }

//   // alert("getClientID ------> "+getClientID)

//     // const socketID = await  AsyncStorage.getItem("UserID");

//     var socket_ur = Constants.QMS_SOCKET_CONNECTION_URL+getClientID+"/";

//     console.log("****************** getStatusChangeUpdate socket_ur  -----" + socket_ur);

//     const chatSocket = new WebSocket(socket_ur);

//     var me = this;

//     chatSocket.onmessage = function (e) {
//       console.log("ON MESSAGE");

//       var data = JSON.parse(e.data);

//       console.log("ON MESSAGE data"+JSON.stringify(data));

//       // alert("****************** getStatusChangeUpdate socket_ur  -----" + socket_ur);

//       if(data && data.type == "queue"){
//         // var selectedDateAndTime = me.state.getYear + "-" + me.state.chengedMonthInNumber + "-" + me.state.getDate; 

//         me.getAppointments(moment(this.state.selectedDate).format("YYYY-MM-DD"));
//       }
//     }


//     chatSocket.onclose = function (e) {
//         console.error("Token socket closed unexpectedly");

//         console.log("SOCKET CODE : "  +e.code);
//         console.log("SOCKET REASON : "  +e.reason);
//     };

//     chatSocket.onerror = function (e) {
//         console.error(" socket ERROR ");

//     };
};



  validateCallOptionForCall(selectedItem,qmsStatus){
    // this.state.appointmentData;
    var {appointmentData} = this.state
    if(appointmentData.length > 0){

      var checkIsCallAvailable = false;
      for (let i = 0; i < appointmentData.length; i++) {
        if(appointmentData[i].appointment_status =="With Doctor" && (appointmentData[i].qms_token !== selectedItem.qms_token)){
          this.changeAppointmentStatus("Completed", appointmentData[i].appointment_id,appointmentData[i]);
          this.changeTokenStatusInQMS(appointmentData[i],"complete");
        }

        if(appointmentData[i].qmsTokenStatus =="process" && (appointmentData[i].qms_token !== selectedItem.qms_token)){

          // alert(JSON.stringify(appointmentData[i]))
          this.changeTokenStatusInQMS(appointmentData[i],"wait")
          this.changeTokenStatusInQMS(selectedItem,"process");

          // if(appointmentData[i].qmsTokenStatus =="process" && appointmentData[i].appointment_status =="With Doctor" && (appointmentData[i].qms_token !== selectedItem.qms_token)){
          //   this.changeAppointmentStatus("Completed", appointmentData[i].appointment_id);
          // }

          checkIsCallAvailable = true;
          return
        }else{
          // console.log(JSON.stringify(appointmentData[i]))

        }
      }


      if(!checkIsCallAvailable){
        this.changeTokenStatusInQMS(selectedItem,"process");
      }

    }
  }


  componentWillUnmount() {
    clearInterval(this.interval);
  }

}


const styles = StyleSheet.create({
  titleContainer:{
    width: "100%",
    height:"3vw",
    boxShadow: "0px 3px 6px #00000029",
    borderBottomEndRadius: "15vw",
    borderBottomLeftRadius: "15vw",
    justifyContent:'center'
  },
  titleHeader:{
    display: "flex",
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-between',
    marginHorizontal:"5vw"
  },
  datePickerComponent:{
    marginRight:'3vw',
    display: "flex",
    flexDirection: 'row',
    alignItems: "center",
    justifyContent:'center'
  },
  headerView:{
    height: "5vw",
    flexDirection: "row",
    paddingLeft: "1vw",
    paddingRight: "1vw",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  totalPatientDiv:{ 
    flexDirection: "row", 
    marginLeft:'5vw',
    marginTop:"-1.6vw"
  },
  appointmentContainer:{
     justifyContent: 'flex-start',
     alignItems:'center',
     height: 30,
     flex: 0.4,
     flexDirection:'row'
    },
  appointmentDiv:{
     fontWeight: 500,
     fontSize:'1.10vw',
     minWidth:"fit-content"
    },
  dataContainer:{ 
    flexDirection: "row",  
    marginTop: "1vw", 
    flex: 0.6, 
    alignContent: 'center'
  },
  dateLable:{
    fontWeight: "500", 
    fontSize:'.95vw',
    marginRight:'.2vw'
  },
  switchDiv:{
    marginLeft:"2.5vw",
    flexDirection:"row",
    alignItems:'center'
  },
  outText:{ 
    fontSize: ".9vw", 
    fontWeight: "500",
    marginRight:".5vw"
  },
  inText:{ 
    fontSize: ".9vw", 
    fontWeight: "500",
    marginLeft:".5vw"
  },
  timeAndDateScroll: {
    height: "13vw", width: "6.5vw"
  },
  statusIconStyles:{
    justifyContent:'center',alignItems:'center',
    padding:".5vw",borderRadius:"10vw",height:"2vw",width:"2vw",
    backgroundColor:color.white,
    shadowOffset: { width: ".1vw", height: ".1vw" }, shadowOpacity: 0.4, shadowRadius: 3,
    elevation: 5,
    marginLeft:'.3vw'
  },
  statusIconHeight:{ 
    height: "1.3vw", 
    width: "1.3vw",
  },
  arrivedStyle:{
    flexDirection: "row", 
    marginLeft: "3.5vw",
    width:'45vw',
    justifyContent:'space-between'
  },
  todayPatientstyle1:{
    flexDirection: "row",
    paddingLeft: ".5vw",
    marginRight: "2vw"
  },
 todayPatientstyle2:{
   width: "4vw",
   marginTop: ".8vw"
  },
  patientTypeViewStyle:{
    flexDirection:"row",
    marginLeft:"1.5vw",
  },
  patientTypeText:{
    marginLeft:"0.32vw",
    fontSize:"0.75vw"
  },
  toolTipStyle:{
    fontSize: "0.7vw", 
    fontWeight: "500", 
    color: "grey"
  },
  loaderStyle:{
    flex: 1, 
    marginTop: 50, 
    justifyContent: 'center', 
    alignContent: 'center', 
    alignSelf: 'center'
  },
  SearchedPatientList:{
    height: platform === "web" ? "2.5vw" : "2.7vw",
    width: platform === "web" ? "2.5vw" : "2.7vw",
    borderRadius: 35,
  },
  preferTime:{ 
    flexDirection: 'row', 
    justifyContent: 'flex-start',
    width: "12vw", 
    height: '4vh',
    paddingHorizontal: "1vw",
    alignItems: "center"
  },
  preferTimeHour:{ 
    flexDirection: 'column', 
    width: '2vw', 
  },
  preferTimeMin:{ 
    flexDirection: 'column', 
    width: '2vw', 
  },
  onQueueContainer: {
    margin: "0.5vw",
    height: "65vh"
  },
  SelectTemplateDropDown: { 
    height: '2vw',
    marginRight:'1vw' ,
    alignSelf:'center', 
    paddingLeft: '0.6vw', 
    backgroundColor: "transparent",
    fontSize: '0.9vw' 
  },
  addText: {
    color: color.white,
    fontSize: "0.8vw",
    marginLeft: "0.5vw"
  },
  addButton: {
    backgroundColor: color.themeDark,
    borderRadius: 4,
    padding: "0.5vw",
    justifyContent: "center",
    alignItems: "center", width: "10%",
    borderColor: color.red
  },
  cancelButton: {
    backgroundColor: color.white,
    borderRadius: 4,
    padding: "0.5vw",
    justifyContent: "center",
    alignItems: "center", width: "10%",
    borderColor: color.red,
    borderWidth: 1
  },
  cancelText: {
    color: color.red,
    fontSize: "0.8vw",
    marginLeft: "0.4vw"
  },
  PrintIconImg: {
    height: '1.2vw',
    width: '1.2vw',
    alignSelf: "center",
    tintColor: color.white
  },
  drugHeaderContainer: {
    flex: 1, flexDirection: "column", justifyContent: "flex-start", alignContent: "center"
  },
  subContainer: {
    flexDirection: "row", justifyContent: "flex-start", alignContent: "center", backgroundColor: color.sectionHeadingDarkColor, padding: 8, borderRadius: 4, marginTop:'0.5vw'
  },
  FilterBTnTouch: {
    paddingTop: '0.3vw',
    paddingLeft: '0.5vw',
    paddingRight: '0.5vw',
    paddingBottom: '0.3vw',
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: '3vw',
    height: '2vw',
    marginLeft: '2vw',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  FilterBtnText: {
    color: color.white,
    textAlign: 'center',
    fontSize: "1vw",
    fontWeight: '500',
    marginLeft: '0.5vw'
  },
  FiterTop: {
    display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '2vw'
  },
  FilterPopUpView: {
    borderRadius: '0.5vw',
    width: '21vw',
    height: '14vw',
    // justifyContent: 'center',
    paddingHorizontal: "0.65vw",
    position: 'absolute',
    backgroundColor: "white",
    borderColor: color.themeDark,
    borderWidth: 1,
    left: '21vw',
    display: 'flex',
    flexDirection: 'column'
  },
  TransferPopUpView:{
    position: 'absolute', 
    zIndex: -1, 
    height: '45vw', 
    width: '95vw', 
    justifyContent: 'center', 
    alignItems: 'center', 
    backgroundColor: 'rgba(0, 0, 0, 0.5)' 
  },
  TransferAppointScrn:{
    backgroundColor: "white", 
    position: 'absolute', 
    width: '35%', 
    height: '27vw', 
    borderRadius: 7, 
    paddingTop: 15, 
    paddingBottom: 20, 
    paddingLeft: 20, 
    paddingRight: 30,
    marginTop: -50
  },
  TransferAppointHead:{
    width:"100%",
    display:"flex",
    flexDirection:"row",
    alignItems:"center",
    justifyContent:"space-between"
  },
  TransferAppointText:{
    fontSize: "1.2vw",
    fontWeight: 600
  },
  patientImage:{ 
    height: '3vw', 
    width: '3vw', 
    borderRadius: 25
  },
  patientDetails:{ 
    flexDirection: 'row',
    marginTop: "0.2vw",
    marginLeft:"1vw" 
  },
  patientUniqueDetails:{ 
    fontSize: '0.8vw', 
    color: "grey" 
  },
  closeButton:{
    backgroundColor: "white", 
    padding: 7, 
    borderRadius: 4, 
    width: '8vw', 
    justifyContent: 'center', 
    borderWidth: 1, 
    marginLeft: 5, 
    borderColor: "grey" 
  },
  FilterRows: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginVertical: '1vw',
    height: '1.5vw'
  },
  FilterHeadTxt: {
    fontSize: '1vw',
    width: '90%',
    textAlign: 'center',
    marginTop: '0.2vw',
    fontWeight: '500'
  },
  FilterBtnView: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0.5vw',
    zIndex: -2
  },
  BtnTouch: {
    paddingTop: '0.3vw',
    paddingLeft: '0.5vw',
    paddingRight: '0.5vw',
    paddingBottom: '0.3vw',
    backgroundColor: color.themeDark,
    borderRadius: ".25vw",
    width: '5vw',
    height: '2vw',
    marginLeft: '2vw'
  },
  ButtonText: {
    color: color.white,
    textAlign: 'center',
    fontSize: ".85vw",
    fontWeight: '500'
  },
  SearchInput: {
    height: '2vw',
    borderWidth: '0.065vw',
    borderColor: color.themeDark,
    borderRadius: '0.26vw',
    width: "18vw",
    marginLeft: '0.65vw',
    paddingHorizontal: '0.52vw',
    color: color.black,
    fontSize: '0.91vw',
    zIndex: -2
  },
  filterPopErrorText :{
    color: color.red,
    fontSize:'0.8vw',
    height:'1vw',
    fontWeight:'500',
    marginLeft:'2vw'
  }



});
